import React from 'react';
import PropTypes from 'prop-types';
import BillingProp from '../../propTypes/billingProp';
import Request from '../../../../services/request';
import Notifications from '../../../../services/notifications';
import { compose, isHigherAdmin } from '../../../../services/utilityFunctions';
import withCSRF from '../../../../commonComponents/withCSRF';
import translate from '../../../../commonComponents/translate';
import Form from '../../../../commonComponents/forms/Form';
import FormInput from '../../../../commonComponents/forms/FormInput';
import FormActions from './FormActions';
import FormHeader from '../../../../commonComponents/forms/FormHeader';
import CountryProp from '../../../../commonComponents/forms/Address/CountryProp';
import FormAddressFragment from '../../../../commonComponents/forms/Address/FormAddressFragment';
import UserProp from '../../propTypes/userProp';
import withLoggedUser from '../../../../commonComponents/withLoggedUser';
import Separator from '../../../../commonComponents/forms/Separator';
import TaxIdTypeProp from '../../../subscriptionDetails/propTypes/TaxIdTypeProp';

/**
 * Form to update the current logged user
 */
class BillingForm extends React.Component {
  constructor(props) {
    super(props);

    const { billing } = props;

    this.state = {
      name: billing.shipping.name,
      phone: billing.shipping.phone,
      address: { ...billing.shipping.address },
      tax_info: { ...billing.tax_info },
      invoiceEmail: billing.invoiceEmail,
    };

    this.validationRules = {
      name: { required: true },
      phone: { required: true },
      invoiceEmail: { required: true },
      tax_id: { required: true },
      type: { required: true },
      city: { required: true },
      country: { required: true },
      line_1: { required: true },
      postal_code: { required: true },
      state: { required: true },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.createInput = this.createInput.bind(this);
  }

  handleSubmit() {
    const { t, _csrf, onBillingChange, taxIdTypes } = this.props;
    const { address, tax_info } = this.state;

    const { city, country, line1, line2, postal_code, state } = address;
    const params = {
      ...this.state,
      tax_info: { ...tax_info },
      address: { city, country, line1, line2, postal_code, state },
    };

    // Serialize Tax Code into Tax Type (value that works on stripe)
    if (tax_info && tax_info.type) {
      const taxIdType = taxIdTypes.find(txId => txId.taxCode === tax_info.type);
      if (taxIdType) {
        params.tax_info.type = taxIdType.taxType;
      }
    }

    new Request(_csrf)
      .put('/profile/billing/edit', params)
      .done(() => {
        Notifications.showNotificationSuccess(
          t('success'),
          t('success_update'),
        );
        onBillingChange({
          shipping: {
            name: params.name,
            phone: params.phone,
            address: { ...params.address },
          },
          tax_info: { ...tax_info },
          invoiceEmail: params.invoiceEmail,
        });
      })
      .fail(err => {
        let error = t('error_profileUpdate');
        if (err && err.responseJSON) {
          error = err.responseJSON.message || t(err.responseJSON.error);
        }
        Notifications.showNotificationError(t('error'), error);
      });
  }

  handleChange(field) {
    return value => this.setState({ [field]: value });
  }

  handleAddressChange(newAddress) {
    this.setState(({ address }) => ({
      address: { ...address, ...newAddress },
    }));
  }

  handleSubSectionChange(section) {
    return field => value => {
      this.setState(state => ({
        ...state,
        [section]: { ...state[section], [field]: value },
      }));
    };
  }

  createInput(section, field, disabled, typeParam) {
    const { t, taxIdTypes } = this.props;

    const type = typeParam || 'text';
    let value;
    let changeHandler;
    if (section === 'main') {
      value = this.state[field];
      changeHandler = this.handleChange(field);
    } else {
      value = this.state[section][field];
      changeHandler = this.handleSubSectionChange(section)(field);
    }

    let placeholder = '';
    let inputProps = {};
    if (section === 'tax_info') {
      if (field === 'type') {
        inputProps = {
          required: true,
          items: taxIdTypes.map(c => ({
            id: c.taxCode,
            text: c.taxType === 'eu_vat' ? `${c.flagEmoji} ${c.countryName} VAT number` : `${c.flagEmoji} ${c.name}`,
          })),
        };
      }
      if (field === 'tax_id') {
        const taxCode = this.state.tax_info.type;
        if (taxCode) {
          const taxIdType = taxIdTypes.find(txId => txId.taxCode === taxCode);
          if (taxIdType) {
            ({ placeholder } = taxIdType);
          }
        }
      }
    }

    return (
      <FormInput
        id={field}
        name={field}
        value={value}
        onChange={changeHandler}
        label={t(`billing_${field}`)}
        labelClassName="col-2"
        containerClassName="col-7"
        type={type}
        groupProps={{ className: 'row' }}
        disabled={disabled}
        inputProps={{ ...inputProps }}
        placeholder={placeholder}
      />
    );
  }

  render() {
    const { t, countries, forceStripeCustomer, loggedUser } = this.props;
    const { address } = this.state;
    const disabled = forceStripeCustomer && !isHigherAdmin(loggedUser);

    return (
      <Form
        className="m-form m-form--fit m-form--label-align-right"
        id="userForm"
        submitMethod={this.handleSubmit}
        validationRules={this.validationRules}
      >
        {submitHandler => (
          <React.Fragment>
            <div className="m-portlet__body">
              <FormHeader title={`${t('billing_info')}`} />
              {this.createInput('main', 'name', disabled)}
              {this.createInput('main', 'phone', disabled)}
              {this.createInput('main', 'invoiceEmail', disabled, 'email')}
              <Separator />
              {this.createInput('tax_info', 'type', disabled, 'select')}
              {this.createInput('tax_info', 'tax_id', disabled)}
              <FormHeader title={`${t('billing_address')}`} />
              <FormAddressFragment
                disabled={disabled}
                address={address}
                onAddressChange={this.handleAddressChange}
                onFieldChange={this.handleSubSectionChange('address')}
                countries={countries}
              />
            </div>
            <FormActions submitHandler={submitHandler} />
          </React.Fragment>
        )}
      </Form>
    );
  }
}

BillingForm.propTypes = {
  t: PropTypes.func.isRequired,
  _csrf: PropTypes.string.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape(CountryProp.propType))
    .isRequired,
  billing: PropTypes.shape(BillingProp.propType).isRequired,
  onBillingChange: PropTypes.func.isRequired,
  forceStripeCustomer: PropTypes.bool.isRequired,
  loggedUser: PropTypes.shape(UserProp.propType),
  taxIdTypes: PropTypes.arrayOf(PropTypes.shape(TaxIdTypeProp.propType)).isRequired,
};

BillingForm.defaultProps = {
  loggedUser: null,
};

export default compose(
  withCSRF,
  withLoggedUser,
  translate,
)(BillingForm);
