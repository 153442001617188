import React from 'react';
import PropTypes from 'prop-types';
import DateRange from '../../forms/inputs/DateRange';

/**
 * Table date filter component, expects two event handlers,
 * one for dateStart and another for dateEnd
 */
export default function DateFilter({ onDateStartChange, onDateEndChange }) {
  const onDateChange = ({ startDate, endDate }) => {
    onDateStartChange(startDate);
    onDateEndChange(endDate);
  };

  const onDateClear = () => onDateChange({ startDate: '', endDate: '' });

  return <DateRange onChange={onDateChange} onClear={onDateClear} />;
}

DateFilter.propTypes = {
  /** Handler for dateStartChange event */
  onDateStartChange: PropTypes.func.isRequired,
  /** Handler for dateEndChange event */
  onDateEndChange: PropTypes.func.isRequired,
};
