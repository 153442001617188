import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import translate from '../../../commonComponents/translate';
import Modal from '../../../commonComponents/modal/Modal';
import ModalHeader from '../../../commonComponents/modal/ModalHeader';
import Select from '../../../commonComponents/forms/inputs/Select';

function OrganizationModal(props) {
  const {
    t,
    organizationIdentifier,
    handleChange,
    onSubmit,
    postingForm,
    organizationData,
    buttonCaption,
  } = props;

  const submitButtonClasses = cx(
    'btn',
    'btn-primary',
    'm-btn',
    'm-btn--pill',
    'm-btn--custom',
    'm-btn--air',
    'm-login__btn',
    'm-login__btn--brand',
    { 'm-loader': postingForm },
    { 'm-loader--right': postingForm },
    { 'm-loader--light': postingForm },
  );

  return (
    <Modal>
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <ModalHeader title={t('login_modal_title')} />
          <div className="modal-body organization-modal-body">
            <Select
              id="organizationIdentifier"
              name="organizationIdentifier"
              type="select2"
              value={organizationIdentifier}
              onChange={handleChange('organizationIdentifier')}
              className="form-control organization-selector"
              searchable={false}
              items={organizationData.map(o => ({
                id: o.identifierCode,
                text: o.name,
              }))}
              width="200px"
            />
          </div>
          <div className="modal-footer">
            <button
              className={submitButtonClasses}
              disabled={postingForm}
              type="submit"
              onClick={onSubmit}
            >
              {buttonCaption || t('submit')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

OrganizationModal.propTypes = {
  t: PropTypes.func.isRequired,
  organizationIdentifier: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  postingForm: PropTypes.bool.isRequired,
  organizationData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      identifierCode: PropTypes.string.isRequired,
    }),
  ),
  buttonCaption: PropTypes.string,
};
OrganizationModal.defaultProps = {
  organizationData: [],
  buttonCaption: null,
};

export default translate(OrganizationModal);
