import React from 'react';
import PropTypes from 'prop-types';
import { renderToStaticMarkup } from 'react-dom/server';
import * as Utils from '../../../services/utilityFunctions';
import translate from '../../../commonComponents/translate';
import List from '../../../commonComponents/lists/ClientSideList';
import BooleanColumn from '../../../commonComponents/lists/columns/BooleanColumn';
import ActionsColumn from './columns/ActionsColumn';

function InvoiceList({ t, data, loading }) {
  const columns = [
    {
      field: 'number',
      title: t('invoice_number'),
      textAlign: 'center',
      width: 100,
    },
    {
      field: 'date',
      title: t('invoice_date'),
      textAlign: 'center',
      width: 100,
    },
    {
      field: 'total',
      title: t('invoice_total'),
      textAlign: 'center',
      width: 100,
    },
    {
      field: 'paid',
      title: t('invoice_paid_status'),
      template: ({ paid }) =>
        renderToStaticMarkup(<BooleanColumn value={paid} />),
      textAlign: 'center',
      width: 100,
    },
    {
      field: 'Actions',
      width: 60,
      textAlign: 'center',
      title: t('actions'),
      sortable: false,
      overflow: 'visible',
      template: ({ pdf }) => renderToStaticMarkup(<ActionsColumn pdf={pdf} />),
    },
  ];

  const dataTransform = invoice => ({
    ...invoice,
    date: Utils.displayDate(invoice.date),
    total: Utils.displayMoney(invoice.total),
  });

  return (
    <List
      id="invoicesList"
      loading={loading}
      data={data.map(dataTransform)}
      columns={columns}
    />
  );
}

InvoiceList.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default translate(InvoiceList);
