import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from '../../services/utilityFunctions';
import Notifications from '../../services/notifications';
import Request from '../../services/request';
import translate from '../../commonComponents/translate';
import InvoiceList from './list/InvoiceList';
import InvoiceFilters from './list/InvoiceFilters';

/**
 * Subscriptions list functionality, wrapping filters and list
 */
class Invoices extends React.Component {
  constructor(props) {
    super(props);
    this.list = null;

    this.state = {
      loadingData: false,
      data: [],
      query: { startDate: '', endDate: '', limit: 100 },
    };

    this.handleQueryChange = this.handleQueryChange.bind(this);
    this.fetchData = debounce(this.fetchData.bind(this), 300);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const { t } = this.props;
    const { query } = this.state;

    this.setState({ loadingData: true });

    const params = Object.keys(query).reduce(
      (acc, key) => (query[key] ? { ...acc, [key]: query[key] } : acc),
      {},
    );

    new Request()
      .get('/invoices/list', { query: params }, true, false)
      .done(({ data }) => this.setState({ data, loadingData: false }))
      .fail(err => {
        let error = t('error_getInvoices');
        if (err && err.responseJSON) {
          error = err.responseJSON.message || t(err.responseJSON.error);
        }
        this.setState({ data: [], loadingData: false });
        Notifications.showNotificationError(t('error'), error);
      });
  }

  handleQueryChange(field) {
    return value =>
      this.setState(
        ({ query }) => ({ query: { ...query, [field]: value } }),
        this.fetchData,
      );
  }

  render() {
    const { data, loadingData } = this.state;

    return (
      <div className="m-portlet__body">
        <InvoiceFilters onChange={this.handleQueryChange} />
        <InvoiceList data={data} loading={loadingData} />
      </div>
    );
  }
}

Invoices.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(Invoices);
