/* eslint no-script-url: 0 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Metronic's brand display and side menu toggler
 */
export default function Brand({ theme }) {
  return (
    <div className="m-stack__item m-brand">
      <div className="m-stack m-stack--ver m-stack--general m-stack--inline">
        <div className="m-stack__item m-stack__item--middle m-brand__logo">
          <a href="/" className="m-brand__logo-wrapper">
            <img
              alt="brand logo"
              style={{ maxWidth: '125px' }}
              src={`/dist/${theme}/assets/demo/${theme}/media/img/logo/logo-dark.png`}
            />
          </a>
        </div>
        <div className="m-stack__item m-stack__item--middle m-brand__tools">
          <a
            href="javascript:;"
            id="m_aside_header_menu_mobile_toggle"
            className="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block"
          >
            <span />
          </a>
          <a
            id="m_aside_header_topbar_mobile_toggle"
            href="javascript:;"
            className="m-brand__icon m--visible-tablet-and-mobile-inline-block"
          >
            <i className="flaticon-more" />
          </a>
        </div>
      </div>
    </div>
  );
}

Brand.propTypes = {
  /** metronic theme in use */
  theme: PropTypes.string.isRequired,
};
