import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as Utils from '../../../../../services/utilityFunctions';
import translate from '../../../../../commonComponents/translate';
import ProductImage from '../pack/ProductImage';
import ProductProp from '../../../propTypes/ProductProp';
import QuantityModal from './QuantityModal';
import Switch from '../../../../../commonComponents/forms/inputs/Switch';

class ProductItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showQuantityModal: false };

    this.handleClick = this.handleClick.bind(this);
    this.handleProductAdd = this.handleProductAdd.bind(this);
    this.handleQuantityModalClose = this.handleQuantityModalClose.bind(this);
    this.openDescriptionModal = this.openDescriptionModal.bind(this);
  }

  handleClick(evt) {
    evt.stopPropagation();
    const { product, onClickRemove, isInCart } = this.props;
    const cb = isInCart ? onClickRemove : this.handleProductAdd;
    cb(product);
  }

  openDescriptionModal(evt) {
    // ignoring clicks on input labels
    if (evt.target.tagName !== 'INPUT' && evt.target.tagName !== 'LABEL') {
      const { onOpenDescriptionModal, product } = this.props;
      onOpenDescriptionModal(product);
    }
  }

  handleProductAdd() {
    const { product, onClickAdd } = this.props;
    if (!product.allowQuantity) {
      onClickAdd({ product, quantity: 1 });
    } else {
      this.setState({ showQuantityModal: true });
    }
  }

  handleQuantityModalClose(quantity) {
    const { product } = this.props;
    this.setState({ showQuantityModal: false }, () => {
      if (quantity) {
        this.props.onClickAdd({ product, quantity });
      }
    });
  }

  render() {
    const { t, product, isInCart, isSubscriptionEdit, isPack } = this.props;
    const { showQuantityModal } = this.state;
    const { type, isBought, isFreeTrial, isDefaultForSubscription } = product;

    const isSubscription = type === Utils.PRODUCT_TYPE.SUBSCRIPTION;
    const showBought = isBought && type === Utils.PRODUCT_TYPE.SINGLE_BUY;
    const markAsBought =
      (showBought && isSubscriptionEdit) ||
      (showBought && !isSubscriptionEdit && !isFreeTrial);
    const tempAddText = markAsBought ? 'bought' : 'add';
    const addText = isSubscription ? 'subscribe' : tempAddText;
    const removeText = isSubscription ? 'unsubscribe' : 'remove';

    return (
      <React.Fragment>
        <div onClick={this.openDescriptionModal} className="product-item">
          <ProductImage
            className="product-item-img"
            product={{
              ...product,
              belongsToPack: isPack,
              isInCart,
              isSubscription,
              showBought,
            }}
            light={isPack}
          />
          <div className="product-name">{product.name}</div>
          <div className="product-item-add">
            {isSubscription ? (
              <Switch
                onClick={this.handleClick}
                checked={isInCart}
                id={product._id}
              />
            ) : (
              <button
                disabled={
                  showBought ||
                  (!isSubscriptionEdit && isDefaultForSubscription)
                }
                type="button"
                className={cx(
                  'btn m-btn--pill m-btn--air btn-sm m-btn m-btn--custom',
                  { 'cat-btn-available': !isInCart && !showBought },
                  { 'cat-btn-remove': isInCart && !showBought },
                  { 'cat-btn-remove-no-hover': showBought },
                )}
                onClick={this.handleClick}
              >
                {isInCart ? t(removeText) : t(addText)}
              </button>
            )}
          </div>
        </div>
        {showQuantityModal && (
          <QuantityModal onClose={this.handleQuantityModalClose} />
        )}
      </React.Fragment>
    );
  }
}

ProductItem.propTypes = {
  t: PropTypes.func.isRequired,
  product: PropTypes.shape(ProductProp.propType),
  isInCart: PropTypes.bool.isRequired,
  isSubscriptionEdit: PropTypes.bool,
  onClickAdd: PropTypes.func.isRequired,
  onClickRemove: PropTypes.func.isRequired,
  onOpenDescriptionModal: PropTypes.func.isRequired,
  isPack: PropTypes.bool,
  interval: PropTypes.string.isRequired,
};

ProductItem.defaultProps = {
  isSubscriptionEdit: true,
  product: ProductProp.defaultProp,
  isPack: false,
};

export default translate(ProductItem);
