import React from 'react';
import PropTypes from 'prop-types';

export default function PriceLine(props) {
  const { label, value, labelClassName, valueClassName, removeItem } = props;

  return (
    <div className="price-line-container">
      <div className="prices-item">
        <div className={labelClassName}>{label}</div>
        <div className={valueClassName}>{value}</div>
      </div>
      {removeItem ? (
        <button
          className="prices-item-remove"
          type="button"
          onClick={removeItem}
        >
          <i className="flaticon-cancel" />
        </button>
      ) : (
        <div className="prices-item-remove">
          <div style={{ width: '21px' }} />
        </div>
      )}
    </div>
  );
}

PriceLine.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  removeItem: PropTypes.func,
  labelClassName: PropTypes.string.isRequired,
  valueClassName: PropTypes.string.isRequired,
};

PriceLine.defaultProps = {
  removeItem: null,
};
