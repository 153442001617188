import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import TranslationContext from './contextProviders/TranslationContext';
import CSRFContext from './contextProviders/CSRFContext';
import StripeContext from './contextProviders/StripeContext';
import Header from './commonComponents/pageLayout/header/Header';
import Footer from './commonComponents/pageLayout/Footer';
import ScrollTop from './commonComponents/pageLayout/ScrollTop';
import NotFound from './sections/error/NotFound';
import Profile from './sections/profile/Profile';
import SignIn from './sections/signIn/SignIn';
import Error from './sections/error/Error';
import Trial from './sections/trial/Trial';
import TrialCreated from './sections/trial/TrialCreated';
import SubscriptionDetails from './sections/subscriptionDetails/SubscriptionDetails';
import TawkTo from './services/tawkTo';
import LoggedUserContext from './contextProviders/LoggedUserContext';
import RecoverPasswordForm from './sections/signIn/RecoverPasswordForm';

/**
 * Wraps a component in the default page layout, header/footer, etc
 */
function wrapInDefaultLayout(Component, props) {
  const { theme, profile, tenant, version, ...other } = props;
  return routeProps => {
    const withRoute = { ...other, ...routeProps };
    return (
      <React.Fragment>
        <Header theme={theme} profile={profile} />
        <Component {...withRoute} />
        <Footer version={version} tenant={tenant} />
        <ScrollTop />
      </React.Fragment>
    );
  };
}

/**
 * Renders the error page, using the error received from the server, or a client side error
 */
function renderError(props, clientSideError) {
  const finalProps = {
    ...clientSideError,
    ...props,
    status: props.status || 500,
  };
  return wrapInDefaultLayout(Error, finalProps)();
}

/**
 * React app entry point, creates context providers and an error boundary and
 * wraps most of the pages in a Header/Footer layout, as needed.
 */
export default class Main extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false, error: undefined };
  }

  componentDidMount() {
    const tawkTo = new TawkTo('5d416e5ee5ae967ef80daede', this.props.profile);
    tawkTo.init();
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error: { message: error.toString(), stack: info.componentStack },
    });
  }

  render() {
    const { t, _csrf, stripe, profile } = this.props;
    const { hasError, error } = this.state;

    return (
      <TranslationContext.Provider value={t}>
        <CSRFContext.Provider value={_csrf}>
          <StripeContext.Provider value={stripe}>
            <LoggedUserContext.Provider value={profile}>
              <DocumentTitle title={t('default_head_title')}>
                <div className="m-grid m-grid--hor m-grid--root m-page">
                  {hasError || this.props.error ? (
                    <React.Fragment>
                      {renderError(this.props, { error })}
                    </React.Fragment>
                  ) : (
                    <Switch>
                      <Route
                        path="/signin"
                        render={() => <SignIn {...this.props} />}
                      />
                      <Route
                        path="/password/recover/:token"
                        render={() => <RecoverPasswordForm {...this.props} />}
                      />
                      <Route
                        path="/trial/created"
                        render={() => <TrialCreated {...this.props} />}
                      />
                      <Route
                        path="/trial"
                        render={() => <Trial {...this.props} />}
                      />
                      <Route
                        path="/profile"
                        render={wrapInDefaultLayout(Profile, this.props)}
                      />
                      <Route
                        exact
                        path="/subscription/create"
                        render={wrapInDefaultLayout(
                          SubscriptionDetails,
                          this.props,
                        )}
                      />
                      <Route
                        exact
                        path="/subscription/free"
                        render={() => <Trial {...this.props} />}
                      />
                      <Route
                        exact
                        path="/subscription/active"
                        render={wrapInDefaultLayout(
                          SubscriptionDetails,
                          this.props,
                        )}
                      />
                      <Route
                        path="/shop"
                        render={wrapInDefaultLayout(
                          SubscriptionDetails,
                          this.props,
                        )}
                      />
                      <Route
                        exact
                        path="/"
                        render={wrapInDefaultLayout(
                          SubscriptionDetails,
                          this.props,
                        )}
                      />
                      <Route
                        exact
                        path="*"
                        render={wrapInDefaultLayout(NotFound, this.props)}
                      />
                    </Switch>
                  )}
                </div>
              </DocumentTitle>
            </LoggedUserContext.Provider>
          </StripeContext.Provider>
        </CSRFContext.Provider>
      </TranslationContext.Provider>
    );
  }
}

Main.propTypes = {
  /** translation function, i18n.__ on the browser, response.__ on the server */
  t: PropTypes.func.isRequired,
  /** Cross site request forgery token, used on non-get requests to the server */
  _csrf: PropTypes.string,
};

Main.defaultProps = {
  profile: null,
  _csrf: '',
};
