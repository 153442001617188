import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../../../commonComponents/translate';
import {
  SUBSCRIPTION_TYPE,
  SUBSCRIPTION_TYPES,
} from '../../../../services/utilityFunctions';

function ActionsColumn({ t, subscription }) {
  const { originalType, isActive } = subscription;

  if (isActive) {
    if (originalType === SUBSCRIPTION_TYPE.TRIAL) {
      return (
        <a
          href="/subscription/create"
          className="btn m-btn btn-primary m-btn--pill"
          title={t('header_banner_upgrade')}
        >
          {t('header_banner_upgrade')}
        </a>
      );
    }

    return (
      <button
        type="button"
        className={
          'btn btn-danger m-btn m-btn--custom m-btn--pill' +
          ' cancel-button loader-button'
        }
        title={t('subscription_cancel')}
      >
        {t('subscription_cancel')}
      </button>
    );
  }

  return <span />;
}

ActionsColumn.propTypes = {
  t: PropTypes.func.isRequired,
  subscription: PropTypes.shape({
    originalType: PropTypes.oneOf(SUBSCRIPTION_TYPES).isRequired,
    isActive: PropTypes.bool.isRequired,
  }).isRequired,
};

export default translate(ActionsColumn);
