import React from 'react';
import PropTypes from 'prop-types';

/**
 * Metronic modal container
 */
export default class Modal extends React.Component {
  componentDidMount() {
    $(`#${this.props.id}`).modal({ backdrop: 'static' });
  }

  componentWillUnmount() {
    $(`#${this.props.id}`).modal('hide');
  }

  render() {
    const { id, children } = this.props;
    return (
      <div className="modal fade" id={id} tabIndex="-1" role="dialog">
        {children}
      </div>
    );
  }
}

Modal.propTypes = {
  /** modal container html id */
  id: PropTypes.string,
  /** React components to render inside the modal */
  children: PropTypes.node.isRequired,
};

Modal.defaultProps = {
  id: 'modalContainer',
};
