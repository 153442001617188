import PropTypes from 'prop-types';
import ProductProp from './ProductProp';

const propType = {
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.shape(ProductProp.propType)).isRequired,
};

export default { propType };
