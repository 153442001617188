import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import UserProp from './propTypes/userProp';
import BillingProp from './propTypes/billingProp';
import SidePanel from './sidePanel/SidePanel';
import MainPanel from './mainPanel/MainPanel';
import translate from '../../commonComponents/translate';
import CountryProp from '../../commonComponents/forms/Address/CountryProp';
import { PROFILE_TABS } from '../../services/utilityFunctions';
import ContentContainer from '../../commonComponents/pageLayout/ContentContainer';
import StripePaymentMethodsProp from '../payments/propTypes/StripePaymentMethodsProp';
import TaxIdTypeProp from "../subscriptionDetails/propTypes/TaxIdTypeProp";

/**
 * User profile main page
 */
class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      avatar: { ...props.member.image },
      paymentMethods: props.paymentMethods.slice(),
      billing: { ...props.billing },
      member: { ...props.member },
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(field) {
    return value => this.setState({ [field]: value });
  }

  render() {
    const {
      t,
      activeTab,
      countries,
      isActive,
      forceStripeCustomer,
      SEPACountries,
      taxIdTypes,
    } = this.props;
    const { avatar, paymentMethods, billing, member } = this.state;

    return (
      <DocumentTitle title={t('profile_head_title')}>
        <ContentContainer>
          <div className="m-content">
            <div className="row">
              <div className="col-lg-4">
                <SidePanel
                  name={member.name}
                  email={member.email}
                  avatarURL={avatar.url}
                  onAvatarChange={this.handleChange('avatar')}
                />
              </div>
              <div className="col-lg-8">
                <MainPanel
                  activeTab={activeTab}
                  countries={countries}
                  avatar={avatar}
                  member={member}
                  onMemberChange={this.handleChange('member')}
                  paymentMethods={paymentMethods}
                  onCardsChange={this.handleChange('paymentMethods')}
                  billing={billing}
                  onBillingChange={this.handleChange('billing')}
                  subscriptionIsInactive={!isActive}
                  forceStripeCustomer={forceStripeCustomer}
                  SEPACountries={SEPACountries}
                  taxIdTypes={taxIdTypes}
                />
              </div>
            </div>
          </div>
        </ContentContainer>
      </DocumentTitle>
    );
  }
}

Profile.propTypes = {
  t: PropTypes.func.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.shape(StripePaymentMethodsProp.propType)).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape(CountryProp.propType))
    .isRequired,
  activeTab: PropTypes.oneOf(PROFILE_TABS),
  member: PropTypes.shape(UserProp.propType).isRequired,
  billing: PropTypes.shape(BillingProp.propType).isRequired,
  forceStripeCustomer: PropTypes.bool.isRequired,
  isActive: PropTypes.bool,
  SEPACountries: PropTypes.arrayOf(PropTypes.shape(CountryProp.propType)).isRequired,
  taxIdTypes: PropTypes.arrayOf(PropTypes.shape(TaxIdTypeProp.propType)).isRequired,
};

Profile.defaultProps = {
  activeTab: null,
  isActive: true,
};

export default translate(Profile);
