import React from 'react';
import PropTypes from 'prop-types';
import FormValidator from '../../../services/formValidation';
import translate from '../../../commonComponents/translate';
import FormInput from '../../../commonComponents/forms/FormInput';

class Info extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.info,
      termsAccepted: false,
      solutions: [],
      finishDisabled: true,
    };

    this.validationRules = {
      name: { required: true },
      email: { required: true, email: true },
      organizationName: { required: true },
    };

    this.formId = 'infoForm';

    this.handleChange = this.handleChange.bind(this);
    this.handleAcceptanceChange = this.handleAcceptanceChange.bind(this);
    this.handleUnselect = this.handleUnselect.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
  }

  componentDidMount() {
    FormValidator.config();
  }

  handleChange(field) {
    if (field === 'solutions') {
      return value =>
        this.setState(prev => ({
          ...prev,
          solutions: [...prev.solutions, value],
        }));
    }
    return value => this.setState({ [field]: value });
  }

  handleAcceptanceChange() {
    this.setState(state => ({
      ...state,
      finishDisabled: !state.finishDisabled,
    }));
  }

  handleUnselect(field) {
    return value =>
      this.setState(prev => ({
        ...prev,
        [field]: prev[field].filter(s => s !== value),
      }));
  }

  submitHandler(isForward) {
    return evt => {
      evt.preventDefault();

      const form = $(`#${this.formId}`);
      form.validate({ rules: this.validationRules });

      if (form.valid()) {
        const info = { ...this.state };
        delete info.confirmPassword;
        delete info.finishDisabled;
        const cb = isForward ? this.props.onFinish : this.props.onBack;
        cb({ info });
      } else if (isForward) {
        document.querySelector('.has-danger').scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
      } else {
        this.props.onBack({});
      }
    };
  }

  createInput(field, type, inputProps) {
    const { t } = this.props;
    return (
      <FormInput
        id={field}
        name={field}
        value={this.state[field]}
        onChange={this.handleChange(field)}
        label={t(`trial_info_${field}`)}
        labelClassName="col-3"
        containerClassName="col-9"
        type={type || 'text'}
        groupProps={{ className: 'row' }}
        inputProps={inputProps}
      />
    );
  }

  render() {
    const { t, isSuperAdmin, solutions } = this.props;
    const { finishDisabled } = this.state;

    const appName = 'Bravon';
    const privacyLink = 'https://www.bravon.io/privacy-policy/';
    const termsLink = 'https://www.bravon.io/terms-conditions/';

    return (
      <div className="trial-info-container">
        <div className="trial-info-form">
          <div className="trial-info-form-wrapper">
            <div className="trial-info-form-content">
              <div>
                <h1>
                  {t('trial_info_greeting_title')}
                  <b>Bravon</b>
                </h1>
                <h2>{t('trial_info_greeting')}</h2>
              </div>
              <div className="trial-info-form-inputs">
                <form
                  className="m-form m-form--fit m-form--label-align-right"
                  id={this.formId}
                >
                  {this.createInput('solutions', 'select2', {
                    searchable: false,
                    multiple: true,
                    items: solutions.map(s => ({ id: s._id, text: s.name })),
                    onUnselect: this.handleUnselect('solutions'),
                  })}
                  {this.createInput('name')}
                  {this.createInput('email', 'email')}
                  {this.createInput('organizationName')}
                  <div className="form-group">
                    <div className="col-md-12">
                      <label
                        htmlFor="acceptTerms"
                        className="m-checkbox m-checkbox-outline"
                        style={{
                          marginTop: '2rem',
                          fontSize: '12px',
                          textAlign: 'justify',
                        }}
                      >
                        <input
                          type="checkbox"
                          id="acceptTerms"
                          onChange={this.handleAcceptanceChange}
                        />
                        {`I understand and agree that the use of ${appName}'s website is subject to the `}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={termsLink}
                        >
                          {`${appName}'s Terms of Use`}
                        </a>
                        {`. Additional details regarding ${appName}'s collection and use of your personal information is available in the `}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={privacyLink}
                        >
                          {`${appName} Privacy Policy`}
                        </a>
                        <span />
                      </label>
                    </div>
                  </div>
                  <div
                    className="trial-info-next-container"
                    style={
                      isSuperAdmin
                        ? {}
                        : {
                            justifyContent: 'flex-end',
                            paddingRight: '30px',
                          }
                    }
                  >
                    {isSuperAdmin && (
                      <button
                        className="trial-info-back-button"
                        type="submit"
                        onClick={this.submitHandler(false)}
                      >
                        <i className="fa fa-arrow-left" />
                        {t('trial_info_back')}
                      </button>
                    )}
                    <button
                      disabled={finishDisabled}
                      className="trial-info-next-button loader-button"
                      type="submit"
                      onClick={this.submitHandler(true)}
                    >
                      {t('trial_finish')}
                      <i className="fa fa-arrow-right" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="trial-demo-container">
          <img
            alt="platform demo"
            className="demo-image"
            src="/images/trial/trial_step_2.png"
          />
        </div>
      </div>
    );
  }
}

Info.propTypes = {
  /** Translation function */
  t: PropTypes.func.isRequired,
  /** Finish trial event handler */
  onFinish: PropTypes.func.isRequired,
  /** Backwards navigation event handler */
  onBack: PropTypes.func.isRequired,
  /** Already saved info, in case we're navigating back and forth */
  info: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    organizationName: PropTypes.string,
  }),
  /** Whether the user is a super admin, to display a back button */
  isSuperAdmin: PropTypes.bool.isRequired,
  /** Solutions available for the user */
  solutions: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
};

Info.defaultProps = {
  info: {
    name: '',
    email: '',
    organizationName: '',
  },
  solutions: [],
};

export default translate(Info);
