import React from 'react';
import PropTypes from 'prop-types';

export default function BooleanColumn({ value }) {
  return value ? (
    <div className="m--font-success">
      <i className="fa fa-check" />
    </div>
  ) : (
    <div className="m--font-danger">
      <i className="fa fa-times" />
    </div>
  );
}

BooleanColumn.propTypes = {
  value: PropTypes.bool.isRequired,
};
