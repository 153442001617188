import React from 'react';
import PropTypes from 'prop-types';

/**
 * Footer component, implemented in React so we can show it and hide it whenever it makes sense
 */
export default function Footer({ version, tenant }) {
  const { branding, terms } = tenant || {};
  return (
    <footer className="m-grid__item m-footer ">
      <div className="m-container m-container--responsive m-container--xxl m-container--full-height m-page__container">
        <div className="m-footer__wrapper">
          <div className="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop">
            <div className="m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--last">
              <span className="m-footer__copyright">
                {`${new Date().getFullYear()} © `}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://bravon.io"
                >
                  Bravon
                </a>
                {terms && (
                  <span style={{ whiteSpace: 'pre-wrap' }}>
                    {' - '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={terms.termsLink}
                    >
                      {`${branding.appName}'s Terms of Use`}
                    </a>
                    {' & '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={terms.privacyLink}
                    >
                      {`${branding.appName} Privacy Policy`}
                    </a>
                  </span>
                )}
              </span>
            </div>
            <div className="m-stack__item m-stack__item--right m-stack__item--middle m-stack__item--first">
              <span className="m-footer__copyright">{version}</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  tenant: PropTypes.shape({
    branding: PropTypes.shape({ appName: PropTypes.string }),
    terms: PropTypes.shape({
      termsLink: PropTypes.string,
      privacyLink: PropTypes.string,
    }),
  }),
  /** App version to display on form footer in development builds */
  version: PropTypes.string.isRequired,
};

Footer.defaultProps = {
  tenant: null,
};
