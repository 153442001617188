import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../commonComponents/translate';

/**
 * Trial navigation buttons displayed on the bottom of the form
 */
function NavigationButtons({ t, onBack, onNext, disableForward }) {
  return (
    <div className="trial-info-next-container">
      <button className="trial-info-back-button" type="button" onClick={onBack}>
        <i className="fa fa-arrow-left" />
        {t('trial_info_back')}
      </button>
      <button
        disabled={disableForward}
        className="trial-info-next-button"
        type="submit"
        onClick={onNext}
      >
        {t('trial_info_next')}
        <i className="fa fa-arrow-right" />
      </button>
    </div>
  );
}

NavigationButtons.propTypes = {
  /** Translation function */
  t: PropTypes.func.isRequired,
  /** Back navigation handler */
  onBack: PropTypes.func,
  /** Forward navigation handler */
  onNext: PropTypes.func,
  /** Disable forward navigation */
  disableForward: PropTypes.bool,
};

NavigationButtons.defaultProps = {
  onBack: () => null,
  onNext: () => null,
  disableForward: false,
};

export default translate(NavigationButtons);
