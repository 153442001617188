import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import translate from '../../../../../commonComponents/translate';
import ProductImage from './ProductImage';
import PackProp from '../../../propTypes/PackProp';

class Pack extends React.PureComponent {
  render() {
    const {
      t,
      pack,
      isInCart,
      onAdd,
      onRemove,
      onOpenModal,
      hideAddButton,
      isSubscriptionEdit,
    } = this.props;

    const handleClick = evt => {
      evt.stopPropagation();
      if (isInCart) {
        onRemove(pack._id);
      } else {
        onAdd(pack);
      }
    };

    const { name, description, products, isBought, isFreeTrial } = pack;
    const markAsBought =
      (isBought && isSubscriptionEdit) ||
      (isBought && !isSubscriptionEdit && !isFreeTrial);
    const addText = markAsBought ? 'bought' : 'add';

    return (
      <div className="subscription-pack" onClick={() => onOpenModal(pack)}>
        <div className="pack-header">
          <ProductImage
            product={{ ...pack, belongsToPack: false }}
            className="product-item-img"
            light={false}
          />
          <h4>{name}</h4>
        </div>
        <div className="pack-body">
          <div className="pack-description">{description}</div>
          <div className="pack-product">
            <div className="pack-product-header">
              <span className="pack-product-size">{products.length}</span>
              <span className="pack-product-title">
                {t('subscriptionCreate_options_products')}
              </span>
            </div>
            <div className="pack-product-images">
              {products.map(s => (
                <ProductImage
                  key={s._id}
                  product={{ ...s, belongsToPack: true }}
                  className="product-item-img"
                  light
                  showPopup
                />
              ))}
            </div>
          </div>
        </div>
        <button
          disabled={markAsBought}
          type="button"
          className={cx(
            'btn m-btn--pill m-btn--air btn-md m-btn m-btn--custom button-center',
            { 'button-available': !isInCart && !markAsBought },
            { 'button-bought': markAsBought && !isInCart },
            { 'button-available': isInCart },
          )}
          onClick={handleClick}
          style={hideAddButton ? { visibility: 'hidden' } : {}}
        >
          {isInCart ? t('remove') : t(addText)}
        </button>
      </div>
    );
  }
}

Pack.propTypes = {
  t: PropTypes.func.isRequired,
  pack: PropTypes.shape(PackProp.propType).isRequired,
  isInCart: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  hideAddButton: PropTypes.bool,
  isSubscriptionEdit: PropTypes.bool,
};

Pack.defaultProps = {
  isSubscriptionEdit: true,
  hideAddButton: false,
};

export default translate(Pack);
