import React from 'react';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Modal from '../../../commonComponents/modal/Modal';
import ModalHeader from '../../../commonComponents/modal/ModalHeader';
import translate from '../../../commonComponents/translate';
import SEPAForm from './SEPAForm';
import { compose } from '../../../services/utilityFunctions';
import injectStripe from '../../../commonComponents/injectStripe';
import ShippingProp from '../../profile/propTypes/shippingProp';
import CountryProp from '../../../commonComponents/forms/Address/CountryProp';

function SEPAModal({ t, stripe, shipping, invoiceEmail, SEPACountries, postingForm, onFinish, onClose }) {
  const stripePromise = loadStripe(stripe.key);
  const paymentMethod = {
    accountName: shipping.name,
    accountEmail: invoiceEmail,
  };
  return (
    <Modal>
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <ModalHeader onClose={onClose} title={t('add_sepa_payment')} />
          <div className="modal-body">
            <div className="subscription-payment-container">
              <div className="payment-cc-form" />
              <Elements stripe={stripePromise}>
                <SEPAForm
                  onFinish={onFinish}
                  postingForm={postingForm}
                  paymentMethod={paymentMethod}
                  SEPACountries={SEPACountries}
                  billingCountry={shipping.address.country}
                />
              </Elements>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

SEPAModal.propTypes = {
  t: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  postingForm: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  stripe: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }).isRequired,
  shipping: PropTypes.shape(ShippingProp.propType),
  invoiceEmail: PropTypes.string,
  SEPACountries: PropTypes.arrayOf(PropTypes.shape(CountryProp.propType))
    .isRequired,
};

SEPAModal.defaultProps = {
  invoiceEmail: '',
  shipping: ShippingProp.defaultProp,
};

export default compose(
  translate,
  injectStripe,
)(SEPAModal);
