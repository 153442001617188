import PropTypes from 'prop-types';
import ImageProp from './imageProp';

const propType = {
  _id: PropTypes.string,
  username: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  password: PropTypes.string,
  genre: PropTypes.string,
  image: PropTypes.shape(ImageProp.propType),
};

const defaultProp = {
  _id: '',
  username: '',
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  genre: '',
  image: ImageProp.defaultProp,
};

export default { propType, defaultProp };
