import React from 'react';
import PropTypes from 'prop-types';
import SubscriptionInfo from '../../../services/subscriptionInfo';
import translate from '../../translate';
import Notifications from '../../../services/notifications';

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isExpiring: false };
  }

  componentDidMount() {
    const { t } = this.props;

    SubscriptionInfo.isExpiring()
      .then(isExpiring => this.setState({ isExpiring }))
      .catch(err => {
        let error = t('error_getSubscriptionInfo');
        if (err && err.responseJSON) {
          error = err.responseJSON.message || t(err.responseJSON.error);
        }
        Notifications.showNotificationError(t('error'), error);
      });
  }

  render() {
    const { t } = this.props;
    const { isExpiring } = this.state;

    return isExpiring ? (
      <div className="header-banner">
        <div
          className={
            'm-portlet m-portlet--brand' +
            ' m-portlet--head-solid-bg m-portlet--bordered'
          }
        >
          <div className="m-portlet__head" style={{ height: '50px' }}>
            <div className="m-portlet__head-caption">
              <div className="m-portlet__head-title">
                <h3 className="m-portlet__head-text">
                  {t('header_banner_expiring')}
                </h3>
              </div>
            </div>
            <div className="m-portlet__head-tools">
              <ul className="m-portlet__nav">
                <li className="m-portlet__nav-item">
                  <a
                    href="/subscription/create"
                    className={
                      'm-portlet__nav-link btn btn-light' +
                      ' m-btn m-btn--pill m-btn--air'
                    }
                  >
                    {t('header_banner_upgrade')}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <span />
    );
  }
}

Banner.propTypes = {
  /** Translation function */
  t: PropTypes.func.isRequired,
};

export default translate(Banner);
