import PropTypes from 'prop-types';
import ProductProp from './ProductProp';
import PackProp from './PackProp';
import DiscountProp from './DiscountProp';

const propType = {
  limitUsers: PropTypes.number.isRequired,
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      users: PropTypes.number,
      interval: PropTypes.string,
    }),
  ).isRequired,
  packs: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.shape(PackProp.propType).isRequired,
      quantity: PropTypes.number.isRequired,
      interval: PropTypes.string,
    }),
  ).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.shape(ProductProp.propType).isRequired,
      quantity: PropTypes.number.isRequired,
      interval: PropTypes.string,
    }),
  ).isRequired,
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.shape(ProductProp.propType).isRequired,
      quantity: PropTypes.number.isRequired,
      interval: PropTypes.string,
    }),
  ).isRequired,
  discounts: PropTypes.arrayOf(PropTypes.shape(DiscountProp.propType))
    .isRequired,
};

export default { propType };
