import React from 'react';
import PropTypes from 'prop-types';
import DateFilter from '../../../commonComponents/lists/filters/DateFilter';

export default function InvoiceFilters({ onChange }) {
  return (
    <div className="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
      <div className="row">
        <div className="col-xl-8 col-md-8" />
        <div className="col-xl-4 col-md-4 order-1 order-xl-1 m--align-right">
          <DateFilter
            onDateStartChange={onChange('startDate')}
            onDateEndChange={onChange('endDate')}
          />
        </div>
      </div>
    </div>
  );
}

InvoiceFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
};
