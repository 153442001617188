import React from 'react';
import LoggedUserContext from '../contextProviders/LoggedUserContext';

export default function injectStripe(Component) {
  const C = props => {
    const { wrappedComponentRef, ...remainingProps } = props;

    return (
      <LoggedUserContext.Consumer>
        {loggedUser => (
          <Component
            loggedUser={loggedUser}
            {...remainingProps}
            ref={wrappedComponentRef}
            wrappedComponentRef={wrappedComponentRef}
          />
        )}
      </LoggedUserContext.Consumer>
    );
  };

  C.displayName = `withLoggedUser(${Component.displayName || Component.name})`;
  C.WrappedComponent = Component;

  return C;
}
