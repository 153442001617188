import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import CategoryProp from './propTypes/CategoryProp';
import PackProp from './propTypes/PackProp';
import PlanProp from './propTypes/PlanProp';
import CountryProp from '../../commonComponents/forms/Address/CountryProp';
import Notifications from '../../services/notifications';
import Request from '../../services/request';
import translate from '../../commonComponents/translate';
import withCSRF from '../../commonComponents/withCSRF';
import ContentContainer from '../../commonComponents/pageLayout/ContentContainer';
import NavigationMenu from './navigation/NavigationMenu';
import Solutions from './steps/solutions/Solutions';
import Options from './steps/options/Options';
import Payment from './steps/payment/Payment';
import Shipping from './steps/billing/Billing';
import SubscriptionProp from './propTypes/SubscriptionProp';
import BillingProp from '../profile/propTypes/billingProp';
import SubscriptionInfo from '../../services/subscriptionInfo';
import DiscountProp from './propTypes/DiscountProp';
import TaxInfoProp from './propTypes/TaxInfoProp';
import TaxIdTypeProp from './propTypes/TaxIdTypeProp';

import {
  calcCurrentTotal,
  markDiscountApplication,
} from '../../services/cartCalculations';
import {
  compose,
  isHigherAdmin,
  PRODUCT_TYPE,
  SUBSCRIPTION_TYPE,
} from '../../services/utilityFunctions';
import UserProp from '../profile/propTypes/userProp';
import withLoggedUser from '../../commonComponents/withLoggedUser';
import StripePaymentMethodsProp from '../payments/propTypes/StripePaymentMethodsProp';

class SubscriptionDetails extends React.Component {
  static fillStepsFromProps(
    { plans = [] },
    billing = {},
    paymentMethods,
    hasEditPrivileges,
  ) {
    const step1 = plans.length > 0;

    const step2 = true;

    const shipping = billing.shipping || {};
    const address = shipping.address || {};
    const filledAddress = !!(
      address.city &&
      address.country &&
      address.line1 &&
      address.postal_code &&
      address.state
    );
    const step3 = !!(
      filledAddress &&
      shipping.name &&
      shipping.phone &&
      billing.invoiceEmail
    );

    const step4 = hasEditPrivileges ? !!paymentMethods.length : true;

    return { 1: step1, 2: step2, 3: step3, 4: step4 };
  }

  static handlePlan(plans) {
    return plans.slice(0, 1).map(p => ({ _id: p._id }));
  }

  static handleProducts(categories) {
    return categories.reduce(
      (allProducts, c) => allProducts.concat(c.products),
      [],
    );
  }

  static serializeData(subscription, plans, categories) {
    const propPlans = subscription.plans || [];
    const bravonPlan = SubscriptionDetails.handlePlan(plans);
    const allProducts = SubscriptionDetails.handleProducts(categories);
    const propSubs = allProducts
      .filter(p => p.type === PRODUCT_TYPE.SUBSCRIPTION && p.isBought)
      .map(p => ({ product: p, quantity: 1, interval: p.interval }));
    const mandatoryProductsOnCreate = allProducts
      .filter(p => p.isDefaultForSubscription)
      .map(p => ({ product: { ...p, mandatory: true }, quantity: 1, interval: p.interval }));
    return {
      propPlans,
      bravonPlan,
      propSubs,
      mandatoryProductsOnCreate,
    };
  }

  constructor(props) {
    super(props);
    const { subscription, plans, categories } = props;

    const {
      propPlans,
      bravonPlan,
      propSubs,
      mandatoryProductsOnCreate,
    } = SubscriptionDetails.serializeData(subscription, plans, categories);

    this.state = {
      postingForm: false,
      showErrors: false,
      selectedIndex: props.toShop ? 2 : 1,
      interval: props.interval,
      cart: {
        limitUsers: props.isEdit ? props.subscription.limitUsers : 1,
        plans: props.isEdit ? propPlans : bravonPlan,
        subscriptions: props.isEdit ? propSubs : [],
        packs: [],
        products: props.isEdit ? [] : mandatoryProductsOnCreate,
        discounts: props.activeDiscount
          ? [{ ...props.activeDiscount, applied: true }]
          : [],
      },
      shipping: {
        address: { ...props.billing.shipping.address },
        name: props.billing.shipping.name || '',
        phone: props.billing.shipping.phone || '',
      },
      invoiceEmail: props.billing.invoiceEmail || '',
      tax_info: { ...props.billing.tax_info },
      hasPendingChanges: props.subscription.hasPendingChanges,
      paymentMethods: props.paymentMethods.slice(),
      filledSteps: SubscriptionDetails.fillStepsFromProps(
        props.subscription,
        props.billing,
        props.paymentMethods,
        !props.forceStripeCustomer || isHigherAdmin(props.loggedUser),
      ),
      manualInvoice: false,
      plans: props.plans,
      categories: props.categories,
      packs: props.packs,
    };

    this.currentStep = null;

    this.handleResetErrors = this.handleResetErrors.bind(this);
    this.handleUsersChange = this.handleUsersChange.bind(this);
    this.handlePackAdd = this.handlePackAdd.bind(this);
    this.handlePackRemove = this.handlePackRemove.bind(this);
    this.handleProductAdd = this.handleProductAdd.bind(this);
    this.handleProductRemove = this.handleProductRemove.bind(this);
    this.handleSubscriptionAdd = this.handleSubscriptionAdd.bind(this);
    this.handleSubscriptionRemove = this.handleSubscriptionRemove.bind(this);
    this.handleDiscountAdd = this.handleDiscountAdd.bind(this);
    this.handleDiscountRemove = this.handleDiscountRemove.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.checkDiscountApplication = this.checkDiscountApplication.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNavigation = this.handleNavigation.bind(this);
    this.attemptToNavigate = this.attemptToNavigate.bind(this);
    this.handleManualInvoiceSelection = this.handleManualInvoiceSelection.bind(this);
    this.onIntervalChange = this.onIntervalChange.bind(this);
    this.handleRequestError = this.handleRequestError.bind(this);
  }

  async onIntervalChange(interval) {
    try {
      const { isEdit, subscription, activeDiscount } = this.props;
      const { cart: previousCart, interval: previousInterval } = this.state;
      const plans = await SubscriptionDetails.getListPlans(interval);
      const { categories, packs } = await SubscriptionDetails.getListProducts(interval);
      const {
        propPlans,
        bravonPlan,
        propSubs,
        mandatoryProductsOnCreate,
      } = SubscriptionDetails.serializeData(subscription, plans, categories);

      const updatedPacks = previousCart.packs.filter(p => p.interval !== previousInterval);
      const subs = isEdit ? propSubs : [];
      const prods = isEdit ? [] : mandatoryProductsOnCreate;
      const cart = {
        limitUsers: isEdit ? subscription.limitUsers : 1,
        plans: isEdit ? propPlans : bravonPlan,
        subscriptions: subs,
        packs: updatedPacks,
        products: prods,
        discounts: activeDiscount
          ? [{ ...activeDiscount, applied: true }]
          : [],
      };
      this.setState({ interval, plans, categories, packs, cart });
    } catch (err) {
      this.handleRequestError(err);
    }
  }

  static getListProducts(interval) {
    return new Promise((resolve, reject) => {
      Request.GET(
          '/shop/products',
          { interval },
        )
        .done(({ data }) => {
          resolve(data);
        })
        .fail(err => {
          reject(err);
        });
    });
  }

  static getListPlans(interval) {
    return new Promise((resolve, reject) => {
      Request.GET(
          '/shop/plans',
          { interval },
        )
        .done(({ data }) => {
          resolve(data);
        })
        .fail(err => {
          reject(err);
        });
    });
  }

  handleRequestError(err) {
    const { t } = this.props;
    Notifications.showNotificationError(t('error'), err);
  }

  handleResetErrors() {
    this.setState({ showErrors: false });
  }

  handleNavigation(state, nextIndex, filled, showErrors = false) {
    this.setState(({ selectedIndex, filledSteps }) => ({
      ...state,
      selectedIndex: nextIndex,
      filledSteps: { ...filledSteps, [selectedIndex]: filled },
      showErrors,
    }));
  }

  attemptToNavigate(id) {
    if (this.currentStep && this.currentStep.validateAndLeave) {
      this.currentStep.validateAndLeave(id);
    } else {
      this.handleNavigation({}, id, true);
    }
  }

  checkDiscountApplication() {
    const { subscription } = this.props;
    const { categories, plans } = this.state;
    const plansAreFree =
      subscription.type === SUBSCRIPTION_TYPE.TRIAL ||
      subscription.type === SUBSCRIPTION_TYPE.FREE;
    this.setState(({ cart, manualInvoice }) => ({
      cart: {
        ...cart,
        discounts: markDiscountApplication(
          cart,
          categories,
          plans,
          plansAreFree,
        ),
      },
      manualInvoice:
        calcCurrentTotal(cart, categories) < 200000 ? false : manualInvoice,
    }));
  }

  handleManualInvoiceSelection(manualInvoice) {
    this.setState({ manualInvoice });
  }

  handleCountryChange(country) {
    this.setState(({ shipping }) => ({
      shipping: { ...shipping, address: { ...shipping.address, country } },
    }));
  }

  handleUsersChange(limitUsers) {
    this.setState(
      ({ cart }) => ({ cart: { ...cart, limitUsers } }),
      this.checkDiscountApplication,
    );
  }

  handleSectionAdd(section, productCard) {
    this.setState(
      ({ cart }) => ({
        cart: { ...cart, [section]: cart[section].concat(productCard) },
      }),
      this.checkDiscountApplication,
    );
  }

  handleSectionRemove(section, id) {
    this.setState(
      ({ cart }) => ({
        cart: {
          ...cart,
          [section]: cart[section].filter(({ product }) => product._id !== id),
        },
      }),
      this.checkDiscountApplication,
    );
  }

  handlePackAdd(product) {
    this.handleSectionAdd('packs', { product, quantity: 1 });
  }

  handlePackRemove(id) {
    this.handleSectionRemove('packs', id);
  }

  handleSubscriptionAdd(product) {
    this.handleSectionAdd('subscriptions', product);
  }

  handleSubscriptionRemove(id) {
    this.handleSectionRemove('subscriptions', id);
  }

  handleProductAdd(product) {
    this.handleSectionAdd('products', product);
  }

  handleProductRemove(id) {
    this.handleSectionRemove('products', id);
  }

  handleDiscountAdd(discount) {
    const { t } = this.props;

    this.setState(({ cart }) => {
      if (cart.discounts.some(d => d._id === discount._id)) {
        Notifications.showNotificationError(
          t('error'),
          t('error_duplicateDiscountCode'),
        );
        return { cart };
      }
      if (cart.discounts.some(d => d.type === discount.type)) {
        Notifications.showNotificationError(
          t('error'),
          t(`error_discount_multiple_${discount.type}`),
        );
        return { cart };
      }
      return { cart: { ...cart, discounts: cart.discounts.concat(discount) } };
    }, this.checkDiscountApplication);
  }

  handleDiscountRemove(id) {
    this.setState(
      ({ cart }) => ({
        cart: {
          ...cart,
          discounts: cart.discounts.filter(discount => discount._id !== id),
        },
      }),
      this.checkDiscountApplication,
    );
  }

  handleSubmit(paymentMethods) {
    const {
      t,
      _csrf,
      subscription,
      isEdit,
      forceStripeCustomer,
      loggedUser,
      taxIdTypes,
    } = this.props;
    const {
      filledSteps,
      cart: { plans, packs, products, subscriptions, discounts, limitUsers },
      shipping,
      invoiceEmail,
      tax_info,
      manualInvoice,
      interval,
      categories,
      plansAreFree,
    } = this.state;

    const hasEditPrivileges = !forceStripeCustomer || isHigherAdmin(loggedUser);
    const noPayment = plansAreFree && packs.length === 0 && products.length === 0;

    const firstNonFilled = () =>
      Object.keys(filledSteps).reduce((acc, step) => {
        const index = Number(step);
        if (filledSteps[index]) {
          return acc;
        }
        return index > acc ? acc : index;
      }, Number.MAX_SAFE_INTEGER);

    const usersChanged = subscription.limitUsers !== limitUsers;

    const allProducts = categories.reduce(
      (acc, c) => acc.concat(c.products),
      [],
    );
    const oldSubscriptions = allProducts.filter(
      p => p.type === PRODUCT_TYPE.SUBSCRIPTION && p.isBought,
    );
    const subsChanged = oldSubscriptions.reduce((hasChanged, oldSub) => {
      if (hasChanged) {
        return hasChanged;
      }

      return !!subscriptions.find(s => s._id === oldSub._id);
    }, false);

    const skipValidation =
      isEdit &&
      !usersChanged &&
      !subsChanged &&
      packs.length === 0 &&
      products.length === 0;

    if (!skipValidation && firstNonFilled() !== Number.MAX_SAFE_INTEGER) {
      this.handleNavigation({ paymentMethods }, firstNonFilled(), true, true);
    } else {
      const { address } = shipping;
      const { city, country, line1, line2, postal_code, state } = address;

      const transformProducts = ({ product: p, quantity }) => ({
        item: p._id,
        quantity,
      });

      // Serialize Tax Code into Tax Type (value that works on stripe)
      if (tax_info && tax_info.type) {
        const taxIdType = taxIdTypes.find(txId => txId.taxCode === tax_info.type);
        if (taxIdType) {
          tax_info.type = taxIdType.taxType;
        }
      }

      const params = {
        plans,
        limitUsers,
        shipping: filledSteps[3]
          ? {
              ...shipping,
              address: { city, country, line1, line2, postal_code, state },
            }
          : undefined,
        invoiceEmail: filledSteps[3] ? invoiceEmail : undefined,
        tax_info: filledSteps[3] ? tax_info : undefined,
        _csrf,
        products: products.map(transformProducts),
        packs: packs.map(transformProducts),
        subscriptions: subscriptions.map(({ product: p }) => p._id),
        discounts: discounts.map(d => ({ _id: d._id, type: d.type })),
        paymentMethodId: hasEditPrivileges && !noPayment
          ? (paymentMethods.find(c => c.isDefault) || {}).id
          : undefined,
        manualInvoice,
        interval,
      };
      console.log('PARAMS', params);

      const request = isEdit
        ? new Request(_csrf).put('/subscription', params)
        : new Request(_csrf).post('/subscription', params);

      const errorKey = isEdit
        ? 'error_subscriptionUpdate'
        : 'error_subscriptionCreate';

      this.setState({ postingForm: true });
      request
        .done(() => {
          if (!isEdit) {
            SubscriptionInfo.clearCache();
          }
          window.location.href = '/';
        })
        .fail(err => {
          let error = t(errorKey);
          if (err && err.responseJSON) {
            error = err.responseJSON.message || t(err.responseJSON.error);
          }
          Notifications.showNotificationError(t('error'), error);
          this.setState({ postingForm: false });
        });
    }
  }

  render() {
    const {
      t,
      subscription,
      countries,
      isEdit,
      isExpired,
      isActive,
      awaitingValidation,
      taxInfo,
      forceStripeCustomer,
      SEPACountries,
      isDefault,
      taxIdTypes,
    } = this.props;
    const {
      showErrors,
      selectedIndex,
      postingForm,
      cart,
      shipping,
      invoiceEmail,
      tax_info,
      filledSteps,
      paymentMethods,
      hasPendingChanges,
      manualInvoice,
      interval,
      categories,
      packs,
      plans,
    } = this.state;

    const plansAreFree = subscription.type === SUBSCRIPTION_TYPE.TRIAL || subscription.type === SUBSCRIPTION_TYPE.FREE;
    const noPayment = plansAreFree && cart.packs.length === 0 && cart.products.length === 0;
    const finishButtonText = noPayment ? t('save') : t('subscriptionCreate_payment_payNow');

    const StepComponent = (() => {
      switch (selectedIndex) {
        case 1:
          return Solutions;
        case 2:
          return Options;
        case 3:
          return Shipping;
        case 4:
          return Payment;
        default:
          return Solutions;
      }
    })();

    let editionDisabled = false;
    let disabledMessage = '';
    if (isEdit) {
      editionDisabled =
        hasPendingChanges || isExpired || !isActive || awaitingValidation;
      let message = t('subscriptionCreate_pendingChanges');
      if (isExpired) {
        message = t('subscriptionCreate_isExpired');
      }
      if (awaitingValidation) {
        message = t('subscriptionCreate_awaitingValidation');
      }
      disabledMessage = !isActive
        ? t('subscriptionCreate_isInactive')
        : message;
    }

    return (
      <React.Fragment>
        {editionDisabled && (
          <div className="edition-disabled-overlay">
            <span className="edition-disabled-message">
              <span>
                {disabledMessage}
                {isExpired &&
                  !awaitingValidation && (
                    <a href="subscription/create">
                      {t('header_banner_upgrade')}
                    </a>
                  )}
                {!isActive && (
                  <a href="profile/payment">
                    {t('subscriptionCreate_reactivated')}
                  </a>
                )}
              </span>
            </span>
          </div>
        )}
        <DocumentTitle
          title={
            isEdit
              ? t('subscription_detail_head_title')
              : t('subscriptionCreate_head_title')
          }
        >
          <ContentContainer>
            <div className="m-content">
              <div className="create-wizard">
                <NavigationMenu
                  selectedIndex={selectedIndex}
                  plansAreFree={plansAreFree}
                  cart={cart}
                  plans={plans}
                  categories={categories}
                  packs={packs}
                  taxInfo={taxInfo}
                  country={shipping.address.country}
                  onSelectionChange={this.attemptToNavigate}
                  filledSteps={filledSteps}
                  onPackRemove={this.handlePackRemove}
                  onProductRemove={this.handleProductRemove}
                  onSubscriptionRemove={this.handleSubscriptionRemove}
                  onDiscountRemove={this.handleDiscountRemove}
                  interval={interval}
                />
                <div
                  className="create-wizard-content m-portlet"
                  id="stepContainer"
                >
                  <StepComponent
                    wrappedComponentRef={c => {
                      this.currentStep = c;
                    }}
                    shipping={shipping}
                    invoiceEmail={invoiceEmail}
                    tax_info={tax_info}
                    paymentMethods={paymentMethods}
                    cart={cart}
                    isManualInvoiceSelected={manualInvoice}
                    initialPlans={subscription.plans || []}
                    plans={plans}
                    categories={categories}
                    packs={packs}
                    countries={countries}
                    taxInfo={taxInfo}
                    forceStripeCustomer={forceStripeCustomer}
                    onUsersChange={this.handleUsersChange}
                    onPackAdd={this.handlePackAdd}
                    onPackRemove={this.handlePackRemove}
                    onProductAdd={this.handleProductAdd}
                    onProductRemove={this.handleProductRemove}
                    onSubscriptionAdd={this.handleSubscriptionAdd}
                    onSubscriptionRemove={this.handleSubscriptionRemove}
                    onDiscountAdd={this.handleDiscountAdd}
                    onDiscountRemove={this.handleDiscountRemove}
                    onManualInvoiceSelection={this.handleManualInvoiceSelection}
                    onCountryChange={this.handleCountryChange}
                    onNavigate={this.handleNavigation}
                    onFinish={this.handleSubmit}
                    finishButtonText={finishButtonText}
                    postingForm={postingForm}
                    isEdit={isEdit}
                    showValidationErrors={showErrors}
                    resetShowErrors={this.handleResetErrors}
                    plansAreFree={plansAreFree}
                    fieldsRequired={!noPayment}
                    awaitingValidation={awaitingValidation}
                    SEPACountries={SEPACountries}
                    interval={interval}
                    onIntervalChange={this.onIntervalChange}
                    isDefault={isDefault}
                    taxIdTypes={taxIdTypes}
                  />
                </div>
              </div>
            </div>
          </ContentContainer>
        </DocumentTitle>
      </React.Fragment>
    );
  }
}

SubscriptionDetails.propTypes = {
  /** Translation Function */
  t: PropTypes.func.isRequired,
  _csrf: PropTypes.string.isRequired,
  subscription: PropTypes.shape(SubscriptionProp.propType),
  billing: PropTypes.shape(BillingProp.propType),
  activeDiscount: PropTypes.shape(DiscountProp.propType),
  paymentMethods: PropTypes.arrayOf(PropTypes.shape(StripePaymentMethodsProp.propType)),
  countries: PropTypes.arrayOf(PropTypes.shape(CountryProp.propType))
    .isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape(CategoryProp.propType))
    .isRequired,
  packs: PropTypes.arrayOf(PropTypes.shape(PackProp.propType)).isRequired,
  plans: PropTypes.arrayOf(PropTypes.shape(PlanProp.propType)).isRequired,
  taxInfo: PropTypes.shape(TaxInfoProp.propType).isRequired,
  forceStripeCustomer: PropTypes.bool.isRequired,
  loggedUser: PropTypes.shape(UserProp.propType),
  isExpired: PropTypes.bool,
  isActive: PropTypes.bool,
  awaitingValidation: PropTypes.bool,
  isEdit: PropTypes.bool,
  toShop: PropTypes.bool,
  interval: PropTypes.string.isRequired,
  SEPACountries: PropTypes.arrayOf(PropTypes.shape(CountryProp.propType)).isRequired,
  taxIdTypes: PropTypes.arrayOf(PropTypes.shape(TaxIdTypeProp.propType)).isRequired,
  isDefault: PropTypes.bool.isRequired,
};

SubscriptionDetails.defaultProps = {
  isActive: true,
  isExpired: false,
  awaitingValidation: false,
  isEdit: false,
  toShop: false,
  paymentMethods: [],
  subscription: SubscriptionProp.defaultProp,
  billing: BillingProp.defaultProp,
  activeDiscount: null,
  loggedUser: null,
};

export default compose(
  translate,
  withCSRF,
  withLoggedUser,
)(SubscriptionDetails);
