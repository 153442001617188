import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import translate from '../../../../../commonComponents/translate';
import * as Utils from '../../../../../services/utilityFunctions';
import CountryProp from '../../../../../commonComponents/forms/Address/CountryProp';

class CreditCardItem extends React.Component {
  constructor(props) {
    super(props);

    this.handleSelect = this.handleSelect.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  handleSelect() {
    this.props.onSelect(this.props.id);
  }

  handleRemove() {
    console.log('handleRemove().props.id)', this.props.id);
    this.props.onRemove(this.props.id);
  }

  render() {
    const { t, last4, billingName, logo, country: countryCode, displayRemove, isSelected, readOnly, SEPACountries } = this.props;
    const { flagEmoji } = SEPACountries.find(c => c.code === countryCode);

    const isExpired = false;

    const radioClasses = cx('m-radio', {
      'm-radio--disabled': isExpired,
      'm-radio--state-brand': !isExpired,
    });

    const cardNumber = Utils.displayCardNumber(last4);

    const shouldDisplayRemove = displayRemove && !readOnly;

    return (
      <div className={`m-payment_method_item ${readOnly ? 'read-only' : ''}`}>
        {shouldDisplayRemove && (
          <button
            className="m-payment_item__close"
            type="button"
            onClick={this.handleRemove}
          >
            <i className="la la-close" />
          </button>
        )}
        <label
          htmlFor="cardSelector"
          onClick={isExpired ? () => null : this.handleSelect}
        >
          <span className="m-payment_method__control">
            <span className={radioClasses}>
              <input
                id="cardSelected"
                type="radio"
                name="cardSelected"
                checked={isSelected}
                onChange={this.handleSelect}
                readOnly={readOnly}
                disabled={isExpired}
              />
              <span />
            </span>
          </span>
          <div className="m-payment_method__content">
            <div className="m-payment_method__header">
              <div className="m-payment_method__label">{`${t('payment_method_sepa_debit')} ${cardNumber}`}</div>
              <div className="m-payment_method__description">
                <div className="m-payment_method__message">
                  <span>{`${flagEmoji || ''} ${billingName}`}</span>
                </div>
              </div>
              {isExpired && (
                <div className="m-payment_method__alert">
                  <div className="m-payment_method__alert_icon">
                    <i className="la la-warning" />
                  </div>
                  <div className="m-payment_method__alert_message">
                    <span>{t('expired')}</span>
                  </div>
                </div>
              )}
            </div>
            <div className="m-payment_method__image_container">
              <img className="m-payment_method__image" src={logo} alt={t('payment_method_sepa_debit')} />
            </div>
          </div>
        </label>
      </div>
    );
  }
}

CreditCardItem.propTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  last4: PropTypes.string.isRequired,
  billingName: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  displayRemove: PropTypes.bool,
  readOnly: PropTypes.bool,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
  SEPACountries: PropTypes.arrayOf(PropTypes.shape(CountryProp.propType)).isRequired,
};

CreditCardItem.defaultProps = {
  isSelected: false,
  displayRemove: true,
  readOnly: true,
  onSelect: () => null,
  onRemove: () => null,
};

export default translate(CreditCardItem);
