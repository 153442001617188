import React from 'react';
import PropTypes from 'prop-types';

/**
 * TabContent for an individual tab inside a TabbedPortlet
 */
export default function TabContent({ id, content }) {
  return (
    <div id={id} className="tab-pane active show">
      {content}
    </div>
  );
}

TabContent.propTypes = {
  /** Tab id, used for selection */
  id: PropTypes.string.isRequired,
  /** React children to render when the tab is selected */
  content: PropTypes.element.isRequired,
};
