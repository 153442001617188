import React from 'react';
import PropTypes from 'prop-types';

/**
 * Checkbox component wrapping the label -> input -> span pattern
 */
export default function Checkbox({ id, name, label, value, onChange, className }) {
  return (
    <label htmlFor={id} className={`m-checkbox m-checkbox--focus ${className}`}>
      <input
        id={id}
        type="checkbox"
        name={name}
        defaultChecked={value}
        onChange={onChange}
        readOnly={onChange ? null : true}
      />
      {label}
      <span />
    </label>
  );
}

Checkbox.propTypes = {
  /** Input html id */
  id: PropTypes.string.isRequired,
  /** Input html name */
  name: PropTypes.string,
  /** Label text shown */
  label: PropTypes.string,
  /** Checkbox input value, true or false */
  value: PropTypes.bool.isRequired,
  /** Change handler, should negate the previous value. If not given the component will be read only */
  onChange: PropTypes.func,
  /** String of class names to be passed on to the wrapping <label /> */
  className: PropTypes.string,
};

Checkbox.defaultProps = {
  name: '',
  label: '',
  onChange: null,
  className: '',
};
