import React from 'react';
import PropTypes from 'prop-types';
import UserProp from '../../../sections/profile/propTypes/userProp';
import Brand from './Brand';
import HeaderProfile from './headerProfile/HeaderProfile';
import Banner from './Banner';

/**
 * Metronic header layout
 */
export default function Header({ theme, profile }) {
  return (
    <header id="m_header" className="m-grid__item m-header">
      <div className="m-header__top header-top">
        <div className="m-container m-container--responsive m-container--xxl m-container--full-height m-page__container">
          <div className="m-stack m-stack--ver m-stack--desktop">
            <Brand theme={theme} />
            <Banner />
          </div>
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  /** metronic theme in use */
  theme: PropTypes.string.isRequired,
  /** member currently logged in */
  profile: PropTypes.shape(UserProp.propType),
};

Header.defaultProps = { profile: null };
