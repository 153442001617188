import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ProductProp from '../../../../propTypes/ProductProp';
import ProductImage from '../../pack/ProductImage';
import Switch from '../../../../../../commonComponents/forms/inputs/Switch';
import { PRODUCT_TYPE } from '../../../../../../services/utilityFunctions';
import PackProp from '../../../../propTypes/PackProp';

export default function ProductItemModalHeader({ t, onClose, product, onClick, handleChangeQuantity, quantity, interval }) {
  const {
    name,
    price,
    type,
    isInCart,
    showBought,
    belongsToPack,
    allowQuantity,
  } = product;

  const isSubscription = type === PRODUCT_TYPE.SUBSCRIPTION;

  let formattedPrice = '';
  try {
    const numberValue = Number(price) / 100;
    const value = numberValue.toFixed(2);
    formattedPrice = value ? `${value}€` : value;
  } catch (err) {
    formattedPrice = '';
  }

  const addText = !isInCart && !showBought ? 'add' : 'bought';

  let inputCaption = '';
  if (isSubscription) {
    inputCaption = 'product-modal-input-caption-subscription';
  } else if (isInCart && !showBought) {
    inputCaption = 'product-modal-input-caption-removable';
  } else if (showBought) {
    inputCaption = 'product-modal-input-caption-bought';
  } else if (!isInCart && !showBought && allowQuantity) {
    inputCaption = 'product-modal-input-caption-allowQuantity';
  } else if (!isInCart && !showBought) {
    inputCaption = 'product-modal-input-caption-add';
  }

  return (
    <div className="modal-header product-item-modal-header">
      <div className="modal-header-image">
        <ProductImage
          className="product-item-img"
          product={product}
          style={{ width: '100%', height: '100%', top: '0', left: '0' }}
          light
        />
      </div>
      <div>
        <div className="product-title">{name}</div>
        {!belongsToPack && (
          <div className="modal-input">
            <div className="modal-input-caption">{t(inputCaption)}</div>
            <div className="modal-input-button">
              {isSubscription ? (
                <div>
                  <Switch
                    onClick={onClick}
                    checked={isInCart}
                    id={product._id}
                  />
                </div>
              ) : (
                <div>
                  {!isInCart && !showBought && allowQuantity ? (
                    <div className="modal-input-quantity-container">
                      <div className="input-group modal-input-quantity-selector">
                        <div className="input-group-prepend modal-input-quantity-btn">
                          <button
                            className="btn btn-outline-primary"
                            style={{ padding: '5px' }}
                            type="button"
                            disabled={quantity <= 1}
                            onClick={() =>
                              handleChangeQuantity({ type: 'decrement' })
                            }
                          >
                            -
                          </button>
                        </div>
                        <input
                          className="form-control modal-input-quantity modal-input-quantity-btn"
                          type="number"
                          onChange={evt =>
                            handleChangeQuantity({
                              type: 'set',
                              value: Number(evt.currentTarget.value),
                            })
                          }
                          value={quantity}
                        />
                        <div className="input-group-append modal-input-quantity-btn">
                          <button
                            className="btn btn-outline-primary"
                            style={{ padding: '5px' }}
                            type="button"
                            onClick={() =>
                              handleChangeQuantity({ type: 'increment' })
                            }
                          >
                            +
                          </button>
                        </div>
                      </div>
                      <div>
                        <button
                          disabled={showBought}
                          type="button"
                          className={cx(
                            'btn m-btn--pill m-btn--air btn-sm m-btn m-btn--custom modal-btn',
                            { 'modal-btn-available': !isInCart && !showBought },
                            { 'modal-btn-remove': isInCart && !showBought },
                            { 'modal-btn-remove-no-hover': showBought },
                          )}
                          onClick={onClick}
                        >
                          <b>{isInCart ? t('remove') : t(addText)}</b>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <button
                        disabled={showBought}
                        type="button"
                        className={cx(
                          'btn m-btn--pill m-btn--air btn-sm m-btn m-btn--custom modal-btn',
                          { 'modal-btn-available': !isInCart && !showBought },
                          { 'modal-btn-remove': isInCart && !showBought },
                          { 'modal-btn-remove-no-hover': showBought },
                        )}
                        onClick={onClick}
                      >
                        <b>{isInCart ? t('remove') : t(addText)}</b>
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="modal-price">
        <div className="price">{formattedPrice}</div>
        {isSubscription && (
          <div className="price-caption">
            {interval === 'year' ? t('subscriptionCreate_perUserPerYear') : t('subscriptionCreate_perUserPerMonth')}
          </div>
        )}
      </div>

      {onClose && (
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">×</span>
        </button>
      )}
    </div>
  );
}

ProductItemModalHeader.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  product: PropTypes.oneOfType([
    PropTypes.shape(ProductProp.propType),
    PropTypes.shape(PackProp.propType),
  ]),
  onClick: PropTypes.func,
  handleChangeQuantity: PropTypes.func.isRequired,
  quantity: PropTypes.number,
  interval: PropTypes.string.isRequired,
};

ProductItemModalHeader.defaultProps = {
  onClose: null,
  product: ProductProp.defaultProp,
  onClick: null,
  quantity: 1,
};
