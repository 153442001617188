import React from 'react';
import PropTypes from 'prop-types';
import { displayMoney } from '../../../../services/utilityFunctions';
import { getPriceForUsers } from '../../../../services/cartCalculations';
import translate from '../../../../commonComponents/translate';

function Plan(props) {
  const { t, title, description, prices, users, readOnly, onUsersChange, interval } = props;

  function changeHandler({ currentTarget }) {
    if (currentTarget.value) {
      onUsersChange(Number(currentTarget.value));
    } else {
      onUsersChange(1);
    }
  }

  const { price, isFlat } = getPriceForUsers(prices, users);
  let priceLabel = displayMoney(price);
  if (!isFlat) {
    priceLabel = `${
      interval === 'year' ? t('subscriptionCreate_perUserPerYear') : t('subscriptionCreate_perUserPerMonth')
    }\n${priceLabel}`;
  }

  return (
    <div className="subscription-plan subscription-plan-info">
      <h3>{title}</h3>
      <div className="subscription-plan-desc">{description}</div>
      <div className="subscription-price-container">
        <input
          className="subscription-users-input"
          value={users}
          onChange={changeHandler}
          disabled={readOnly}
        />
        <span className="subscription-plan-price">{priceLabel}</span>
      </div>
      <input
        type="range"
        value={users}
        min={1}
        max={100}
        onChange={changeHandler}
        disabled={readOnly}
      />
    </div>
  );
}

Plan.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  prices: PropTypes.arrayOf(
    PropTypes.shape({
      flat_amount: PropTypes.number,
      unit_amount: PropTypes.number,
      from: PropTypes.number,
      up_to: PropTypes.number,
    }),
  ).isRequired,
  users: PropTypes.number.isRequired,
  onUsersChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  interval: PropTypes.string.isRequired,
};

Plan.defaultProps = { readOnly: false };

export default translate(Plan);
