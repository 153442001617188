import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Request from '../../../services/request';
import Notifications from '../../../services/notifications';
import translate from '../../../commonComponents/translate';
import { compose } from '../../../services/utilityFunctions';
import withCSRF from '../../../commonComponents/withCSRF';
import ImageCropModal from '../../../commonComponents/imageCrop/ImageCropModal';

/**
 * Member image input for profile page
 */
class Avatar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showCropTool: false, originalFile: null, fileName: '' };

    this.handleChange = this.handleChange.bind(this);
    this.resetState = this.resetState.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  resetState() {
    this.setState({ showCropTool: false, originalFile: null, fileName: '' });
  }

  async handleModalClose(croppedImage) {
    if (croppedImage) {
      await this.uploadFile(croppedImage);
    } else {
      this.resetState();
    }
  }

  async uploadFile(croppedImage) {
    const { t, _csrf, onChange } = this.props;
    const { fileName } = this.state;

    const formData = new FormData();
    formData.append('file', croppedImage, fileName);
    formData.append('upload_file', true);

    new Request(_csrf)
      .formMulti('POST', '/profile/avatar', formData)
      .done(result => {
        this.resetState();
        onChange({ ...result.data });
      })
      .fail(err => {
        let error = t('error_avatarUpload');
        if (err && err.responseJSON) {
          error = err.responseJSON.message || t(err.responseJSON.error);
        }
        Notifications.showNotificationError(t('error'), error);
      });
  }

  handleChange(evt) {
    const file = evt.currentTarget.files[0];

    const reader = new FileReader();
    reader.onload = readerEvt =>
      this.setState({
        originalFile: readerEvt.target.result,
        showCropTool: true,
        fileName: file.name,
      });

    reader.readAsDataURL(file);
  }

  render() {
    const { t, url, onChange } = this.props;
    const { showCropTool, originalFile, fileName } = this.state;

    const canChange = !!onChange;

    const labelClassName = cx({ 'profile-avatar-input': canChange });
    const imgClassName = cx({ 'profile-avatar': canChange });

    return (
      <React.Fragment>
        <label htmlFor="avatarUpload" className={labelClassName}>
          <img
            id="img_profile_avatar"
            name="profile.avatar"
            className={imgClassName}
            src={url}
            alt="user avatar"
          />
          {canChange && (
            <input
              type="file"
              id="avatarUpload"
              style={{ display: 'none' }}
              onChange={this.handleChange}
            />
          )}
        </label>
        {showCropTool && (
          <ImageCropModal
            title={t('profile_avatar')}
            originalFile={originalFile}
            fileName={fileName}
            onFinished={this.handleModalClose}
          />
        )}
      </React.Fragment>
    );
  }
}

Avatar.propTypes = {
  /** translation function */
  t: PropTypes.func.isRequired,
  /** _csrf token for requests */
  _csrf: PropTypes.string.isRequired,
  /** OnChange handler, optional, is none is passed the component behaves
   * as a regular image display
   */
  onChange: PropTypes.func,
  /** image url */
  url: PropTypes.string,
};

Avatar.defaultProps = {
  onChange: null,
  url: '',
};

export default compose(
  translate,
  withCSRF,
)(Avatar);
