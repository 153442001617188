const i18n = {};

const data = document.querySelector('script[data-lang][data-i18n]');
const lang = JSON.parse(data.getAttribute('data-i18n'));

i18n.__ = (...args) => {
  const tag = args[0];
  let s = lang[tag];
  if (s) {
    for (let i = 0; i < args.length - 1; i += 1) {
      const param = `%${i + 1}\\$s`;
      s = s.replace(new RegExp(param, 'gm'), args[i + 1]);
    }
    return s;
  }
  return tag;
};

i18n.__n = (...args) => {
  const tag = args[0];
  let s = lang[tag];
  if (s) {
    for (let i = 0; i < args.length - 1; i += 1) {
      const param = /%[sdj]/g;
      s = s.replace(new RegExp(param, 'gm'), args[i + 1]);
    }
    return s;
  }
  return tag;
};

const t = (...args) => args[0];

i18n.__l = t;
i18n.__h = t;
i18n.__mf = t;

export default i18n;
