import React from 'react';
import PropTypes from 'prop-types';
import Switch from '../../../../../commonComponents/forms/inputs/Switch';
import ProductProp from '../../../propTypes/ProductProp';

export default function ModuleItem({ t, product, isInCart, onAdd, onRemove, isDefault }) {
  const handleAdd = () => onAdd({ product, quantity: 1 });
  const handleSwitch = evt => {
    evt.stopPropagation();
    const cb = isInCart ? onRemove : handleAdd;
    cb(product);
  };
  const isItemMarketplace = product.itemType === 'marketPlace';
  const disabledModuleItem = isItemMarketplace && isDefault;
  return (
    <div
      key={product._id}
      className={`subscription-module-container ${disabledModuleItem ? 'disabledModuleItem' : ''}`}
    >
      <div className="subscription-module-image">
        <img alt="challenge" src={product.image} />
      </div>
      <div className="subscription-module-body">
        <div className="subscription-module-text">
          <h4>{product.name}</h4>
          <div className="subscription-module-description">
            {product.description}
          </div>
        </div>
        <Switch
          style={{ width: '48px', margin: 0 }}
          onClick={handleSwitch}
          checked={isInCart}
          id={product._id}
          disabledModuleItem={disabledModuleItem}
        />
      </div>
      {isItemMarketplace && isDefault && (
        <div className="subscription-module-disabledMessage">
          <span>{t('solutions.module.item.disableMsg')}</span>
        </div>
      )}
    </div>
  );
}

ModuleItem.propTypes = {
  t: PropTypes.func.isRequired,
  product: PropTypes.shape(ProductProp.propType).isRequired,
  isInCart: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isDefault: PropTypes.bool.isRequired,
};
