import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default function NavigationItem(props) {
  const {
    index,
    title,
    description,
    selected,
    onSelectionChange,
    filled,
  } = props;

  return (
    <button
      type="button"
      className={cx('navigation-item', { selected })}
      onClick={onSelectionChange}
    >
      <div className="navigation-item-index">
        {filled ? <img alt="check" src="/images/check.svg" /> : index}
      </div>
      <div className="navigation-item-text">
        <div className="navigation-item-title">{title}</div>
        <div className="navigation-item-description">{description}</div>
      </div>
    </button>
  );
}

NavigationItem.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  filled: PropTypes.bool,
};

NavigationItem.defaultProps = {
  filled: false,
};
