import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../../translate';
import SubscriptionInfo from '../../../../services/subscriptionInfo';

/**
 * Member popup navigation links
 */
function NavigationLinks({ t }) {
  const handleLogout = () => {
    SubscriptionInfo.clearCache();
  };

  const links = [
    {
      url: '/profile',
      label: t('header_myProfile'),
      icon: 'flaticon-profile-1',
    },
    {
      url: '/profile/subscriptions',
      label: t('header_subscriptions'),
      icon: 'flaticon-signs-1',
    },
    {
      url: '/profile/invoices',
      label: t('header_invoices'),
      icon: 'flaticon-list-2',
    },
    {
      url: '/profile/billing',
      label: t('header_billing'),
      icon: 'flaticon-price-tag',
    },
    {
      url: '/profile/payment',
      label: t('header_payment'),
      icon: 'flaticon-coins',
    },
    {
      url: '/shop',
      label: t('header_navigation_shop'),
      icon: 'flaticon-shopping-basket',
    },
    {
      url: '/bravon',
      label: t('header_navigation_bravon'),
      icon: 'flaticon-analytics',
    },
  ];

  // eslint-disable-next-line react/prop-types
  const renderLink = ({ url, label, icon }) => (
    <li className="m-nav__item" key={url}>
      <a href={url} className="m-nav__link">
        <i className={`m-nav__link-icon ${icon}`} />
        <span className="m-nav__link-title">
          <span className="m-nav__link-wrap">
            <span className="m-nav__link-text">{label}</span>
          </span>
        </span>
      </a>
    </li>
  );

  return (
    <ul className="m-nav m-nav--skin-light">
      <li className="m-nav__section m--hide">
        <span className="m-nav__section-text">Section</span>
      </li>

      {links.map(renderLink)}

      <li className="m-nav__separator m-nav__separator--fit" />

      <li className="m-nav__item">
        <a
          href="/signin/logout"
          onClick={handleLogout}
          className={
            'btn m-btn--pill btn-secondary ' +
            'm-btn m-btn--custom m-btn--label-brand m-btn--bolder'
          }
        >
          {t('header_logout')}
        </a>
      </li>
    </ul>
  );
}

NavigationLinks.propTypes = {
  /* Translation function */
  t: PropTypes.func.isRequired,
};

export default translate(NavigationLinks);
