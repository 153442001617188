import React from 'react';
import PropTypes from 'prop-types';
import PlanProp from '../propTypes/PlanProp';
import PackProp from '../propTypes/PackProp';
import CategoryProp from '../propTypes/CategoryProp';
import translate from '../../../commonComponents/translate';
import NavigationItem from './NavigationItem';
import Cart from './Cart';
import CartProp from '../propTypes/CartProp';
import TaxInfoProp from '../propTypes/TaxInfoProp';

function NavigationMenu(props) {
  const {
    t,
    selectedIndex,
    onSelectionChange,
    plansAreFree,
    cart,
    plans,
    categories,
    packs,
    filledSteps,
    onPackRemove,
    onProductRemove,
    onSubscriptionRemove,
    onDiscountRemove,
    style,
    taxInfo,
    country,
    interval,
  } = props;

  const navigation = [
    {
      id: 1,
      title: t('subscriptionCreate_nav_solutions_title'),
      description: t('subscriptionCreate_nav_solutions_description'),
    },
    {
      id: 2,
      title: t('subscriptionCreate_nav_options_title'),
      description: t('subscriptionCreate_nav_options_description'),
    },
    {
      id: 3,
      title: t('subscriptionCreate_nav_billing_title'),
      description: t('subscriptionCreate_nav_billing_description'),
    },
    {
      id: 4,
      title: t('subscriptionCreate_nav_payment_title'),
      description: t('subscriptionCreate_nav_payment_description'),
    },
  ];

  const renderItem = item => (
    <NavigationItem
      key={item.id}
      index={item.id}
      title={item.title}
      description={item.description}
      selected={selectedIndex === item.id}
      onSelectionChange={() => onSelectionChange(item.id)}
      filled={filledSteps[item.id]}
    />
  );

  return (
    <div className="create-wizard-navigation" style={{ ...style }}>
      {navigation.map(renderItem)}
      <div className="create-wizard-prices-container">
        <Cart
          plansAreFree={plansAreFree}
          cart={cart}
          categories={categories}
          packs={packs}
          plans={plans}
          taxInfo={taxInfo}
          country={country}
          onPackRemove={onPackRemove}
          onProductRemove={onProductRemove}
          onSubscriptionRemove={onSubscriptionRemove}
          onDiscountRemove={onDiscountRemove}
          interval={interval}
        />
      </div>
    </div>
  );
}

NavigationMenu.propTypes = {
  t: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  cart: PropTypes.shape(CartProp.propType).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape(CategoryProp.propType))
    .isRequired,
  packs: PropTypes.arrayOf(PropTypes.shape(PackProp.propType)).isRequired,
  plans: PropTypes.arrayOf(PropTypes.shape(PlanProp.propType)).isRequired,
  taxInfo: PropTypes.shape(TaxInfoProp.propType).isRequired,
  country: PropTypes.string.isRequired,
  filledSteps: PropTypes.objectOf(PropTypes.bool).isRequired,
  onPackRemove: PropTypes.func.isRequired,
  onProductRemove: PropTypes.func.isRequired,
  onSubscriptionRemove: PropTypes.func.isRequired,
  onDiscountRemove: PropTypes.func.isRequired,
  style: PropTypes.shape(),
  plansAreFree: PropTypes.bool.isRequired,
  interval: PropTypes.string.isRequired,
};

NavigationMenu.defaultProps = {
  style: {},
};

export default translate(NavigationMenu);
