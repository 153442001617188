import PropTypes from 'prop-types';

const propType = {
  tax_id: PropTypes.string,
  type: PropTypes.string,
};

const defaultProp = {
  tax_id: '',
  type: 'eu_vat',
};

export default { propType, defaultProp };
