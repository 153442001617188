import PropTypes from 'prop-types';
import ImageProp from './ImageProp';
import {
  PRODUCT_VIEW,
  PRODUCT_VIEWS,
  PRODUCT_ITEM_TYPES,
  PRODUCT_TYPE,
  PRODUCT_TYPES,
} from '../../../services/utilityFunctions';

const propType = {
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.oneOf(PRODUCT_TYPES).isRequired,
  itemType: PropTypes.oneOf(PRODUCT_ITEM_TYPES),
  displayOnView: PropTypes.oneOf(PRODUCT_VIEWS),
  allowQuantity: PropTypes.bool.isRequired,
  isFreeTrial: PropTypes.bool.isRequired,
  isBought: PropTypes.bool.isRequired,
  belongsToPack: PropTypes.bool,
  isInCart: PropTypes.bool,
  isSubscription: PropTypes.bool,
  showBought: PropTypes.bool,
  images: PropTypes.arrayOf(PropTypes.shape(ImageProp.propType)),
  video: PropTypes.shape({
    url: PropTypes.string,
    thumb: PropTypes.string,
  }),
  isDefaultForSubscription: PropTypes.bool,
  interval: PropTypes.string,
};

const defaultProp = {
  _id: '',
  price: 0,
  name: '',
  description: '',
  type: PRODUCT_TYPE.MULTI_BUY,
  itemType: null,
  displayOnView: PRODUCT_VIEW.OPTIONS,
  allowQuantity: false,
  isFreeTrial: false,
  isBought: false,
  image: '/images/trophy.svg',
  belongsToPack: false,
  isInCart: false,
  isSubscription: false,
  showBought: false,
  images: [],
  video: null,
  isDefaultForSubscription: false,
  interval: 'none',
};

export default { propType, defaultProp };
