import React from 'react';
import PropTypes from 'prop-types';
import ImageProp from '../../../../propTypes/ImageProp';

export default function ProductItemImages({ images }) {
  let highlightRow = [];
  let remainingRows = [];
  if (images.length > 2) {
    highlightRow = images.slice(0, 2);
    remainingRows = images.slice(2);
  } else if (images.length <= 2) {
    highlightRow = images;
  }

  return (
    <div className="product-item-modal-images">
      {highlightRow.map(i => (
        <img
          key={i.url}
          src={i.url}
          alt="this is a test"
          className="highlight-image"
        />
      ))}
      {remainingRows.map(i => (
        <img
          key={i.url}
          src={i.url}
          alt="this is a test"
          className="remaining-images"
        />
      ))}
    </div>
  );
}

ProductItemImages.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape(ImageProp.propType)),
};

ProductItemImages.defaultProps = {
  images: [],
};
