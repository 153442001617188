import React from 'react';
import PropTypes from 'prop-types';
import CartProp from '../../propTypes/CartProp';
import StripePaymentMethodsProp from '../../../payments/propTypes/StripePaymentMethodsProp';
import Request from '../../../../services/request';
import Notifications from '../../../../services/notifications';
import {
  compose,
  colors,
  isHigherAdmin,
} from '../../../../services/utilityFunctions';
import translate from '../../../../commonComponents/translate';
import withCSRF from '../../../../commonComponents/withCSRF';
import withLoggedUser from '../../../../commonComponents/withLoggedUser';
import CreditCardSelector from './creditCardSelector/CreditCardSelector';
import CreditCardModal from '../../../payments/modals/CreditCardModal';
import { calcCurrentTotal } from '../../../../services/cartCalculations';
import CategoryProp from '../../propTypes/CategoryProp';
import UserProp from '../../../profile/propTypes/userProp';
import SEPAModal from '../../../payments/modals/SEPAModal';
import ShippingProp from '../../../profile/propTypes/shippingProp';
import CountryProp from '../../../../commonComponents/forms/Address/CountryProp';

class PaymentForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentMethods: props.paymentMethods.slice(),
      coupon: '',
      showModalCard: false,
      showModalSEPA: false,
      postingForm: false,
    };

    this.handleClickAddNewCard = this.handleClickAddNewCard.bind(this);
    this.handleClickAddSEPA = this.handleClickAddSEPA.bind(this);
    this.handleAddedPaymentMethod = this.handleAddedPaymentMethod.bind(this);
    this.handleSelectCard = this.handleSelectCard.bind(this);
    this.handleRemoveCard = this.handleRemoveCard.bind(this);
    this.handleCouponChange = this.handleCouponChange.bind(this);
    this.handleApplyCoupon = this.handleApplyCoupon.bind(this);
    this.validateAndLeave = this.validateAndLeave.bind(this);
  }

  handleClickAddNewCard() {
    this.setState({ showModalCard: true });
  }

  handleClickAddSEPA() {
    this.setState({ showModalSEPA: true });
  }

  performRequest(method, path, params, errorKey, onSuccess, onError) {
    const { t, _csrf } = this.props;

    const handleError = err => {
      Notifications.showNotificationError(t('error'), err);
      this.setState({ postingForm: false }, () => {
        if (onError) {
          onError();
        }
      });
    };

    this.setState({ postingForm: true });
    const result = new Request(_csrf)[method](path, params);
    try {
      result.done(onSuccess).fail(err => {
        let error = t(errorKey);
        if (err && err.responseJSON) {
          error = err.responseJSON.message || t(err.responseJSON.error);
        }
        handleError(error);
      });
    } catch (err) {
      handleError(err);
    }
  }

  handleSelectCard(paymentMethodId) {
    const onSuccess = () => {
      this.setState(({ paymentMethods }) => ({
        paymentMethods: paymentMethods.map(
          c =>
            c.id === paymentMethodId
              ? { ...c, isDefault: true }
              : { ...c, isDefault: false },
        ),
        showModalCard: false,
        showModalSEPA: false,
        postingForm: false,
      }));
    };
    this.performRequest(
      'post',
      '/payments/methods/default',
      { paymentMethodId },
      'error_cardSelect',
      onSuccess,
    );
  }

  handleAddedPaymentMethod(paymentMethod) {
    this.setState(
      ({ paymentMethods }) => ({
        paymentMethods: paymentMethods
          .concat(paymentMethod)
          .map(
            c =>
              c.id === paymentMethod.id
                ? { ...c, isDefault: true }
                : { ...c, isDefault: false },
          ),
        showModalCard: false,
        showModalSEPA: false,
        postingForm: false,
      }),
      this.emitChange,
    );
  }

  handleRemoveCard(paymentMethodId) {
    const onSuccess = () => {
      this.setState(({ paymentMethods }) => ({
        paymentMethods: paymentMethods.filter(c => c.id !== paymentMethodId),
        postingForm: false,
      }));
    };

    this.performRequest(
      'delete',
      '/payments/methods',
      { paymentMethodId },
      'error_cardRemove',
      onSuccess,
    );
  }

  handleApplyCoupon() {
    const { t } = this.props;
    const onSuccess = result => {
      this.setState({ coupon: '', postingForm: false });
      if (!result.data) {
        Notifications.showNotificationError(
          t('error'),
          t('error_applyDiscountCode'),
        );
      } else {
        this.props.onDiscountAdd(result.data);
      }
    };

    const onError = () => {
      this.setState({ coupon: '' });
    };

    this.performRequest(
      'get',
      `/discount/code/${this.state.coupon}`,
      undefined,
      'error_applyDiscountCode',
      onSuccess,
      onError,
    );
  }

  handleCouponChange(evt) {
    this.setState({ coupon: evt.currentTarget.value });
  }

  validateAndLeave(step) {
    const filled = this.state.paymentMethods.length > 0;
    this.props.onNavigate({ paymentMethods: this.state.paymentMethods.slice() }, step, filled);
  }

  render() {
    const {
      t,
      loggedUser,
      cart,
      categories,
      isManualInvoiceSelected,
      onManualInvoiceSelection,
      postingForm: postProps,
      onFinish,
      finishButtonText,
      isEdit,
      forceStripeCustomer,
      shipping,
      invoiceEmail,
      SEPACountries,
    } = this.props;
    const { paymentMethods, coupon, postingForm: postState, showModalCard, showModalSEPA } = this.state;

    const postingForm = postProps || postState;

    const hasEditPrivilege = !forceStripeCustomer || isHigherAdmin(loggedUser);

    const requiresCard =
      hasEditPrivilege && (cart.products.length > 0 || cart.packs.length > 0);
    const invalidPaymentCheck = isEdit
      ? paymentMethods.length === 0 && !isManualInvoiceSelected
      : paymentMethods.length === 0;
    const disableButton = requiresCard ? invalidPaymentCheck : false;
    const unappliedDiscounts = cart.discounts.filter(d => !d.applied);

    const currentTotal = calcCurrentTotal(cart, categories);

    return (
      <React.Fragment>
        <div className="payment-cc-title">
          {t('subscriptionCreate_payment_payment')}
        </div>
        {hasEditPrivilege ? (
          <CreditCardSelector
            paymentMethods={paymentMethods}
            isModeSelection={hasEditPrivilege}
            displayManualInvoice={currentTotal > 200000}
            isManualInvoiceSelected={isManualInvoiceSelected}
            onManualInvoiceSelection={onManualInvoiceSelection}
            onSelectPaymentMethod={this.handleSelectCard}
            onClickAddCard={this.handleClickAddNewCard}
            onClickAddSEPA={this.handleClickAddSEPA}
            onRemovePaymentMethod={this.handleRemoveCard}
            SEPACountries={SEPACountries}
          />
        ) : (
          <h6 className="payment-cc-noSelection-title">
            {t('subscriptionCreate_payment_noCCSelection')}
          </h6>
        )}
        <div className="row">
          <div className="col-12 input-group">
            <input
              disabled={!hasEditPrivilege}
              id="coupon"
              name="coupon"
              value={coupon}
              onChange={this.handleCouponChange}
              placeholder={t('subscriptionCreate_payment_discountCode')}
              type="text"
              className="form-control"
            />
            <div className="input-group-append">
              <button
                className="btn btn-primary loader-button"
                type="button"
                disabled={!hasEditPrivilege}
                onClick={this.handleApplyCoupon}
              >
                {t('apply')}
              </button>
            </div>
          </div>
        </div>
        {unappliedDiscounts.length > 0 && (
          <div className="row discount-warning-container">
            <div className="col-12">
              <h4>{t('subscriptionCreate_payment_unusedDiscounts')}</h4>
              <ul>
                {unappliedDiscounts.map(discount => (
                  <li key={discount._id} className="discount-warning-item">
                    {`${discount.name}`}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {!isEdit &&
        paymentMethods.length === 0 && (
            <div className="row discount-warning-container">
              <div className="col-12">
                <h4 style={{ color: colors.danger }}>
                  {t('subscriptionCreate_payment_cardRequired')}
                </h4>
              </div>
            </div>
          )}
        <button
          type="submit"
          onClick={() => onFinish(paymentMethods)}
          disabled={postingForm || disableButton}
          className={
            'btn m-btn--pill m-btn--air btn-lg m-btn' +
            ' m-btn--custom btn-primary payment-finish-button loader-button'
          }
        >
          {finishButtonText}
        </button>
        {showModalCard && (
          <CreditCardModal
            onFinish={this.handleAddedPaymentMethod}
            onClose={() => this.setState({ showModalCard: false })}
            postingForm={postingForm}
          />
        )}
        {showModalSEPA && (
          <SEPAModal
            onFinish={this.handleAddedPaymentMethod}
            onClose={() => this.setState({ showModalSEPA: false })}
            postingForm={postingForm}
            shipping={shipping}
            invoiceEmail={invoiceEmail}
            SEPACountries={SEPACountries}
          />
        )}
      </React.Fragment>
    );
  }
}

PaymentForm.propTypes = {
  t: PropTypes.func.isRequired,
  _csrf: PropTypes.string.isRequired,
  cart: PropTypes.shape(CartProp.propType).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape(CategoryProp.propType))
    .isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.shape(StripePaymentMethodsProp.propType)).isRequired,
  isManualInvoiceSelected: PropTypes.bool.isRequired,
  onManualInvoiceSelection: PropTypes.func.isRequired,
  finishButtonText: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
  postingForm: PropTypes.bool.isRequired,
  onDiscountAdd: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  forceStripeCustomer: PropTypes.bool.isRequired,
  loggedUser: PropTypes.shape(UserProp.propType),
  shipping: PropTypes.shape(ShippingProp.propType),
  invoiceEmail: PropTypes.string,
  SEPACountries: PropTypes.arrayOf(PropTypes.shape(CountryProp.propType))
    .isRequired,
};

PaymentForm.defaultProps = {
  loggedUser: null,
  invoiceEmail: '',
  shipping: ShippingProp.defaultProp,
};

export default compose(
  translate,
  withCSRF,
  withLoggedUser,
)(PaymentForm);
