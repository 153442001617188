import React from 'react';
import PropTypes from 'prop-types';

export default class FormLabel extends React.Component {
  componentDidMount() {
    $('[data-toggle="m-popover"]').each(function initPopover() {
      const el = $(this);
      const skin = el.data('skin') ? `m-popover--skin-${el.data('skin')}` : '';

      el.popover({
        trigger: 'hover',
        template: `\
            <div class="m-popover ${skin} popover" role="tooltip">\
                <div class="arrow"></div>\
                <h3 class="popover-header"></h3>\
                <div class="popover-body"></div>\
            </div>`,
      });
    });
  }

  render() {
    const { id, label, className, tooltip } = this.props;

    return (
      <label
        data-toggle={tooltip ? 'm-popover' : undefined}
        data-content={tooltip ? tooltip.content : ''}
        data-original-title={tooltip ? tooltip.title : ''}
        htmlFor={id}
        className={`col-form-label ${className}`}
      >
        {label}
      </label>
    );
  }
}

FormLabel.propTypes = {
  /** Html id */
  id: PropTypes.string.isRequired,
  /** Label text */
  label: PropTypes.string.isRequired,
  /** Custom classes to add */
  className: PropTypes.string,
  tooltip: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }),
};

FormLabel.defaultProps = {
  className: '',
  tooltip: null,
};
