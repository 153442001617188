import React from 'react';
import PropTypes from 'prop-types';
import FormValidator from '../../../services/formValidation';
import translate from '../../../commonComponents/translate';
import FormInput from '../../../commonComponents/forms/FormInput';
import { SUBSCRIPTION_TYPE } from '../../../services/utilityFunctions';

class SuperAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props.superAdmin };

    this.validationRules = {
      type: { required: true },
      tenant: { required: true },
    };

    this.formId = 'superAdminForm';

    this.handleChange = this.handleChange.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
  }

  componentDidMount() {
    FormValidator.config();
  }

  handleChange(field) {
    return value => this.setState({ [field]: value });
  }

  submitHandler() {
    return evt => {
      evt.preventDefault();

      const form = $(`#${this.formId}`);
      form.validate({ rules: this.validationRules });

      if (form.valid()) {
        this.props.onForward({ superAdmin: { ...this.state } });
      } else {
        document.querySelector('.has-danger').scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
      }
    };
  }

  render() {
    const { t, tenants } = this.props;

    return (
      <div className="trial-info-container">
        <div className="trial-info-form">
          <div className="trial-info-form-wrapper">
            <div className="trial-info-form-content">
              <div>
                <h1>{t('trial_superAdmin_title')}</h1>
              </div>
              <div className="trial-info-form-inputs">
                <form
                  className="m-form m-form--fit m-form--label-align-right"
                  id={this.formId}
                >
                  <FormInput
                    id="type"
                    name="type"
                    value={this.state.type}
                    onChange={this.handleChange('type')}
                    label={t('trial_superAdmin_type')}
                    labelClassName="col-3"
                    containerClassName="col-9"
                    type="select"
                    groupProps={{ className: 'row' }}
                    inputProps={{
                      items: [
                        SUBSCRIPTION_TYPE.FREE,
                        SUBSCRIPTION_TYPE.TRIAL,
                      ].map(s => ({
                        id: s,
                        text: t(`subscription_type_${s}`),
                      })),
                    }}
                  />
                  <FormInput
                    id="tenant"
                    name="tenant"
                    value={this.state.tenant}
                    onChange={this.handleChange('tenant')}
                    label={t('trial_superAdmin_tenant')}
                    labelClassName="col-3"
                    containerClassName="col-9"
                    type="select"
                    groupProps={{ className: 'row' }}
                    inputProps={{
                      items: tenants.map(s => ({
                        id: s._id,
                        text: s.name,
                      })),
                      placeholder: t('trial_superAdmin_tenant_placeholder'),
                    }}
                  />
                  <div
                    className="trial-info-next-container"
                    style={{
                      justifyContent: 'flex-end',
                      paddingRight: '30px',
                    }}
                  >
                    <button
                      className="trial-info-next-button"
                      type="submit"
                      onClick={this.submitHandler(true)}
                    >
                      {t('trial_info_next')}
                      <i className="fa fa-arrow-right" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="trial-demo-container">
          <img
            alt="platform demo"
            className="demo-image"
            src="/images/trial/trial_step_3.png"
          />
        </div>
      </div>
    );
  }
}

SuperAdmin.propTypes = {
  /** Translation function */
  t: PropTypes.func.isRequired,
  /** Forward navigation event handler */
  onForward: PropTypes.func.isRequired,
  /** Already saved info, in case we're navigating back and forth */
  superAdmin: PropTypes.shape({
    type: PropTypes.string.isRequired,
    tenant: PropTypes.string.isRequired,
  }),
  /** List of available tenants */
  tenants: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

SuperAdmin.defaultProps = {
  superAdmin: {
    type: SUBSCRIPTION_TYPE.FREE,
    tenant: '',
  },
};

export default translate(SuperAdmin);
