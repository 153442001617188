import React, { useState } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Form from '../../../commonComponents/forms/Form';
import translate from '../../../commonComponents/translate';
import withCSRF from '../../../commonComponents/withCSRF';
import { compose } from '../../../services/utilityFunctions';
import StripeService from '../../../services/stripeService';
import Request from '../../../services/request';
import Notifications from '../../../services/notifications';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      iconColor: '#222222',
      color: '#222222',
      fontSize: '16px',
      fontWeight: 100,
      '::placeholder': {
        color: '#aab7c4',
      },
      ':-webkit-autofill': {
        color: '--primary',
      },
    },
    invalid: {
      color: '#f4516c',
      iconColor: '#f4516c',
      ':-webkit-autofill': {
        color: '#f4516c',
      },
    },
  },
};

function CreditCardForm2({ t, _csrf, finishButtonText, onFinish, postingForm }) {
  const stripe = useStripe();
  const elements = useElements();

  const [stateForm, setStateForm] = useState({
    postingForm: false,
  });

  const validationRules = {};

  const attachPaymentMethod = paymentMethodId => new Promise((resolve, reject) => {
    new Request(_csrf)
      .post('/payments/methods/attach', { paymentMethodId })
      .done(({ data: customer }) => {
        console.log('attachPaymentMethod.customer: ', customer);
        resolve(customer);
      })
      .fail(err => {
        reject(err);
      });
  });

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    try {
      const cardNumber = elements.getElement(CardNumberElement);
      setStateForm({ postingForm: true });
      // Use your card Element with other Stripe.js APIs
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumber,
      });
      if (error) {
        console.log(error.message);
        setStateForm({ postingForm: false });
        Notifications.showNotificationError(t('error'), t(StripeService.handleErrors(error)));
      } else {
        const pm = await attachPaymentMethod(paymentMethod.id);
        setStateForm({ postingForm: false });
        console.log('**** confirmSepaDebitSetup.PaymentMethod: ', pm);
        onFinish(pm);
      }
    } catch (err) {
      setStateForm({ postingForm: false });
      Notifications.showNotificationError(t('error'), t(err));
    }
  };

  const finishButtonClass = cx(
    'btn',
    'm-btn--pill',
    'm-btn--air',
    'btn-sm',
    'm-btn',
    'm-btn--custom',
    'btn-primary',
    'payment-finish-button',
    { 'm-loader': postingForm || stateForm.postingForm },
    { 'm-loader--right': postingForm || stateForm.postingForm },
    { 'm-loader--light': postingForm || stateForm.postingForm },
  );

  return (
    <Form
      className="payment-form"
      id="ccForm"
      validationRules={validationRules}
      submitMethod={handleSubmit}
    >
      {submitHandler => (
        <>
          <div className="payment-input">
            <label htmlFor="cardNo">
              {t('subscriptionCreate_payment_cardNo')}
              <CardNumberElement
                id="cardNo"
                name="cardNo"
                options={CARD_ELEMENT_OPTIONS}
              />
            </label>
          </div>
          <div className="payment-line">
            <div className="payment-column">
              {t('subscriptionCreate_payment_expiration')}
              <CardExpiryElement
                id="expirationMonth1"
                name="expirationMonth1"
                options={CARD_ELEMENT_OPTIONS}
              />
            </div>
            <div className="payment-column">
              {t('subscriptionCreate_payment_cvc')}
              <CardCvcElement
                id="cvc"
                name="cvc"
                options={CARD_ELEMENT_OPTIONS}
              />
              <div className="payment-input-hint">
                {t('subscriptionCreate_payment_cvc_hint')}
              </div>
            </div>
          </div>
          <button
            type="submit"
            onClick={submitHandler}
            disabled={postingForm}
            className={finishButtonClass}
          >
            {finishButtonText}
          </button>
        </>
      )}
    </Form>
  );
}

CreditCardForm2.propTypes = {
  t: PropTypes.func.isRequired,
  _csrf: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
  finishButtonText: PropTypes.string.isRequired,
  postingForm: PropTypes.bool.isRequired,
};

CreditCardForm2.defaultProps = {};

export default compose(
  translate,
  withCSRF,
)(CreditCardForm2);
