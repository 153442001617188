import React from 'react';
import PropTypes from 'prop-types';
import { compose, isHigherAdmin } from '../../../../services/utilityFunctions';
import translate from '../../../../commonComponents/translate';
import withLoggedUser from '../../../../commonComponents/withLoggedUser';
import Form from '../../../../commonComponents/forms/Form';
import FormInput from '../../../../commonComponents/forms/FormInput';
import Separator from '../../../../commonComponents/forms/Separator';
import UserProp from '../../../profile/propTypes/userProp';
import CountryProp from '../../../../commonComponents/forms/Address/CountryProp';
import FormAddressFragment from '../../../../commonComponents/forms/Address/FormAddressFragment';
import ShippingProp from '../../../profile/propTypes/shippingProp';
import TaxInfo from '../../../profile/propTypes/taxInfoProp';
import TaxIdTypeProp from '../../propTypes/TaxIdTypeProp';

class Billing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: { ...props.shipping.address },
      name: props.shipping.name,
      phone: props.shipping.phone,
      invoiceEmail: props.invoiceEmail,
      tax_info: { ...props.tax_info },
    };

    const { fieldsRequired } = this.props;

    this.validationRules = {
      name: { required: fieldsRequired },
      phone: { required: fieldsRequired },
      email: { required: fieldsRequired },
      tax_id: { required: fieldsRequired },
      type: { required: fieldsRequired },
      city: { required: fieldsRequired },
      country: { required: fieldsRequired },
      line_1: { required: fieldsRequired },
      postal_code: { required: fieldsRequired },
      state: { required: false },
    };
    this.nextStep = 4;
    this.submitButton = null;

    this.createInput = this.createInput.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleSubSectionChange = this.handleSubSectionChange.bind(this);
    this.handleNavigation = this.handleNavigation.bind(this);
    this.validateAndLeave = this.validateAndLeave.bind(this);
  }

  componentDidMount() {
    const { showValidationErrors } = this.props;
    if (showValidationErrors) {
      if (this.submitButton) {
        this.submitButton.click();
      }
    }
  }

  validateAndLeave(nextStep) {
    this.nextStep = nextStep;
    if (this.submitButton) this.submitButton.click();
  }

  handleNavigation(validated) {
    return () => {
      if (!validated && this.props.showValidationErrors) {
        const el = document.querySelector('.has-danger');
        if (el) {
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'start',
          });
        }
        this.props.resetShowErrors();
      } else {
        const { address, name, phone, invoiceEmail, tax_info, fieldsRequired } = this.state;
        this.props.onNavigate(
          {
            shipping: { address: { ...address }, name, phone },
            invoiceEmail,
            tax_info,
            fieldsRequired,
          },
          this.nextStep,
          validated,
        );
      }
    };
  }

  createInput(section, field, disabled, typeParam) {
    const { t, fieldsRequired, taxIdTypes } = this.props;

    const type = typeParam || 'text';

    let value;
    let changeHandler;
    if (section === 'main') {
      value = this.state[field];
      changeHandler = this.handleChange(field);
    } else {
      value = this.state[section][field];
      changeHandler = this.handleSubSectionChange(section)(field);
    }
    let placeholder = '';
    let inputProps = {};
    if (section === 'tax_info') {
      if (field === 'type') {
        inputProps = {
          required: fieldsRequired,
          items: taxIdTypes.map(c => ({
            id: c.taxCode,
            text: c.taxType === 'eu_vat' ? `${c.flagEmoji} ${c.countryName} VAT number` : `${c.flagEmoji} ${c.name}`,
          })),
        };
      }
      if (field === 'tax_id') {
        const taxCode = this.state.tax_info.type;
        if (taxCode) {
          const taxIdType = taxIdTypes.find(txId => txId.taxCode === taxCode);
          if (taxIdType) {
            ({ placeholder } = taxIdType);
          }
        }
      }
    }
    return (
      <FormInput
        disabled={disabled}
        id={field}
        name={field}
        value={value}
        onChange={changeHandler}
        label={t(`subscriptionCreate_billing_${field}`)}
        labelClassName="col-2"
        containerClassName="col-7"
        type={type}
        groupProps={{ className: 'row' }}
        inputProps={{ ...inputProps }}
        placeholder={placeholder}
      />
    );
  }

  handleChange(field) {
    return value => {
      this.setState({ [field]: value });
    };
  }

  handleAddressChange(newAddress) {
    this.setState(({ address }) => ({
      address: { ...address, ...newAddress },
    }));
  }

  handleSubSectionChange(section) {
    return field => value => {
      this.setState(
        state => ({
          ...state,
          [section]: { ...state[section], [field]: value },
        }),
        () => {
          if (field === 'country') {
            this.props.onCountryChange(value);
          }
        },
      );
    };
  }

  render() {
    const { t, countries, forceStripeCustomer, loggedUser } = this.props;
    const { address } = this.state;
    const disabled = forceStripeCustomer && !isHigherAdmin(loggedUser);

    return (
      <div className="billing-container">
        <Form
          id="billingForm"
          className="m-form m-form--fit m-form--label-align-right"
          validationRules={this.validationRules}
          submitMethod={this.handleNavigation(true)}
          onInvalidForm={this.handleNavigation(false)}
        >
          {submitHandler => (
            <React.Fragment>
              {this.createInput('main', 'name', disabled)}
              {this.createInput('main', 'phone', disabled)}
              {this.createInput('main', 'invoiceEmail', disabled, 'email')}
              <Separator />
              {this.createInput('tax_info', 'type', disabled, 'select')}
              {this.createInput('tax_info', 'tax_id', disabled)}
              <Separator />
              <FormAddressFragment
                disabled={disabled}
                style={{ paddingBottom: '30px' }}
                address={address}
                onAddressChange={this.handleAddressChange}
                onFieldChange={this.handleSubSectionChange('address')}
                countries={countries}
              />
              <button
                ref={c => {
                  this.submitButton = c;
                }}
                type="submit"
                onClick={submitHandler}
                style={{ display: 'none' }}
              />
            </React.Fragment>
          )}
        </Form>
        <div className="wizard-content-footer">
          <button
            onClick={() => this.validateAndLeave(2)}
            type="submit"
            className={
              'btn m-btn--pill m-btn--air' +
              ' btn-outline-primary m-btn m-btn--custom wizard-footer-btn'
            }
          >
            {`< ${t('previous')}`}
          </button>
          <button
            onClick={() => this.validateAndLeave(4)}
            type="submit"
            className={
              'btn m-btn--pill m-btn--air btn-primary' +
              ' m-btn m-btn--custom wizard-footer-btn'
            }
          >
            {`${t('next')} >`}
          </button>
        </div>
      </div>
    );
  }
}

Billing.propTypes = {
  t: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape(CountryProp.propType))
    .isRequired,
  onNavigate: PropTypes.func.isRequired,
  shipping: PropTypes.shape(ShippingProp.propType).isRequired,
  tax_info: PropTypes.shape(TaxInfo.propType).isRequired,
  invoiceEmail: PropTypes.string.isRequired,
  showValidationErrors: PropTypes.bool,
  resetShowErrors: PropTypes.func.isRequired,
  onCountryChange: PropTypes.func.isRequired,
  forceStripeCustomer: PropTypes.bool.isRequired,
  loggedUser: PropTypes.shape(UserProp.propType),
  fieldsRequired: PropTypes.bool.isRequired,
  taxIdTypes: PropTypes.arrayOf(PropTypes.shape(TaxIdTypeProp.propType)).isRequired,
};

Billing.defaultProps = {
  showValidationErrors: false,
  loggedUser: null,
};

export default compose(
  translate,
  withLoggedUser,
)(Billing);
