import React from 'react';
import SubscriptionList from './list/SubscriptionList';

/**
 * Subscriptions list functionality, wrapping filters and list
 */
export default function Subscriptions() {
  return (
    <div className="m-portlet__body">
      <SubscriptionList query={{}} />
    </div>
  );
}
