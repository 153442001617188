import React from 'react';
import PropTypes from 'prop-types';
import Request from '../../../../services/request';
import Notifications from '../../../../services/notifications';
import { compose, isHigherAdmin } from '../../../../services/utilityFunctions';
import withCSRF from '../../../../commonComponents/withCSRF';
import translate from '../../../../commonComponents/translate';
import FormHeader from '../../../../commonComponents/forms/FormHeader';
import CardSelector from '../../../subscriptionDetails/steps/payment/creditCardSelector/CreditCardSelector';
import CreditCardModal from '../../../payments/modals/CreditCardModal';
import SEPAModal from '../../../payments/modals/SEPAModal';
import Loader from '../../../../services/loader';
import UserProp from '../../propTypes/userProp';
import withLoggedUser from '../../../../commonComponents/withLoggedUser';
import StripePaymentMethodsProp from '../../../payments/propTypes/StripePaymentMethodsProp';
import ShippingProp from '../../propTypes/shippingProp';
import CountryProp from '../../../../commonComponents/forms/Address/CountryProp';

/**
 * Form to update the current logged user's credit card information
 */
class PaymentForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      postingForm: false,
      paymentMethods: props.paymentMethods.slice(),
      showModalCard: false,
      showModalSEPA: false,
    };

    this.loader = null;

    this.handleClickAddNewCard = this.handleClickAddNewCard.bind(this);
    this.handleClickAddSEPA = this.handleClickAddSEPA.bind(this);
    this.handleAddedPaymentMethod = this.handleAddedPaymentMethod.bind(this);
    this.handleSelectCard = this.handleSelectCard.bind(this);
    this.handleRemoveCard = this.handleRemoveCard.bind(this);
    this.handleReactivation = this.handleReactivation.bind(this);
    this.emitChange = this.emitChange.bind(this);
  }

  componentDidMount() {
    this.loader = new Loader('#credit-card-container');
  }

  handleClickAddNewCard() {
    this.setState({ showModalCard: true });
  }

  handleClickAddSEPA() {
    this.setState({ showModalSEPA: true });
  }

  emitChange() {
    this.props.onCardsChange(this.state.paymentMethods.slice());
  }

  performRequest(method, path, params, errorKey, onSuccess) {
    const { t, _csrf } = this.props;

    this.setState({ postingForm: true });
    this.loader.show();
    const result = new Request(_csrf)[method](path, params);
    try {
      result
        .done(data => {
          this.loader.hide();
          this.setState({ postingForm: false });
          onSuccess(data);
        })
        .fail(err => {
          let error = t(errorKey);
          if (err && err.responseJSON) {
            error = err.responseJSON.message || t(err.responseJSON.error);
          }
          Notifications.showNotificationError(t('error'), error);
          this.setState({ postingForm: false });
          this.loader.hide();
        });
    } catch (err) {
      Notifications.showNotificationError(t('error'), err);
      this.setState({ postingForm: false });
      this.loader.hide();
    }
  }

  handleReactivation() {
    const { t } = this.props;
    const onSuccess = () => {
      Notifications.showNotificationSuccess(
        t('success'),
        t('billing_reactivationSuccessful'),
      );
      setTimeout(() => window.location.reload(), 1000);
    };

    this.performRequest(
      'post',
      '/subscription/reactivate',
      undefined,
      'error_subscriptionReactivate',
      onSuccess,
    );
  }

  handleSelectCard(paymentMethodId) {
    const onSuccess = () => {
      this.setState(
        ({ paymentMethods }) => ({
          paymentMethods: paymentMethods.map(
            c =>
              c.id === paymentMethodId
                ? { ...c, isDefault: true }
                : { ...c, isDefault: false },
          ),
        }),
        this.emitChange,
      );
    };

    this.performRequest(
      'post',
      '/payments/methods/default',
      { paymentMethodId },
      'error_cardSelect',
      onSuccess,
    );
  }

  handleAddedPaymentMethod(paymentMethod) {
    console.log('handleAddedPaymentMethod().paymentMethod;', paymentMethod);
    this.setState(
      ({ paymentMethods }) => ({
        paymentMethods: paymentMethods
          .concat(paymentMethod)
          .map(
            c =>
              c.id === paymentMethod.id
                ? { ...c, isDefault: true }
                : { ...c, isDefault: false },
          ),
        showModalCard: false,
        showModalSEPA: false,
      }),
      this.emitChange,
    );
  }

  handleRemoveCard(paymentMethodId) {
    const onSuccess = () => {
      this.setState(
        ({ paymentMethods }) => ({ paymentMethods: paymentMethods.filter(c => c.id !== paymentMethodId) }),
        this.emitChange,
      );
    };
    console.log('handleRemoveCard().paymentMethodId: ', paymentMethodId);
    this.performRequest(
      'delete',
      '/payments/methods',
      { paymentMethodId },
      'error_cardRemove',
      onSuccess,
    );
  }

  render() {
    const {
      t,
      subscriptionIsInactive,
      forceStripeCustomer,
      loggedUser,
      shipping,
      invoiceEmail,
      SEPACountries,
    } = this.props;
    const { postingForm, showModalCard, showModalSEPA, paymentMethods } = this.state;

    const hasEditPrivilege = !forceStripeCustomer || isHigherAdmin(loggedUser);

    return (
      <React.Fragment>
        <div
          className="m-form m-form--fit m-form--label-align-right"
          id="credit-card-container"
        >
          <div className="m-portlet__body">
            <FormHeader title={`${t('payment_info')}`} />
            {subscriptionIsInactive && (
              <div className="m-form__section">
                <div className="m-form__heading">
                  <h6 className="m-form__heading-title m--font-danger">
                    {t('billing_reactivation_msg')}
                  </h6>
                </div>
              </div>
            )}
            {hasEditPrivilege ? (
              <CardSelector
                paymentMethods={paymentMethods}
                isModeSelection={hasEditPrivilege}
                onSelectPaymentMethod={this.handleSelectCard}
                onClickAddCard={this.handleClickAddNewCard}
                onClickAddSEPA={this.handleClickAddSEPA}
                onRemovePaymentMethod={this.handleRemoveCard}
                SEPACountries={SEPACountries}
              />
            ) : (
              <h6 className="payment-cc-noSelection-title">
                {t('subscriptionCreate_payment_noCCSelection')}
              </h6>
            )}
          </div>
          {subscriptionIsInactive && (
            <div className="m-portlet__foot m-portlet__foot--fit">
              <div className="m-form__actions">
                <div className="row">
                  <div className="col-7 offset-2">
                    <button
                      type="submit"
                      className="btn btn-accent m-btn m-btn--air m-btn--custom loader-button"
                      onClick={this.handleReactivation}
                    >
                      {t('billing_reactivate')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {showModalCard && (
            <CreditCardModal
              onFinish={this.handleAddedPaymentMethod}
              onClose={() => this.setState({ showModalCard: false })}
              postingForm={postingForm}
            />
        )}
        {showModalSEPA && (
            <SEPAModal
              onFinish={this.handleAddedPaymentMethod}
              onClose={() => this.setState({ showModalSEPA: false })}
              postingForm={postingForm}
              shipping={shipping}
              invoiceEmail={invoiceEmail}
              SEPACountries={SEPACountries}
            />
        )}
      </React.Fragment>
    );
  }
}

PaymentForm.propTypes = {
  t: PropTypes.func.isRequired,
  _csrf: PropTypes.string.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.shape(StripePaymentMethodsProp.propType)).isRequired,
  onCardsChange: PropTypes.func.isRequired,
  subscriptionIsInactive: PropTypes.bool,
  forceStripeCustomer: PropTypes.bool.isRequired,
  loggedUser: PropTypes.shape(UserProp.propType),
  shipping: PropTypes.shape(ShippingProp.propType),
  invoiceEmail: PropTypes.string,
  SEPACountries: PropTypes.arrayOf(PropTypes.shape(CountryProp.propType)).isRequired,
};

PaymentForm.defaultProps = {
  subscriptionIsInactive: false,
  loggedUser: null,
  invoiceEmail: '',
  shipping: ShippingProp.defaultProp,
};

export default compose(
  withCSRF,
  withLoggedUser,
  translate,
)(PaymentForm);
