import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ProductProp from '../../../propTypes/ProductProp';

export default class ProductImage extends React.PureComponent {
  componentDidMount() {
    $('[data-toggle="m-popover__item"]').each(function initPopover() {
      const el = $(this);
      const skin = el.data('skin') ? `m-popover--skin-${el.data('skin')}` : '';

      el.popover({
        container: 'body',
        trigger: 'hover',
        placement: 'auto',
        template: `\
            <div class="m-popover ${skin} popover popover-product" role="tooltip">\
                <h3 class="popover-header"></h3>\
                <div class="popover-body"></div>\
            </div>`,
      });
    });
  }

  render() {
    const { product, className, style, light, showPopup } = this.props;
    const { image, name, description } = product;

    const styles = product.belongsToPack
      ? {
          height: '40px',
          width: '40px',
          minHeight: '40px',
          minWidth: '40px',
        }
      : {};

    const type = light ? 'light' : 'normal';
    const isSvg = image.split('.').pop() === 'svg';
    const imageClasses = cx(`product-image-${type}`, className, {
      [`svg-product-image-${type}`]: isSvg,
    });

    const PopoverContent = () => (
      <div className="pack-popover">
        <img src={image} className={imageClasses} alt="product thumb" />
        <div className="pack-popover-details">
          <div className="pack-popover-description">{description}</div>
        </div>
      </div>
    );

    return (
      <div className={`product-image-background-${type}`} style={styles}>
        <img
          className={imageClasses}
          data-original-title={name}
          data-html={showPopup ? true : undefined}
          data-content={
            showPopup ? renderToStaticMarkup(<PopoverContent />) : undefined
          }
          data-toggle={showPopup ? 'm-popover__item' : undefined}
          style={style}
          src={image}
          alt="product thumb"
        />
      </div>
    );
  }
}

ProductImage.propTypes = {
  className: PropTypes.string,
  product: PropTypes.shape(ProductProp.propType).isRequired,
  style: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
    top: PropTypes.string,
    left: PropTypes.string,
  }),
  light: PropTypes.bool,
  showPopup: PropTypes.bool,
};

ProductImage.defaultProps = {
  className: '',
  style: {},
  light: false,
  showPopup: false,
};
