import PropTypes from 'prop-types';
import ShippingProp from './shippingProp';
import TaxInfo from './taxInfoProp';

const propType = {
  shipping: PropTypes.shape(ShippingProp.propType).isRequired,
  tax_info: PropTypes.shape(TaxInfo.propType).isRequired,
  invoiceEmail: PropTypes.string.isRequired,
};

const defaultProp = {
  shipping: ShippingProp.defaultProp,
  tax_info: TaxInfo.defaultProp,
  invoiceEmail: '',
};

export default { propType, defaultProp };
