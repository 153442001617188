import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import translate from '../../commonComponents/translate';
import Form from '../../commonComponents/forms/Form';
import FormInput from '../../commonComponents/forms/FormInput';
import Checkbox from '../../commonComponents/forms/inputs/Checkbox';
import withCSRF from '../../commonComponents/withCSRF';
import { compose } from '../../services/utilityFunctions';
import OrganizationModal from './modal/OrganizationModal';

/**
 * Component wrapping the signin form on the SignIn page
 */
class SignInForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: props.email || '',
      password: props.password || '',
      organizationIdentifier: props.organizationData
        ? props.organizationData[0].identifierCode
        : '',
      rememberMe: false,
      postingForm: false,
    };

    this.validationRules = {
      email: { required: true, email: true },
      password: { required: true },
    };

    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  handleChange(field) {
    return value => this.setState({ [field]: value });
  }

  handleCheckboxChange() {
    this.setState(({ rememberMe }) => ({ rememberMe: !rememberMe }));
  }

  submitForm() {
    this.setState({ postingForm: true });
    $('#signInForm').submit();
  }

  render() {
    const {
      t,
      _csrf,
      displayForgotPasswordForm,
      organizationData,
    } = this.props;

    const {
      email,
      password,
      organizationIdentifier,
      rememberMe,
      postingForm,
    } = this.state;

    const submitButtonClasses = cx(
      'btn',
      'btn-primary',
      'm-btn',
      'm-btn--pill',
      'm-btn--custom',
      'm-btn--air',
      'm-login__btn',
      'm-login__btn--brand',
      { 'm-loader': postingForm },
      { 'm-loader--right': postingForm },
      { 'm-loader--light': postingForm },
    );

    return (
      <div className="m-login__signin">
        <div className="m-login__head">
          <h3 className="m-login__title">{t('login_title')}</h3>
        </div>
        <Form
          id="signInForm"
          className="m-login__form m-form"
          action="/signin"
          method="POST"
          validationRules={this.validationRules}
          submitMethod={this.submitForm}
        >
          {submitHandler => (
            <React.Fragment>
              {organizationData && (
                <OrganizationModal
                  organizationIdentifier={organizationIdentifier}
                  handleChange={this.handleChange}
                  onSubmit={this.submitForm}
                  postingForm={postingForm}
                  organizationData={organizationData}
                  buttonCaption={t('login_signIn')}
                />
              )}
              <FormInput type="hidden" name="_csrf" value={_csrf} />
              <FormInput
                id="email"
                placeholder="Email"
                name="email"
                value={email}
                onChange={this.handleChange('email')}
                inputProps={{ autoComplete: 'off' }}
              />
              <FormInput
                inputClassName="m-login__form-input--last"
                id="password"
                type="password"
                placeholder="Password"
                name="password"
                value={password}
                onChange={this.handleChange('password')}
              />
              <div className="row m-login__form-sub">
                <div className="col m--align-left m-login__form-left" />
                <div className="col m--align-right m-login__form-right">
                  <button
                    style={{ border: 'none', backgroundColor: 'transparent' }}
                    type="button"
                    id="m_login_forget_password"
                    className="m-link"
                    onClick={displayForgotPasswordForm}
                  >
                    {t('login_forgotPassword')}
                  </button>
                </div>
              </div>
              <div className="m-login__form-action">
                <button
                  id="m_login_signin_submit"
                  type="submit"
                  className={submitButtonClasses}
                  disabled={postingForm}
                  onClick={submitHandler}
                >
                  {t('login_signIn')}
                </button>
              </div>
            </React.Fragment>
          )}
        </Form>
      </div>
    );
  }
}

SignInForm.propTypes = {
  /** translation function, i18n.__ on the browser, response.__ on the server */
  t: PropTypes.func.isRequired,
  /** _csrf token */
  _csrf: PropTypes.string.isRequired,
  /** Function to call to dismiss this form and replace it with ForgotPasswordForm */
  displayForgotPasswordForm: PropTypes.func.isRequired,
  /** Previously entered email, in case of error */
  email: PropTypes.string,
  /** Previously entered password, in case of error */
  password: PropTypes.string,
  /** Organization data, in case the user belongs to multiple organizations and we need to pick one */
  organizationData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      identifierCode: PropTypes.string.isRequired,
    }).isRequired,
  ),
};

SignInForm.defaultProps = {
  organizationData: null,
  email: null,
  password: null,
};

export default compose(
  withCSRF,
  translate,
)(SignInForm);
