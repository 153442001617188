import PropTypes from 'prop-types';

const propType = {
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  line1: PropTypes.string.isRequired,
  line2: PropTypes.string,
  postal_code: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
};

const defaultProp = {
  city: '',
  country: '',
  line1: '',
  line2: '',
  postal_code: '',
  state: '',
};

const requiredFields = {
  city: true,
  country: true,
  line1: true,
  line2: false,
  postal_code: true,
  state: true,
};

export default { propType, defaultProp, requiredFields };
