import React from 'react';
import PropTypes from 'prop-types';
import YouTubePlayer from 'react-youtube';
import ImageProp from '../../../../propTypes/ImageProp';
import ImageGallery from './ProductItemImages';

export default function ProductItemGallery({ images, video }) {
  return (
    <div className="product-item-modal-gallery">
      {video && video.id ? (
        <div className="product-item-modal-video">
          <YouTubePlayer
            videoId={video.id}
            className="video-iframe-container"
            containerClassName="video-iframe-container"
          />
        </div>
      ) : (
        <ImageGallery images={images} />
      )}
    </div>
  );
}

ProductItemGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape(ImageProp.propType)),
  video: PropTypes.shape({
    url: PropTypes.string,
    thumb: PropTypes.string,
  }),
};

ProductItemGallery.defaultProps = {
  images: [],
  video: null,
};
