import React from 'react';
import PropTypes from 'prop-types';
import UserProp from '../../../../sections/profile/propTypes/userProp';
import translate from '../../../translate';
import HeaderProfilePopup from './HeaderProfilePopup';

/**
 * User header display, with greeting, name and avatar
 */
function HeaderProfile({ t, profile, theme }) {
  return (
    <div
      className="m-stack__item m-stack__item--fluid m-header-head"
      id="m_header_nav"
    >
      <div
        id="m_header_topbar"
        className="m-topbar  m-stack m-stack--ver m-stack--general"
      >
        <div className="m-stack__item m-topbar__nav-wrapper">
          <ul className="m-topbar__nav m-nav m-nav--inline">
            <li
              className={
                'm-nav__item m-topbar__user-profile ' +
                'm-topbar__user-profile--img m-dropdown m-dropdown--medium ' +
                'm-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right ' +
                'm-dropdown--mobile-full-width m-dropdown--skin-light'
              }
              m-dropdown-toggle="click"
            >
              <a href="#" className="m-nav__link m-dropdown__toggle">
                <span className="m-topbar__welcome">
                  {t('header_greeting')}
                  &nbsp;
                </span>
                <span className="m-topbar__username">{profile.name}</span>
                <span className="m-topbar__userpic">
                  <img
                    style={{
                      width: '41px',
                      height: '41px',
                      objectFit: 'cover',
                    }}
                    src={profile.avatar}
                    className="m--img-rounded m--img-centered"
                    alt=""
                  />
                </span>
              </a>
              <HeaderProfilePopup profile={profile} theme={theme} />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

HeaderProfile.propTypes = {
  /** Translation function */
  t: PropTypes.func.isRequired,
  /** Member to display */
  profile: PropTypes.shape(UserProp.propType).isRequired,
  /** metronic theme being used */
  theme: PropTypes.string.isRequired,
};

export default translate(HeaderProfile);
