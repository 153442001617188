/* globals Swal */

export default class SweetAlert {
  static custom(options) {
    return Swal(options);
  }

  static success(options) {
    return Swal({ ...options, type: 'success' });
  }

  static warning(options) {
    return Swal({ ...options, type: 'warning' });
  }

  static error(options) {
    return Swal({ ...options, type: 'error' });
  }

  static info(options) {
    return Swal({ ...options, type: 'info' });
  }
}
