import React from 'react';
import PropTypes from 'prop-types';
import CartProp from '../../propTypes/CartProp';
import PackProp from '../../propTypes/PackProp';
import CategoryProp from '../../propTypes/CategoryProp';
import translate from '../../../../commonComponents/translate';
import Pack from './pack/Pack';
import ProductCategory from './category/ProductCategory';
import {
  PRODUCT_TYPE,
  PRODUCT_VIEW,
} from '../../../../services/utilityFunctions';
import ProductItemModal from './category/modal/ProductItemModal';

// Class component instead of stateless function so that react refs work
// eslint-disable-next-line react/prefer-stateless-function
class Options extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      selectedProduct: null,
    };

    this.validateAndLeave = this.validateAndLeave.bind(this);
    this.handleItemAdd = this.handleItemAdd.bind(this);
    this.handleItemRemove = this.handleItemRemove.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  handleItemAdd(addInfo) {
    if (addInfo.product.type === PRODUCT_TYPE.SUBSCRIPTION) {
      this.props.onSubscriptionAdd({ ...addInfo, interval: addInfo.product.interval });
    } else {
      this.props.onProductAdd({ ...addInfo, interval: addInfo.product.interval });
    }
    this.setState({
      selectedProduct: { ...addInfo.product, isInCart: true },
    });
  }

  handleItemRemove(product) {
    if (product.type === PRODUCT_TYPE.SUBSCRIPTION) {
      this.props.onSubscriptionRemove(product._id);
    } else {
      this.props.onProductRemove(product._id);
    }
    this.setState({ selectedProduct: { ...product, isInCart: false } });
  }

  validateAndLeave(nextStep) {
    this.props.onNavigate({}, nextStep, true);
  }

  closeModal() {
    this.setState({
      showModal: false,
      selectedProduct: false,
    });
  }

  openModal(product) {
    this.setState({
      showModal: true,
      selectedProduct: product,
    });
  }

  render() {
    const {
      t,
      cart,
      packs,
      onPackAdd,
      onPackRemove,
      categories,
      isEdit,
      interval,
    } = this.props;

    const { showModal, selectedProduct } = this.state;

    return (
      <React.Fragment>
        {showModal && (
          <ProductItemModal
            onClose={this.closeModal}
            onClickAdd={this.handleItemAdd}
            onClickRemove={this.handleItemRemove}
            product={selectedProduct}
            interval={interval}
          />
        )}
        <div style={{ padding: '20px' }}>
          <div className="row">
            <div className="col-12">
              <h3 className="subscription-options-title">
                {t('subscriptionCreate_options_packs')}
              </h3>
              <div className="subscription-packs-container">
                {packs.map(p => {
                  const isInCart = !!cart.packs.find(
                    ({ product }) => product._id === p._id,
                  );

                  return (
                    <Pack
                      key={p._id}
                      pack={p}
                      isSubscriptionEdit={isEdit}
                      isInCart={isInCart}
                      onAdd={onPackAdd}
                      onRemove={onPackRemove}
                      onOpenModal={this.openModal}
                      interval={interval}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="subscription-trophies-container">
            {categories
              .filter(c => c.displayOnView === PRODUCT_VIEW.OPTIONS)
              .map(c => (
                <div className="m--margin-top-30" key={c._id}>
                  <ProductCategory
                    name={c.name}
                    cart={cart}
                    products={c.products}
                    onClickAddItem={this.handleItemAdd}
                    onClickRemoveItem={this.handleItemRemove}
                    isSubscriptionEdit={isEdit}
                    onOpenDescriptionModal={this.openModal}
                    interval={interval}
                  />
                </div>
              ))}
          </div>
        </div>
        <div className="wizard-content-footer">
          <button
            onClick={() => this.validateAndLeave(1)}
            type="submit"
            className={
              'btn m-btn--pill m-btn--air' +
              ' btn-outline-primary m-btn m-btn--custom wizard-footer-btn'
            }
          >
            {`< ${t('previous')}`}
          </button>
          <button
            onClick={() => this.validateAndLeave(3)}
            type="submit"
            className={
              'btn m-btn--pill m-btn--air btn-primary' +
              ' m-btn m-btn--custom wizard-footer-btn'
            }
          >
            {`${t('next')} >`}
          </button>
        </div>
      </React.Fragment>
    );
  }
}

Options.propTypes = {
  t: PropTypes.func.isRequired,
  cart: PropTypes.shape(CartProp.propType).isRequired,
  packs: PropTypes.arrayOf(PropTypes.shape(PackProp.propType)).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape(CategoryProp.propType))
    .isRequired,
  onPackAdd: PropTypes.func.isRequired,
  onPackRemove: PropTypes.func.isRequired,
  onProductAdd: PropTypes.func.isRequired,
  onProductRemove: PropTypes.func.isRequired,
  onSubscriptionAdd: PropTypes.func.isRequired,
  onSubscriptionRemove: PropTypes.func.isRequired,
  onNavigate: PropTypes.func,
  isEdit: PropTypes.bool.isRequired,
  interval: PropTypes.string.isRequired,
};

Options.defaultProps = {
  onNavigate: () => null,
};

export default translate(Options);
