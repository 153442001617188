/* globals mApp */

export default class Loader {
  constructor(id) {
    this.id = id;
  }

  show(
    options = {
      overlayColor: '#000000',
      type: 'loader',
      state: 'primary',
      size: 'lg',
      message: 'Processing...',
    },
  ) {
    mApp.block(this.id, options);
  }

  hide() {
    mApp.unblock(this.id);
  }
}
