import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from './FormGroup';
import DatePicker from './inputs/DatePicker';
import DropZone from './inputs/DropZone';
import Select from './inputs/Select';
import GoogleSearch from './inputs/GoogleSearch';
import FormLabel from './FormLabel';

/**
 * Standard html input wrapped in a FormGroup component, using metronic's base form input classes
 */
export default function FormInput(props) {
  const {
    inputClassName,
    containerClassName,
    id,
    name,
    type,
    value,
    onChange,
    placeholder,
    label,
    labelClassName,
    disabled,
    inputProps,
    groupProps,
    tooltip,
  } = props;

  const renderInput = () => {
    switch (type) {
      case 'date':
        return (
          <DatePicker
            id={id}
            value={value}
            onChange={onChange}
            name={name}
            className={`form-control m-input ${inputClassName}`}
            {...inputProps}
          />
        );
      case 'dropZone':
        return (
          <DropZone
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            {...inputProps}
          />
        );
      case 'select':
        return (
          <Select
            name={name}
            className={`form-control ${inputClassName}`}
            onChange={onChange}
            value={value}
            id={id}
            disabled={disabled}
            {...inputProps}
          />
        );
      case 'googleSearch':
        return (
          <GoogleSearch
            onChange={onChange}
            placeholder={placeholder}
            className={`form-control ${inputClassName}`}
            disabled={disabled}
            {...inputProps}
          />
        );
      case 'textarea':
        return (
          <textarea
            className={`form-control m-input ${inputClassName}`}
            id={id}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={({ currentTarget }) => onChange(currentTarget.value)}
            readOnly={onChange ? null : true}
            {...inputProps}
          />
        );
      case 'select2':
        return (
          <Select
            name={name}
            className={`form-control ${inputClassName}`}
            onChange={onChange}
            value={value || []}
            id={id}
            disabled={disabled}
            {...inputProps}
          />
        );
      default:
        return (
          <input
            className={`form-control m-input ${inputClassName}`}
            id={id}
            type={type}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={({ currentTarget }) => onChange(currentTarget.value)}
            readOnly={onChange ? null : true}
            disabled={disabled ? 'disabled' : ''}
            {...inputProps}
          />
        );
    }
  };

  return (
    <FormGroup {...groupProps}>
      {label && (
        <FormLabel
          id={id}
          label={label}
          tooltip={tooltip}
          className={labelClassName}
        />
      )}
      <div className={containerClassName}>{renderInput()}</div>
    </FormGroup>
  );
}

FormInput.propTypes = {
  /** Additional class names for the <input /> */
  inputClassName: PropTypes.string,
  /** Additional class names for the <div /> container */
  containerClassName: PropTypes.string,
  /** Html id */
  id: PropTypes.string,
  /** Html name */
  name: PropTypes.string,
  /** Html type, "text" if omitted */
  type: PropTypes.string,
  /** Html value */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  /** OnChange handler, should expect a react synthetic event */
  onChange: PropTypes.func,
  /** Html placeholder */
  placeholder: PropTypes.string,
  /** Label text, if the input has a <label> associated */
  label: PropTypes.string,
  /** Label classes if a <label> exists */
  labelClassName: PropTypes.string,
  /** Prop to put input disabled */
  disabled: PropTypes.bool,
};

FormInput.defaultProps = {
  inputClassName: '',
  containerClassName: '',
  id: undefined,
  name: undefined,
  type: 'text',
  onChange: undefined,
  placeholder: undefined,
  label: undefined,
  labelClassName: undefined,
  value: undefined,
  disabled: false,
};
