import React from 'react';
import PropTypes from 'prop-types';
import UserProp from '../../../../sections/profile/propTypes/userProp';
import NavigationLinks from './NavigationLinks';

/**
 * Member header popup, displaying profiles, logout, etc
 * Note: possibly notifications in case they're implemented in the project, should
 * require little refactoring
 */
export default function HeaderProfilePopup({ theme, profile }) {
  return (
    <div className="m-dropdown__wrapper">
      <span className="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust" />
      <div className="m-dropdown__inner">
        <div
          className="m-dropdown__header m--align-center"
          style={{
            background: `url(/dist/${theme}/assets/app/media/img/misc/user_profile_bg.jpg)`,
            backgroundSize: 'cover',
          }}
        >
          <div className="m-card-user m-card-user--skin-dark">
            <div className="m-card-user__pic">
              <img src={profile.avatar} className="m--img-rounded m--marginless" alt="" />
            </div>
            <div className="m-card-user__details">
              <span className="m-card-user__name m--font-weight-500">{profile.name}</span>
              <a href="" className="m-card-user__email m--font-weight-300 m-link">
                {profile.email}
              </a>
            </div>
          </div>
        </div>
        <div className="m-dropdown__body">
          <div className="m-dropdown__content">
            <NavigationLinks />
          </div>
        </div>
      </div>
    </div>
  );
}

HeaderProfilePopup.propTypes = {
  /** metronic theme */
  theme: PropTypes.string.isRequired,
  /** member profile being displayed */
  profile: PropTypes.shape(UserProp.propType).isRequired,
};
