import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import MetaContext from '../contextProviders/MetaContext';

export default function Title({ title, disableAppName, children }) {
  const { meta } = useContext(MetaContext);
  const { appName = 'Bravon' } = meta || {};
  const titleSuffix = title ? ` - ${title}` : '';
  const complexTitle = `${appName}${titleSuffix}`;
  const finalTitle = disableAppName ? title : complexTitle;
  return <DocumentTitle title={finalTitle}>{children}</DocumentTitle>;
}

Title.propTypes = {
  title: PropTypes.string,
  disableAppName: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

Title.defaultProps = {
  title: '',
  disableAppName: false,
};
