import React from 'react';
import PropTypes from 'prop-types';

export default function SignInLogo({ theme }) {
  return (
    <div className="m-login__logo">
      <a href="/">
        <img
          alt="logo"
          src={`/dist/${theme}/assets/demo/${theme}/media/img/logo/logo-dark.png`}
        />
      </a>
    </div>
  );
}

SignInLogo.propTypes = {
  theme: PropTypes.string.isRequired,
};
