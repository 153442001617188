import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../../../../commonComponents/translate';
import Modal from '../../../../../commonComponents/modal/Modal';
import ModalHeader from '../../../../../commonComponents/modal/ModalHeader';
import ModalFooter from '../../../../../commonComponents/modal/ModalFooter';

class QuantityModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { quantity: 1 };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(action) {
    switch (action.type) {
      case 'increment':
        return this.setState(({ quantity }) => ({ quantity: quantity + 1 }));
      case 'decrement':
        return this.setState(({ quantity }) => ({ quantity: quantity - 1 }));
      case 'set':
      default:
        return this.setState({ quantity: action.value });
    }
  }

  render() {
    const { t, onClose } = this.props;
    const { quantity } = this.state;

    return (
      <Modal>
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <ModalHeader
              onClose={() => onClose()}
              title={t('subscriptionCreate_quantity_title')}
            />
            <div className="modal-body quantity-modal-container">
              <div className="quantity-modal-message">
                {t('subscriptionCreate_quantity_message')}
              </div>
              <div className="quantity-modal-input-container input-group">
                <div className="input-group-prepend">
                  <button
                    className="btn btn-outline-primary"
                    type="button"
                    disabled={quantity <= 0}
                    onClick={() => this.handleChange({ type: 'decrement' })}
                  >
                    -
                  </button>
                </div>
                <input
                  className="form-control quantity-modal-input"
                  type="number"
                  onChange={evt =>
                    this.handleChange({
                      type: 'set',
                      value: Number(evt.currentTarget.value),
                    })
                  }
                  value={quantity}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-outline-primary"
                    type="button"
                    onClick={() => this.handleChange({ type: 'increment' })}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            <ModalFooter
              onCancel={() => onClose()}
              onSave={() => onClose(quantity)}
              cancelText={t('close')}
              saveText={t('save')}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

QuantityModal.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default translate(QuantityModal);
