import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

/**
 * TabbedPortlet header item, used to activate different tabs
 */
export default function TabHeader({ id, title, isSelected, onTabChange }) {
  const tabClasses = cx('nav-link', 'm-tabs__link', { active: isSelected }, { show: isSelected });

  return (
    <li className="nav-item m-tabs__item">
      <a className={tabClasses} data-toggle="tab" href={`#${id}`} role="tab" onClick={onTabChange}>
        <i className="flaticon-share m--hide" />
        {title}
      </a>
    </li>
  );
}

TabHeader.propTypes = {
  /** Id of the tab to activate on click */
  id: PropTypes.string.isRequired,
  /** Tab title to display */
  title: PropTypes.string.isRequired,
  /** Whether the tab is selected or not, used for styling */
  isSelected: PropTypes.bool,
  /** Handler for the click event on the tab header */
  onTabChange: PropTypes.func,
};

TabHeader.defaultProps = {
  isSelected: false,
  onTabChange: () => null,
};
