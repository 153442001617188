import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export const ALERT_TYPE = {
  SUCCESS: 'success',
  DANGER: 'danger',
};

/** Form dismissible alert */
export default function DismissibleAlert({ type, message, onClick, autoDismiss }) {
  const classes = cx(
    'm-alert',
    'm-alert--outline',
    'alert',
    { 'alert-danger': type === ALERT_TYPE.DANGER },
    { 'alert-success': type === ALERT_TYPE.SUCCESS },
    'alert-dismissible',
  );

  const dismissId = autoDismiss ? 'alert' : undefined;
  return (
    <div className={classes} role={dismissId}>
      <button
        type="button"
        className="close"
        data-dismiss={dismissId}
        aria-label="Close"
        onClick={onClick}
      />
      <span>{message}</span>
    </div>
  );
}

DismissibleAlert.propTypes = {
  /** Alert type, accepts one of the enum values exported */
  type: PropTypes.oneOf(Object.values(ALERT_TYPE)),
  /** Message to display */
  message: PropTypes.string.isRequired,
  /* Handler for close button click event */
  onClick: PropTypes.func,
  /** Whether the alert is dismissed using metronic's css/js and no other react interaction */
  autoDismiss: PropTypes.bool,
};

DismissibleAlert.defaultProps = {
  type: ALERT_TYPE.DANGER,
  onClick: () => true,
  autoDismiss: true,
};
