import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../../../commonComponents/translate';
import PaymentForm from './PaymentForm';
import CartProp from '../../propTypes/CartProp';
import CategoryProp from '../../propTypes/CategoryProp';
import PackProp from '../../propTypes/PackProp';
import PlanProp from '../../propTypes/PlanProp';
import Cart from '../../navigation/Cart';
import TaxInfoProp from '../../propTypes/TaxInfoProp';
import ShippingProp from '../../../profile/propTypes/shippingProp';
import StripePaymentMethodsProp from '../../../payments/propTypes/StripePaymentMethodsProp';
import CountryProp from '../../../../commonComponents/forms/Address/CountryProp';

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.formComponent = null;

    this.validateAndLeave = this.validateAndLeave.bind(this);
  }

  validateAndLeave(nextStep) {
    this.formComponent.validateAndLeave(nextStep);
  }

  render() {
    const {
      cart,
      paymentMethods,
      onFinish,
      onNavigate,
      postingForm,
      finishButtonText,
      plansAreFree,
      plans,
      categories,
      packs,
      taxInfo,
      shipping,
      isManualInvoiceSelected,
      onPackRemove,
      onProductRemove,
      onSubscriptionRemove,
      onDiscountAdd,
      onDiscountRemove,
      onManualInvoiceSelection,
      isEdit,
      forceStripeCustomer,
      invoiceEmail,
      SEPACountries,
      interval,
    } = this.props;

    return (
      <div className="m-portlet__body" style={{ height: '100%' }}>
        <div className="subscription-payment-container">
          <div className="payment-cart-container">
            <Cart
              plansAreFree={plansAreFree}
              cart={cart}
              categories={categories}
              packs={packs}
              plans={plans}
              taxInfo={taxInfo}
              country={shipping.address.country}
              onPackRemove={onPackRemove}
              onProductRemove={onProductRemove}
              onSubscriptionRemove={onSubscriptionRemove}
              onDiscountRemove={onDiscountRemove}
              totalContainerClassName="payment-cart-total-container"
              totalLabelClassName="payment-cart-total-label"
              totalValueClassName="payment-cart-total-value"
              priceLabelClassName="payment-cart-price-label"
              priceValueClassName="payment-cart-price-value"
              interval={interval}
            />
          </div>
          <div className="payment-cc-form">
            <PaymentForm
              wrappedComponentRef={c => {
                this.formComponent = c;
              }}
              cart={cart}
              categories={categories}
              paymentMethods={paymentMethods}
              isManualInvoiceSelected={isManualInvoiceSelected}
              finishButtonText={finishButtonText}
              onFinish={onFinish}
              onNavigate={onNavigate}
              postingForm={postingForm}
              onDiscountAdd={onDiscountAdd}
              onManualInvoiceSelection={onManualInvoiceSelection}
              forceStripeCustomer={forceStripeCustomer}
              isEdit={isEdit}
              shipping={shipping}
              invoiceEmail={invoiceEmail}
              SEPACountries={SEPACountries}
              plansAreFree={plansAreFree}
            />
          </div>
        </div>
      </div>
    );
  }
}

Payment.propTypes = {
  paymentMethods: PropTypes.arrayOf(PropTypes.shape(StripePaymentMethodsProp.propType)).isRequired,
  cart: PropTypes.shape(CartProp.propType).isRequired,
  isManualInvoiceSelected: PropTypes.bool.isRequired,
  finishButtonText: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
  postingForm: PropTypes.bool.isRequired,
  plansAreFree: PropTypes.bool.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape(CategoryProp.propType))
    .isRequired,
  packs: PropTypes.arrayOf(PropTypes.shape(PackProp.propType)).isRequired,
  plans: PropTypes.arrayOf(PropTypes.shape(PlanProp.propType)).isRequired,
  taxInfo: PropTypes.shape(TaxInfoProp.propType).isRequired,
  shipping: PropTypes.shape(ShippingProp.propType).isRequired,
  invoiceEmail: PropTypes.string,
  onPackRemove: PropTypes.func.isRequired,
  onProductRemove: PropTypes.func.isRequired,
  onSubscriptionRemove: PropTypes.func.isRequired,
  onDiscountAdd: PropTypes.func.isRequired,
  onDiscountRemove: PropTypes.func.isRequired,
  onManualInvoiceSelection: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  forceStripeCustomer: PropTypes.bool.isRequired,
  SEPACountries: PropTypes.arrayOf(PropTypes.shape(CountryProp.propType))
    .isRequired,
  interval: PropTypes.string.isRequired,
};

Payment.defaultProps = {
  invoiceEmail: '',
};

export default translate(Payment);
