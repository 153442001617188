import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../translate';
import FormInput from '../FormInput';
import CountryProp from './CountryProp';
import AddressProp from './AddressProp';
import Loader from '../../../services/loader';

class FormAddressFragment extends React.Component {
  constructor(props) {
    super(props);

    this.loader = null;
    this.setLoading = this.setLoading.bind(this);
  }

  componentDidMount() {
    this.loader = new Loader('#form-address-fragment');
  }

  setLoading(isLoading) {
    return () => (isLoading ? this.loader.show() : this.loader.hide());
  }

  render() {
    const {
      t,
      countries,
      address,
      onAddressChange,
      onFieldChange,
      translationPrefix,
      disabled,
      style,
    } = this.props;

    const clearErrors = id => {
      const element = $(`#${id}`);
      const form = element.closest('.form-group');
      form.removeClass('has-danger');
      form.find(`#${id}-error`).remove();
    };

    const handleAddressChange = newAddress => {
      onAddressChange(newAddress);
      Object.keys(newAddress).forEach(af => {
        if (newAddress[af] && AddressProp.requiredFields[af]) {
          clearErrors(af);
        }
      });
    };

    const createInput = (field, typeParam) => {
      const type = typeParam || 'text';

      const inputProps = () => {
        switch (field) {
          case 'country':
            return {
              required: true,
              placeholder: t(`${translationPrefix}_countryPlaceholder`),
              items: countries.map(c => ({
                id: c.code,
                text: `${c.flagEmoji} ${c.name}`,
              })),
            };
          default:
            return undefined;
        }
      };

      return (
        <FormInput
          id={field}
          name={field}
          value={address[field]}
          onChange={onFieldChange(field)}
          label={t(`${translationPrefix}_${field}`)}
          labelClassName="col-2"
          containerClassName="col-7"
          type={type}
          groupProps={{ className: 'row' }}
          inputProps={inputProps()}
          disabled={disabled}
        />
      );
    };

    return (
      <div id="form-address-fragment" style={style}>
        <FormInput
          id="googleSearch"
          onChange={handleAddressChange}
          label={t(`${translationPrefix}_googleSearch`)}
          placeholder={t(`${translationPrefix}_googleSearch_placeholder`)}
          labelClassName="col-2"
          containerClassName="col-7"
          type="googleSearch"
          groupProps={{ className: 'row' }}
          inputProps={{
            onRequestStart: this.setLoading(true),
            onRequestEnd: this.setLoading(false),
          }}
          disabled={disabled}
        />
        {createInput('line1')}
        {createInput('line2')}
        {createInput('city')}
        {createInput('postal_code')}
        {createInput('state')}
        {createInput('country', 'select')}
      </div>
    );
  }
}

FormAddressFragment.propTypes = {
  t: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape(CountryProp.propType))
    .isRequired,
  address: PropTypes.shape(AddressProp.propType).isRequired,
  onAddressChange: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  translationPrefix: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.shape(),
};

FormAddressFragment.defaultProps = {
  translationPrefix: 'address',
  disabled: false,
  style: {},
};

export default translate(FormAddressFragment);
