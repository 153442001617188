import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Request from '../../../services/request';
import FormValidator from '../../../services/formValidation';
import { compose } from '../../../services/utilityFunctions';
import translate from '../../../commonComponents/translate';
import withCSRF from '../../../commonComponents/withCSRF';
import FormInput from '../../../commonComponents/forms/FormInput';
import NavigationButtons from '../NavigationButtons';

class Organization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.organization,
      codePlaceholder: '',
      usingOther: false,
      awaitingSuggestion: false,
    };

    this.validationRules = {
      name: { required: true },
      code: {
        required: false,
        minlength: 2,
        remote: {
          async: false,
          url: '/trial/code/exists',
          type: 'post',
          data: { _csrf: props._csrf },
        },
      },
    };

    this.formId = 'organizationForm';

    this.fetchCodeSuggestion = this.fetchCodeSuggestion.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTeamSizeChange = this.handleTeamSizeChange.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
  }

  componentDidMount() {
    FormValidator.config();
  }

  fetchCodeSuggestion() {
    const { _csrf } = this.props;
    const { name } = this.state;

    this.setState({ awaitingSuggestion: true });
    new Request(_csrf)
      .post('/trial/code/suggestion', { _csrf, name })
      .done(({ data }) =>
        this.setState({ codePlaceholder: data, awaitingSuggestion: false }),
      )
      .fail(() => this.setState({ awaitingSuggestion: false }));
  }

  handleChange(field) {
    return value => this.setState({ [field]: value });
  }

  handleTeamSizeChange({ currentTarget }) {
    this.setState({ teamSize: currentTarget.value, usingOther: true });
  }

  submitHandler(isForward) {
    return evt => {
      evt.preventDefault();

      const form = $(`#${this.formId}`);
      form.validate({ rules: this.validationRules });

      const { name, teamSize, code, codePlaceholder } = this.state;
      if (form.valid()) {
        const organization = { name, teamSize, code: code || codePlaceholder };

        const cb = isForward ? this.props.onForward : this.props.onBack;
        cb({ organization });
      } else if (isForward) {
        document.querySelector('.has-danger').scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
      } else {
        this.props.onBack({});
      }
    };
  }

  createInput(field, type) {
    const { t } = this.props;
    return (
      <FormInput
        id={field}
        name={field}
        value={this.state[field]}
        onChange={this.handleChange(field)}
        label={t(`trial_organization_${field}`)}
        labelClassName="col-3"
        containerClassName="col-9"
        type={type || 'text'}
        groupProps={{ className: 'row' }}
        placeholder={field === 'code' ? this.state.codePlaceholder : ''}
        inputProps={{ onBlur: () => this.fetchCodeSuggestion() }}
        tooltip={
          field === 'code'
            ? {
                title: t('trial_organization_code'),
                content: t('trial_organization_code_tooltip'),
              }
            : null
        }
      />
    );
  }

  createTeamSizeButton(text, value) {
    const { teamSize } = this.state;
    const className = cx('trial-organization-teamSizeOption', {
      selected: value === teamSize,
    });

    return (
      <button
        type="button"
        className={className}
        onClick={() => this.setState({ teamSize: value, usingOther: false })}
      >
        {text}
      </button>
    );
  }

  render() {
    const { t } = this.props;
    const { teamSize, usingOther, awaitingSuggestion } = this.state;

    return (
      <div className="trial-info-container">
        <div className="trial-info-form">
          <div className="trial-info-form-wrapper">
            <div className="trial-info-form-content">
              <div>
                <h1>
                  {t('trial_organization_greeting_title1')}
                  <b>{t('trial_organization_greeting_title2')}</b>
                </h1>
              </div>
              <div className="trial-info-form-inputs">
                <form
                  className="m-form m-form--fit m-form--label-align-right trial-organization-form"
                  id={this.formId}
                >
                  {this.createInput('name')}
                  {this.createInput('code')}
                  <h4>{t('trial_organization_collaborate')}</h4>
                  <div className="trial-organization-teamSizeOptions">
                    {this.createTeamSizeButton('2-10', 10)}
                    {this.createTeamSizeButton('11-25', 25)}
                    {this.createTeamSizeButton('26-50', 50)}
                    {this.createTeamSizeButton('51-100', 100)}
                    {this.createTeamSizeButton('101-500', 500)}
                    <input
                      className="trial-organization-teamSizeOther"
                      type="number"
                      value={usingOther ? teamSize : ''}
                      placeholder={t('trial_organization_other')}
                      onChange={this.handleTeamSizeChange}
                    />
                  </div>
                  <NavigationButtons
                    onBack={this.submitHandler(false)}
                    onNext={this.submitHandler(true)}
                    disableForward={awaitingSuggestion}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="trial-demo-container">
          <img
            alt="platform demo"
            className="demo-image"
            src="/images/trial/trial_step_2.png"
          />
        </div>
      </div>
    );
  }
}

Organization.propTypes = {
  /** Translation function */
  t: PropTypes.func.isRequired,
  /** Anti cross-site forgery request token */
  _csrf: PropTypes.string.isRequired,
  /** Forward navigation event handler */
  onForward: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  /** Already saved info, in case we're navigating back and forth */
  organization: PropTypes.shape({
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    teamSize: PropTypes.number.isRequired,
  }),
};

Organization.defaultProps = {
  organization: {
    name: '',
    code: '',
    teamSize: 10,
  },
};

export default compose(
  translate,
  withCSRF,
)(Organization);
