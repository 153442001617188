import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import SignInLogo from './SignInLogo';

export default function SignInContainer({ theme, forgotPassword, children }) {
  const containerClasses = cx(
    'm-grid__item',
    'm-grid__item--fluid',
    'm-grid',
    'm-grid--hor',
    'm-login',
    'm-login--2',
    'm-login-2--skin-2',
    { 'm-login--signin': !forgotPassword },
    { 'm-login--forget-password': forgotPassword },
  );

  return (
    <div
      className={containerClasses}
      id="m_login"
      style={{ backgroundImage: `url("/dist/${theme}/assets/app/media/img/bg/bg-3.jpg")` }}
    >
      <div className="m-grid__item m-grid__item--fluid m-login__wrapper">
        <div className="m-login__container">
          <SignInLogo theme={theme} />
          {children}
        </div>
      </div>
    </div>
  );
}

SignInContainer.propTypes = {
  theme: PropTypes.string.isRequired,
  forgotPassword: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

SignInContainer.defaultProps = {
  forgotPassword: false,
};
