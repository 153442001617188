import React from 'react';
import PropTypes from 'prop-types';
import Notifications from '../../services/notifications';
import { compose } from '../../services/utilityFunctions';
import withCSRF from '../../commonComponents/withCSRF';
import translate from '../../commonComponents/translate';
import Request from '../../services/request';
import SuperAdmin from './steps/SuperAdmin';
import Info from './steps/Info';
import Organization from './steps/Organization';
import Invitations from './steps/Invitations';

class Trial extends React.Component {
  constructor(props) {
    super(props);
    this.state = { step: props.isSuperAdmin ? 0 : 1 };

    this.handleForwardNavigation = this.handleForwardNavigation.bind(this);
    this.handleBackNavigation = this.handleBackNavigation.bind(this);
    this.handleFinish = this.handleFinish.bind(this);
    this.submitTrialInfo = this.submitTrialInfo.bind(this);
  }

  handleForwardNavigation(data) {
    this.setState(({ step }) => ({ step: step + 1, ...data }));
  }

  handleBackNavigation(data) {
    this.setState(({ step }) => ({ step: step - 1, ...data }));
  }

  handleFinish(data) {
    this.setState({ ...data }, this.submitTrialInfo);
  }

  submitTrialInfo() {
    const { t, _csrf, isSuperAdmin, utmSource, utmMedium } = this.props;
    const {
      name: adminName,
      email,
      solutions,
      organizationName,
    } = this.state.info;

    const params = {
      adminName,
      email,
      organizationName,
      limitUsers: 25,
      solutions,
    };

    let request;
    if (isSuperAdmin) {
      const { tenant, type } = this.state.superAdmin;
      request = new Request(_csrf).post('/subscription/free', {
        ...params,
        tenant,
        type,
      });
    } else {
      request = new Request(_csrf).post('/trial', params);
    }

    this.setState({ postingForm: true });
    request
      .done(() => {
        if (isSuperAdmin) {
          window.location.href = '/';
        } else if (utmSource && utmMedium) {
          window.location.href = `/trial/created?utm_source=${utmSource}-finished&utm_medium=${utmMedium}`;
        } else {
          window.location.href = '/trial/created';
        }
      })
      .fail(err => {
        let error = t('error_trialCreation');
        if (err && err.responseJSON) {
          error = err.responseJSON.message || t(err.responseJSON.error);
        }
        this.setState({ postingForm: false });
        Notifications.showNotificationError(t('error'), error);
      });
  }

  render() {
    const { isSuperAdmin, tenants, solutions } = this.props;
    const { step } = this.state;

    const StepComponent = (() => {
      switch (step) {
        case 0:
          return SuperAdmin;
        case 1:
          return Info;
        case 2:
          return Organization;
        case 3:
          return Invitations;
        default:
          return Info;
      }
    })();

    return (
      <StepComponent
        {...this.state}
        isSuperAdmin={isSuperAdmin}
        tenants={tenants}
        solutions={solutions}
        onForward={this.handleForwardNavigation}
        onBack={this.handleBackNavigation}
        onFinish={this.handleFinish}
      />
    );
  }
}

Trial.propTypes = {
  isSuperAdmin: PropTypes.bool.isRequired,
  tenants: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  /** Solutions available for the user */
  solutions: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  utmSource: PropTypes.string,
  utmMedium: PropTypes.string,
};

Trial.defaultProps = {
  tenants: [],
  solutions: [],
  utmSource: '',
  utmMedium: '',
};

export default compose(
  translate,
  withCSRF,
)(Trial);
