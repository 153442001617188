import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import translate from '../../commonComponents/translate';

/**
 * Error page, used to either display errors thrown on the server
 * or caught by the ErrorBoundary on the site's main component
 */
function Error({ t, status, error, isProduction }) {
  return (
    <div
      className={
        'm-grid__item m-grid__item--fluid ' +
        'm-grid m-grid--ver-desktop m-grid--desktop m-container m-container--responsive ' +
        'm-container--xxl m-page__container m-body'
      }
    >
      <div id="m_aside_left" className="m-grid__item m-aside-left " />
      <div className="m-grid__item m-grid__item--fluid m-wrapper">
        <div className="m-content">
          <div className="row">
            <div className={`col-md-12 page-${status}`}>
              <div className="number font-red">{status}</div>
              <div className="details">
                <h3>{t(`${status}error-title`)}</h3>
                <p>
                  {t(`${status}error-text-1`)}
                  <br />
                  <Link to="/">{t(`${status}error-link`)}</Link>
                </p>
              </div>
            </div>
          </div>
          {!isProduction && (
            <div className="row">
              <div className="col-md-12">
                <p>{error.message}</p>
                <p style={{ whiteSpace: 'pre-wrap' }}>{error.stack}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

Error.propTypes = {
  /** Translation function */
  t: PropTypes.func.isRequired,
  /** Error status code */
  status: PropTypes.number,
  /** Error info to display, the error message and a stack trace */
  error: PropTypes.shape({
    message: PropTypes.string,
    stack: PropTypes.string,
  }),
  /** Whether we are in a production environment, which restricts detailed message displayed */
  isProduction: PropTypes.bool.isRequired,
};

Error.defaultProps = {
  status: 404,
  error: { message: '', stack: '' },
};

export default translate(Error);
