import React from 'react';
import PropTypes from 'prop-types';
import { renderToStaticMarkup } from 'react-dom/server';
import Request from '../../../services/request';
import SweetAlert from '../../../services/sweetAlert';
import Notifications from '../../../services/notifications';
import * as Utils from '../../../services/utilityFunctions';
import translate from '../../../commonComponents/translate';
import withCSRF from '../../../commonComponents/withCSRF';
import List from '../../../commonComponents/lists/List';
import BooleanColumn from '../../../commonComponents/lists/columns/BooleanColumn';
import ActionsColumn from './columns/ActionsColumn';

function SubscriptionList({ t, query, _csrf }) {
  const tableId = 'subscriptionList';

  const cancelSubscription = reason => {
    try {
      new Request(_csrf)
        .delete('/subscription', { reason })
        .done(() => {
          SweetAlert.success({
            title: t('subscription_cancel_successful_title'),
            text: t('subscription_cancel_successful_msg'),
          }).then(() => {
            window.location.href = '/';
          });
        })
        .fail(err => {
          let error = t('error_subscriptionCancel');
          if (err && err.responseJSON) {
            error = err.responseJSON.message || t(err.responseJSON.error);
          }
          Notifications.showNotificationError(t('error'), error);
        });
    } catch (err) {
      Notifications.showNotificationError(t('error'), err);
    }
  };

  const onClickCancelSubscription = () => {
    SweetAlert.warning({
      title: t('subscription_cancel_title'),
      text: t('subscription_cancel_msg'),
      input: 'textarea',
      inputPlaceholder: t('subscription_cancel_reason_placeholder'),
      inputValidator: value =>
        !value && t('subscription_cancel_reason_required'),
      width: '50%',
      position: 'top',
      showConfirmButton: true,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonText: t('subscription_cancel_button'),
      cancelButtonText: t('cancel'),
      confirmButtonClass: 'btn btn-danger m-btn m-btn--wide',
      preConfirm: () => {},
    }).then(result => {
      if (result.value) {
        cancelSubscription(result.value);
      }
    });
  };

  function handleListInitialization() {
    $(`#${tableId}`).on('click', '.cancel-button', onClickCancelSubscription);
  }

  const columns = [
    {
      field: 'type',
      title: t('subscription_type'),
      textAlign: 'center',
      sortable: false,
      width: 100,
    },
    {
      field: 'expirationDate',
      title: t('subscription_expirationDate'),
      textAlign: 'center',
      sortable: false,
      width: 100,
    },
    {
      field: 'isActive',
      title: t('subscription_isActive'),
      template: ({ isActive, awaitingValidation }) =>
        renderToStaticMarkup(
          <BooleanColumn value={isActive && !awaitingValidation} />,
        ),
      textAlign: 'center',
      sortable: false,
      width: 100,
    },
    {
      field: 'Actions',
      width: 100,
      textAlign: 'center',
      title: t('actions'),
      sortable: false,
      overflow: 'visible',
      template: subscription =>
        renderToStaticMarkup(
          <ActionsColumn t={t} subscription={subscription} />,
        ),
    },
  ];

  const dataTransform = subscription => ({
    ...subscription,
    expirationDate: Utils.displayDate(subscription.expirationDate) || '- -',
    type: subscription.type ? t(`subscription_type_${subscription.type}`) : '',
    originalType: subscription.type,
  });

  return (
    <List
      id={tableId}
      query={query}
      dataSourceURL="/subscription/list"
      columns={columns}
      dataTransform={dataTransform}
      onInit={handleListInitialization}
    />
  );
}

SubscriptionList.propTypes = {
  t: PropTypes.func.isRequired,
  _csrf: PropTypes.string.isRequired,
  query: PropTypes.shape({}).isRequired,
};

export default Utils.compose(
  translate,
  withCSRF,
)(SubscriptionList);
