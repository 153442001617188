import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../../../commonComponents/translate';

function ActionsColumn({ t, pdf }) {
  return pdf ? (
    <a
      href={pdf}
      className="btn m-btn m-btn--hover-primary m-btn--icon m-btn--icon-only m-btn--pill pdf-button"
      target="_blank"
      rel="noopener noreferrer"
      title={t('invoice_pdf_download')}
    >
      <i className="m--font-danger la la-file-pdf-o" />
    </a>
  ) : (
    <span />
  );
}

ActionsColumn.propTypes = {
  /** Translation function */
  t: PropTypes.func.isRequired,
  /** Invoice pdf URL */
  pdf: PropTypes.string,
};

ActionsColumn.defaultProps = {
  pdf: '',
};

export default translate(ActionsColumn);
