import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../../../../commonComponents/translate';
import ProductProp from '../../../propTypes/ProductProp';
import { isInCart } from '../../../../../services/utilityFunctions';
import ModuleItem from './ModuleItem';
import CartProp from '../../../propTypes/CartProp';

function Modules({ t, cart, modules, onAdd, onRemove, isDefault }) {
  if (modules.length < 1) {
    return null;
  }

  return (
    <div className="subscription-plan-modules">
      <h3>{t('subscriptionCreate_modules')}</h3>
      {modules.map(m => (
        <ModuleItem
          t={t}
          key={m._id}
          onAdd={onAdd}
          onRemove={onRemove}
          product={m}
          isInCart={isInCart(cart, m)}
          isDefault={isDefault}
        />
      ))}
    </div>
  );
}

Modules.propTypes = {
  t: PropTypes.func.isRequired,
  cart: PropTypes.shape(CartProp.propType).isRequired,
  modules: PropTypes.arrayOf(PropTypes.shape(ProductProp.propType)).isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isDefault: PropTypes.bool.isRequired,
};

export default translate(Modules);
