import PropTypes from 'prop-types';

const propType = {
  url: PropTypes.string.isRequired,
  thumb: PropTypes.string.isRequired,
  medium: PropTypes.string.isRequired,
};

const defaultProp = {
  url: '/images/no_user.png',
  thumb: '/images/no_user.png',
  medium: '/images/no_user.png',
};

export default { propType, defaultProp };
