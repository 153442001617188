import React from 'react';
import PropTypes from 'prop-types';

/** Form Header, mainly the title */
export default function FormHeader({ title }) {
  return (
    <div className="form-group m-form__group row">
      <div className="col-10 ml-auto">
        <h3 className="m-form__section">{title}</h3>
      </div>
    </div>
  );
}

FormHeader.propTypes = {
  /** Title to display */
  title: PropTypes.string.isRequired,
};
