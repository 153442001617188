import PropTypes from 'prop-types';

const propType = {
  name: PropTypes.string,
  phone: PropTypes.string,
  address: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    line1: PropTypes.string,
    line2: PropTypes.string,
    postal_code: PropTypes.string,
    state: PropTypes.string,
  }),
};

const defaultProp = {
  name: '',
  phone: '',
  address: {
    city: '',
    country: '',
    line1: '',
    line2: '',
    postal_code: '',
    state: '',
  },
};

export default { propType, defaultProp };
