import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../../../commonComponents/translate';

function FormActions({ t, submitHandler }) {
  return (
    <div className="m-portlet__foot m-portlet__foot--fit">
      <div className="m-form__actions">
        <div className="row">
          <div className="col-2" />
          <div className="col-7">
            <button
              type="submit"
              className="btn btn-accent m-btn m-btn--air m-btn--custom loader-button"
              onClick={submitHandler}
            >
              {t('save')}
            </button>
            &nbsp;&nbsp;
            <button
              type="button"
              className="btn btn-secondary m-btn m-btn--air m-btn--custom"
              id="cancel_button"
            >
              {t('cancel')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

FormActions.propTypes = {
  t: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
};

export default translate(FormActions);
