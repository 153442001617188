import React from 'react';
import PropTypes from 'prop-types';
import CartProp from '../../../propTypes/CartProp';
import ProductProp from '../../../propTypes/ProductProp';
import ProductItem from './ProductItem';
import { isInCart } from '../../../../../services/utilityFunctions';

export default class ProductCategory extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickAddItem = this.handleClickAddItem.bind(this);
    this.handleClickRemoveItem = this.handleClickRemoveItem.bind(this);
  }

  handleClickAddItem({ product, quantity }) {
    this.props.onClickAddItem({ product, quantity });
  }

  handleClickRemoveItem(product) {
    this.props.onClickRemoveItem(product);
  }

  render() {
    const {
      name,
      products,
      cart,
      isSubscriptionEdit,
      onOpenDescriptionModal,
      interval,
    } = this.props;

    return (
      <div className="row">
        <div className="col-12 subscription-top-trophies-container">
          <h3 className="subscription-options-title">{name}</h3>
          <div className="product-scrolling-wrapper">
            {products.map(ts => (
              <div className="scroll-item m--margin-10" key={ts._id}>
                <ProductItem
                  product={ts}
                  isInCart={isInCart(cart, ts)}
                  isSubscriptionEdit={isSubscriptionEdit}
                  onClickAdd={this.handleClickAddItem}
                  onClickRemove={this.handleClickRemoveItem}
                  onOpenDescriptionModal={onOpenDescriptionModal}
                  interval={interval}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

ProductCategory.propTypes = {
  name: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape(ProductProp.propType)).isRequired,
  cart: PropTypes.shape(CartProp.propType).isRequired,
  onClickAddItem: PropTypes.func.isRequired,
  onClickRemoveItem: PropTypes.func.isRequired,
  onOpenDescriptionModal: PropTypes.func.isRequired,
  isSubscriptionEdit: PropTypes.bool,
  interval: PropTypes.string.isRequired,
};

ProductCategory.defaultProps = {
  isSubscriptionEdit: true,
};
