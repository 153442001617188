import React from 'react';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CreditCardForm2 from './CreditCardForm2';
import Modal from '../../../commonComponents/modal/Modal';
import ModalHeader from '../../../commonComponents/modal/ModalHeader';
import translate from '../../../commonComponents/translate';
import { compose } from '../../../services/utilityFunctions';
import injectStripe from '../../../commonComponents/injectStripe';

function CreditCardModal({ t, stripe, postingForm, onFinish, onClose }) {
  const stripePromise = loadStripe(stripe.key);
  return (
    <Modal>
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <ModalHeader onClose={onClose} title={t('add_card_payment')} />
          <div className="modal-body">
            <div className="subscription-payment-container">
              <div className="payment-cc-form">
                <Elements stripe={stripePromise}>
                  <CreditCardForm2
                    finishButtonText={t('add_card')}
                    onFinish={onFinish}
                    postingForm={postingForm}
                  />
                </Elements>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

CreditCardModal.propTypes = {
  t: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  postingForm: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  stripe: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }).isRequired,
};

export default compose(
  translate,
  injectStripe,
)(CreditCardModal);
