import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

export default function BootstrapSwitch(props) {
  const { id, name, label, className, textSelected, textOn, textOff, colorOn, colorOff, width, onSwitchChange } = props;
  useEffect(() => {
    const element = $(`#${id}`);
    element.bootstrapSwitch();
    element.bootstrapSwitch('state', textSelected === textOn, true);
    element.on('switchChange.bootstrapSwitch', (event, state) => {
      onSwitchChange(state, state ? textOn : textOff);
    });
  }, []);

  return (
    <div className={`form-group m-form__group row ${className}`}>
      <span className="col-lg-12 col-sm-12">
      {label}
      </span>
      <label htmlFor={id} className="col-form-label col-lg-12 col-sm-12">
        <span className="m-bootstrap-switch m-bootstrap-switch--pill">
          <input
            id={id}
            name={name}
            type="checkbox"
            className="col-lg-12 col-sm-12"
            data-on-text={textOn}
            data-off-text={textOff}
            data-on-color={colorOn}
            data-off-color={colorOff}
            data-handle-width={width}
            onChange={() => {}}
          />
        </span>
      </label>
    </div>
  );
}

BootstrapSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  textSelected: PropTypes.string.isRequired,
  textOn: PropTypes.string.isRequired,
  textOff: PropTypes.string.isRequired,
  colorOn: PropTypes.string,
  colorOff: PropTypes.string,
  width: PropTypes.string,
  onSwitchChange: PropTypes.func.isRequired,
};

BootstrapSwitch.defaultProps = {
  name: null,
  className: '',
  colorOn: 'brand',
  colorOff: 'brand',
  width: '70',
};
