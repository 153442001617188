import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Request from '../../services/request';
import { compose } from '../../services/utilityFunctions';
import translate from '../../commonComponents/translate';
import withCSRF from '../../commonComponents/withCSRF';
import Form from '../../commonComponents/forms/Form';
import FormInput from '../../commonComponents/forms/FormInput';
import OrganizationModal from './modal/OrganizationModal';

/**
 * Component wrapping the forgot password form on the SignIn page
 */
class ForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailRecover: '',
      organizationIdentifier: '',
      organizationData: [],
      postingForm: false,
    };

    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(field) {
    return value => this.setState({ [field]: value });
  }

  submitForm() {
    const { _csrf, displayAlert, alertTypes } = this.props;
    const { emailRecover, organizationIdentifier } = this.state;

    this.setState({ postingForm: true });

    const form = $('#forgotPasswordForm');
    new Request(_csrf)
      .post('/signin/forgotPassword', {
        email_recover: emailRecover,
        organizationIdentifier,
      })
      .done(() => {
        form.validate().resetForm();

        this.setState({
          showOrganizationModal: false,
          postingForm: false,
          organizationData: [],
        });

        displayAlert(
          alertTypes.SUCCESS,
          'Cool! Password recovery instruction has been sent to your email.',
        );
      })
      .fail(err => {
        form.validate().resetForm();
        const { responseJSON: error } = err;

        if (error.error === 'ERROR_MULTIPLE_ORGANIZATIONS_DETECTED') {
          this.setState({
            showOrganizationModal: true,
            organizationData: error.data,
            organizationIdentifier: error.data[0].identifierCode,
            postingForm: false,
          });
        }

        displayAlert(
          alertTypes.DANGER,
          'An error has occurred while processing your request.',
        );
      });
  }

  render() {
    const { t, displaySignInForm } = this.props;

    const {
      emailRecover,
      organizationIdentifier,
      organizationData,
      showOrganizationModal,
      postingForm,
    } = this.state;

    console.log(organizationData);

    const submitButtonClasses = cx(
      'btn',
      'btn-primary',
      'm-btn',
      'm-btn--pill',
      'm-btn--custom',
      'm-btn--air',
      'm-login__btn',
      'm-login__btn--primary',
      'loader-button',
    );

    return (
      <div className="m-login__forget-password">
        <div className="m-login__head">
          <h3 className="m-login__title">{t('login_forgottenPassword')}</h3>
          <div className="m-login__desc">{t('login_enterEmail')}</div>
        </div>
        <Form
          validationRules={{
            email_recover: {
              required: true,
              email: true,
            },
          }}
          className="m-login__form m-form"
          id="forgotPasswordForm"
          submitMethod={this.submitForm}
        >
          {submitHandler => (
            <React.Fragment>
              {showOrganizationModal && (
                <OrganizationModal
                  organizationIdentifier={organizationIdentifier}
                  handleChange={this.handleChange}
                  onSubmit={this.submitForm}
                  postingForm={postingForm}
                  organizationData={organizationData}
                  buttonCaption={t('recoverPassword_organizationPicker')}
                />
              )}
              <FormInput
                type="email"
                placeholder={t('login_forgotPassword_email')}
                name="email_recover"
                id="m_email"
                value={emailRecover}
                onChange={this.handleChange('emailRecover')}
                inputProps={{ autoComplete: 'off' }}
              />
              <div className="m-login__form-action">
                <button
                  type="submit"
                  id="m_login_forget_password_submit"
                  className={submitButtonClasses}
                  onClick={submitHandler}
                >
                  {t('login_request')}
                </button>
                &nbsp;&nbsp;
                <button
                  type="button"
                  id="m_login_forget_password_cancel"
                  className="btn btn-outline-primary m-btn m-btn--pill m-btn--custom
                          m-login__btn"
                  onClick={displaySignInForm}
                >
                  {t('cancel')}
                </button>
              </div>
            </React.Fragment>
          )}
        </Form>
      </div>
    );
  }
}

ForgotPasswordForm.propTypes = {
  /** translation function, i18n.__ on the browser, response.__ on the server */
  t: PropTypes.func.isRequired,
  /** _csrf token */
  _csrf: PropTypes.string.isRequired,
  /** Function used to display alerts on the parent page */
  displayAlert: PropTypes.func.isRequired,
  /** Function to call to dismiss this form and replace it with SignInForm */
  displaySignInForm: PropTypes.func.isRequired,
  /** Enum representing the possible alert types */
  alertTypes: PropTypes.shape({
    SUCCESS: PropTypes.string.isRequired,
    DANGER: PropTypes.string.isRequired,
  }).isRequired,
};

export default compose(
  translate,
  withCSRF,
)(ForgotPasswordForm);
