import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from '../../services/utilityFunctions';

/**
 * Basic mDataTable implementation,
 * see https://keenthemes.com/metronic/?page=docs&section=datatable#docs
 */
export default class List extends React.Component {
  constructor(props) {
    super(props);

    this.updateQuery = debounce(this.updateQuery.bind(this), 300);
  }

  componentDidMount() {
    const {
      id,
      dataSourceURL,
      dataSourceParams,
      dataTransform,
      columns,
      onInit,
      onRowClick,
    } = this.props;

    const config = {
      data: {
        type: 'remote',
        source: {
          read: {
            url: dataSourceURL,
            method: 'GET',
            map: raw => {
              let data = raw;
              if (raw.data) {
                data = dataTransform ? raw.data.map(dataTransform) : raw.data;
              }
              return data;
            },
            params: dataSourceParams,
          },
        },
        pageSize: 20,
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
      },

      layout: {
        theme: 'default',
        class: '',
        scroll: false,
        footer: false,
      },

      sortable: true,

      pagination: true,
      pagingType: 'full_numbers',
      columns,
      rows: onRowClick
        ? {
            beforeTemplate: (row, data) =>
              row.addClass('clickable-row').on('click', () => onRowClick(data)),
          }
        : undefined,
    };

    const tableElement = $(`#${id}`);
    const table = tableElement.mDatatable(config);

    if (onInit) {
      table.on('m-datatable--on-init', onInit);
    }
  }

  componentDidUpdate(prevProps) {
    const { query } = this.props;
    for (const key of Object.keys(query)) {
      if (query[key] !== prevProps.query[key]) {
        this.updateQuery(query);
        break;
      }
    }
  }

  componentWillUnmount() {
    $(`#${this.props.id}`)
      .mDatatable()
      .destroy();
  }

  updateQuery(query) {
    const table = $(`#${this.props.id}`).mDatatable();
    const newQuery = { ...table.getDataSourceQuery(), ...query };
    table.setDataSourceParam('query', newQuery);
    const newPagination = { ...table.getDataSourceParam('pagination'), page: 1 };
    table.setDataSourceParam('pagination', newPagination);
    table.reload();
  }

  render() {
    return <div className="m_datatable" id={this.props.id} />;
  }
}

List.propTypes = {
  /** DataTable div container id */
  id: PropTypes.string.isRequired,
  /** Server endpoint for table data */
  dataSourceURL: PropTypes.string.isRequired,
  /** Data source constant params, such as a userId for a tabled displayed in the profile page */
  dataSourceParams: PropTypes.shape(),
  /** Query configuration object, where we define the filters for the lists */
  query: PropTypes.shape().isRequired,
  /** Data transformation function, typically uses utilityFunctions to convert dates/numbers, etc */
  dataTransform: PropTypes.func,
  /** Column definitions, passed directly to jQuery component, see it's docs */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      textAlign: PropTypes.string,
      width: PropTypes.number,
      sortable: PropTypes.bool,
      overflow: PropTypes.string,
      template: PropTypes.func,
    }),
  ).isRequired,
  /**
   * initialization event handler so we can do extra work, like add events to the
   * components rendered as a string
   */
  onInit: PropTypes.func,
  /**
   * Row click event handler, row data is passed as an argument
   */
  onRowClick: PropTypes.func,
};

List.defaultProps = {
  dataSourceParams: null,
  dataTransform: null,
  onInit: null,
  onRowClick: null,
};
