import React, { useState } from 'react';
import { IbanElement, useElements, useStripe } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Form from '../../../commonComponents/forms/Form';
import translate from '../../../commonComponents/translate';
import withCSRF from '../../../commonComponents/withCSRF';
import PaymentMethod from '../propTypes/PaymentMethod';
import Request from '../../../services/request';
import { compose } from '../../../services/utilityFunctions';
import Notifications from '../../../services/notifications';
import StripeService from '../../../services/stripeService';
import CountryProp from '../../../commonComponents/forms/Address/CountryProp';

const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ['SEPA'],
  iconStyle: 'default',
  style: {
    base: {
      iconColor: '#222222',
      color: '#222222',
      fontSize: '16px',
      fontWeight: 100,
      '::placeholder': {
        color: '#aab7c4',
      },
      ':-webkit-autofill': {
        color: '--primary',
      },
    },
    invalid: {
      color: '#f4516c',
      iconColor: '#f4516c',
      ':-webkit-autofill': {
        color: '#f4516c',
      },
    },
  },
};

function SEPAForm({ t, _csrf, paymentMethod, SEPACountries, billingCountry, onFinish, postingForm }) {
  const stripe = useStripe();
  const elements = useElements();

  const [state, setState] = useState({
    accountName: paymentMethod.accountName,
    accountEmail: paymentMethod.accountEmail,
    accountIBAN: paymentMethod.accountIBAN,
  });

  const [stateForm, setStateForm] = useState({
    postingForm: false,
  });

  const [stateCountry, setStateCountry] = useState({
    billingCountry: billingCountry || 'LU',
  });

  const validationRules = {
    accountName: { required: true },
    accountEmail: { required: true },
  };
  // const handleChange = field => value =>
  //   setState(prevState => ({ ...prevState, [field]: value }));

  const handleChange = field => ({ target }) => setState(prevState => ({ ...prevState, [field]: target.value }));

  const handleChangeCountry = ({ target }) => {
    setStateCountry({ billingCountry: target.value });
  };

  const getStripeClientSecret = () => new Promise((resolve, reject) => {
    new Request(_csrf)
      .post('/payments/methods/sepa/authorization')
      .done(({ data }) => {
        console.log('result.data: ', data);
        const { client_secret: clientSecret } = data;
        resolve(clientSecret);
      })
      .fail(err => {
        reject(err);
      });
  });

  const attachPaymentMethod = paymentMethodId => new Promise((resolve, reject) => {
    new Request(_csrf)
      .post('/payments/methods/attach', { paymentMethodId })
      .done(({ data: customer }) => {
        console.log('attachPaymentMethod.customer: ', customer);
        resolve(customer);
      })
      .fail(err => {
        reject(err);
      });
  });

  const handleSubmitFuture = async () => {
    if (!stripe || !elements) {
      return;
    }

    const { accountName, accountEmail } = state;
    const accountIBAN = elements.getElement(IbanElement);

    try {
      setStateForm({ postingForm: true });
      const clientSecret = await getStripeClientSecret();
      const resultData = await stripe.confirmSepaDebitSetup(clientSecret, {
        payment_method: {
          sepa_debit: accountIBAN,
          billing_details: {
            name: accountName,
            email: accountEmail,
          },
        },
      });
      if (resultData.error) {
        console.log(resultData.error.message);
        setStateForm({ postingForm: false });
        Notifications.showNotificationError(t('error'), t(StripeService.handleErrors(resultData.error)));
      } else {
        const { setupIntent } = resultData;
        console.log('**** confirmSepaDebitSetup.setupIntent: ', setupIntent);
        const { payment_method: paymentMethodId } = setupIntent;
        const pm = await attachPaymentMethod(paymentMethodId);
        setStateForm({ postingForm: false });
        console.log('**** handleSubmitFuture.PaymentMethod: ', pm);
        onFinish(pm);
      }
    } catch (err) {
      setStateForm({ postingForm: false });
      Notifications.showNotificationError(t('error'), t(err));
      // setError(err);
    }
  };

  // const handleSubmit = async () => {
  //   if (!stripe || !elements) {
  //     return;
  //   }
  //
  //   const { accountName, accountEmail } = state;
  //   const accountIBAN = elements.getElement(IbanElement);
  //   try {
  //     setStateForm({ postingForm: true });
  //     const { error, paymentMethod: pm } = await stripe.createPaymentMethod({
  //       type: 'sepa_debit',
  //       sepa_debit: accountIBAN,
  //       billing_details: {
  //         name: accountName,
  //         email: accountEmail,
  //       },
  //     });
  //     if (error) {
  //       console.log(error.message);
  //       setStateForm({ postingForm: false });
  //       Notifications.showNotificationError(t('error'), t(StripeService.handleErrors(error)));
  //     } else {
  //       const pm2 = await attachPaymentMethod(pm.id);
  //       setStateForm({ postingForm: false });
  //       console.log('**** confirmSepaDebitSetup.PaymentMethod: ', pm2);
  //       onFinish(pm2);
  //     }
  //   } catch (err) {
  //     setStateForm({ postingForm: false });
  //     Notifications.showNotificationError(t('error'), t(err));
  //     // setError(err);
  //   }
  // };

  const submitButtonClass = cx(
    'btn',
    'm-btn--pill',
    'm-btn--air',
    'btn-sm',
    'm-btn',
    'm-btn--custom',
    'btn-primary',
    'payment-finish-button',
    { 'm-loader': postingForm || stateForm.postingForm },
    { 'm-loader--right': postingForm || stateForm.postingForm },
    { 'm-loader--light': postingForm || stateForm.postingForm },
  );

  const renderSelectOption = item => (
    <option key={item.id} value={item.id}>
      {item.text}
    </option>
  );

  return (
    <Form
      className="payment-form"
      id="SEPAForm"
      validationRules={validationRules}
      submitMethod={handleSubmitFuture}
    >
      {submitHandler => (
        <>
          <div className="payment-line">
            <div className="payment-column">
              <div className="payment-input">
                <label htmlFor="accountName">
                  {t('sepa_account_holder_name')}
                  <input
                    name="accountName"
                    required
                    value={state.accountName}
                    onChange={handleChange('accountName')}
                  />
                </label>
              </div>
            </div>

            <div className="payment-column">
              <div className="payment-input">
                <label htmlFor="accountEmail">
                  {t('sepa_account_holder_email')}
                  <input
                    name="accountEmail"
                    type="email"
                    required
                    value={state.accountEmail}
                    onChange={handleChange('accountEmail')}
                  />
                </label>
              </div>
            </div>
          </div>

          <div className="payment-line">
            <div className="payment-column">
              <div className="payment-input">
                <label htmlFor="bankCountry">
                  {t('sepa_account_country')}
                  <select
                    id="bankCountry"
                    name="bankCountry"
                    onChange={handleChangeCountry}
                    defaultValue={billingCountry}
                    multiple={false}
                  >
                    {SEPACountries.map(c => ({
                      id: c.code,
                      text: `${c.flagEmoji} ${c.name}`,
                    })).map(renderSelectOption)}
                  </select>
                </label>
              </div>
            </div>
          </div>

          <div className="payment-line">
            <div className="payment-column">
              <div className="payment-input">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="accountIBAN">
                  {t('IBAN')}
                  <IbanElement
                    name="accountIBAN"
                    options={{ ...IBAN_ELEMENT_OPTIONS, placeholderCountry: stateCountry.billingCountry }}
                  />
                </label>
              </div>
              {/* Display mandate acceptance text. */}
              <div className="mandate-acceptance">
                {t('sepa_mandate_acceptance')}
              </div>
            </div>
          </div>
          <button
            type="submit"
            onClick={submitHandler}
            disabled={postingForm}
            className={submitButtonClass}
          >
            {t('add_sepa_payment')}
          </button>
        </>
      )}
    </Form>
  );
}

SEPAForm.propTypes = {
  t: PropTypes.func.isRequired,
  _csrf: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
  postingForm: PropTypes.bool.isRequired,
  billingCountry: PropTypes.string,
  paymentMethod: PropTypes.shape(PaymentMethod.propType),
  SEPACountries: PropTypes.arrayOf(PropTypes.shape(CountryProp.propType)).isRequired,
};

SEPAForm.defaultProps = {
  billingCountry: null,
  paymentMethod: {
    accountName: '',
    accountEmail: '',
    accountIBAN: '',
  },
};

export default compose(
  translate,
  withCSRF,
)(SEPAForm);
