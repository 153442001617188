import { DISCOUNT_TYPE } from './utilityFunctions';

const getPriceForUsers = (prices = [], users = 0) => {
  const priceInfo = prices.find(p => {
    if (p.from <= users) {
      if (!p.upTo || p.upTo >= users) {
        return true;
      }
    }
    return false;
  }) || { unitAmount: 0 };

  const isFlat = !!priceInfo.flatAmount;
  return {
    price: isFlat ? priceInfo.flatAmount : priceInfo.unitAmount,
    isFlat,
  };
};

const applyDiscount = (total, discounts) => {
  let result = total;
  const discountApplications = discounts.map(discount => {
    let applied = false;
    const { amountOff, percentOff } = discount;

    const minPrice =
      discount.minPrice === null || discount.minPrice === undefined
        ? 0
        : discount.minPrice;
    const maxPrice =
      discount.maxPrice === null || discount.maxPrice === undefined
        ? Number.MAX_SAFE_INTEGER
        : discount.maxPrice;

    if (total >= minPrice && total <= maxPrice) {
      applied = true;
      if (amountOff) {
        result -= discount.amountOff;
      } else if (percentOff) {
        // Make sure we're not using money amount below cent
        result -= Math.round(total * (percentOff / 100));
      }
    }
    return { ...discount, applied };
  });

  if (result < 0) {
    result = 0;
  }
  return { result, discountApplications };
};

function calcPlansTotal(cart, plans, plansAreFree) {
  const { plans: cartPlans, limitUsers } = cart;
  return cartPlans.reduce((acc, plan) => {
    let planInfo = plans.find(p => p._id === plan._id);
    if (!planInfo) {
      // eslint-disable-next-line prefer-destructuring
      planInfo = plans[0];
    }
    const { price, isFlat } = getPriceForUsers(planInfo.prices, limitUsers);
    const priceInfo = plansAreFree ? 0 : price;
    const planPrice = isFlat ? priceInfo : limitUsers * priceInfo;
    return acc + planPrice;
  }, 0);
}

function calcSubscriptionsTotal(cart, plansAreFree) {
  const { subscriptions, limitUsers } = cart;
  return subscriptions.reduce((acc, { product }) => {
    let price = 0;
    if (!(plansAreFree && product.isFreeTrial)) {
      price = limitUsers * product.price;
    }
    return acc + price;
  }, 0);
}

const splitDiscounts = discounts =>
  discounts.reduce(
    ({ subs, prods }, discount) => {
      if (discount.type === DISCOUNT_TYPE.SUBSCRIPTION) {
        return { subs: subs.concat(discount), prods };
      }
      return { subs, prods: prods.concat(discount) };
    },
    { subs: [], prods: [] },
  );

function vatForCountry(taxInfo = {}, country = '') {
  const { europe = [], rules = { LU: 0, EUROPE: 0, OTHERS: 0 } } = taxInfo;
  let countryVat;
  if (country.toUpperCase() === 'LU') {
    countryVat = rules.LU;
  } else if (europe.includes(country)) {
    countryVat = rules.EUROPE;
  } else {
    countryVat = rules.OTHERS;
  }
  return countryVat / 100;
}

const calcTax = (total, taxInfo, country) =>
  Math.round(total * vatForCountry(taxInfo, country));

const calcMonthlyTotal = (cart, plans, plansAreFree) =>
  applyDiscount(
    calcPlansTotal(cart, plans, plansAreFree) +
      calcSubscriptionsTotal(cart, plansAreFree),
    splitDiscounts(cart.discounts).subs,
  ).result;

const calcMonthlyPostTax = (cart, plans, plansAreFree, taxInfo, country) => {
  const preTax = calcMonthlyTotal(cart, plans, plansAreFree);
  const tax = calcTax(preTax, taxInfo, country);
  return preTax + tax;
};

const splitProducts = (cart, cartProdDiscounts, categories) => {
  const { products: cartProds, packs: cartPacks } = cart;

  return cartProds.concat(cartPacks).reduce(
    (acc, productInfo) => {
      const { _id: productId } = productInfo.product;

      for (const discount of cartProdDiscounts) {
        const hasProducts = discount.products.length !== 0;
        const hasProductCategories = discount.productCategories.length !== 0;

        if (!hasProducts && !hasProductCategories) {
          return {
            discounted: acc.discounted.concat(productInfo),
            other: acc.other,
          };
        }
        if (hasProducts && discount.products.includes(productId)) {
          return {
            discounted: acc.discounted.concat(productInfo),
            other: acc.other,
          };
        }
        if (hasProductCategories) {
          for (const categoryId of discount.productCategories) {
            // in case the product is a pack
            if (categoryId === productId) {
              return {
                discounted: acc.discounted.concat(productInfo),
                other: acc.other,
              };
            }
            const categoryProductIds = (
              categories.find(c => c._id === categoryId) || { products: [] }
            ).products;
            if (categoryProductIds.some(p => p._id === productId)) {
              return {
                discounted: acc.discounted.concat(productInfo),
                other: acc.other,
              };
            }
          }
        }
      }
      return {
        discounted: acc.discounted,
        other: acc.other.concat(productInfo),
      };
    },
    { discounted: [], other: [] },
  );
};

function sumCart(acc, { product, quantity }) {
  const price = quantity * product.price;
  return acc + price;
}

const calcCurrentTotal = (cart, categories) => {
  const { prods: cartProdDiscounts } = splitDiscounts(cart.discounts);
  const { discounted, other } = splitProducts(
    cart,
    cartProdDiscounts,
    categories,
  );

  const discountedTotal = discounted.reduce(sumCart, 0);
  const otherTotal = other.reduce(sumCart, 0);

  return applyDiscount(discountedTotal, cartProdDiscounts).result + otherTotal;
};

const calcCurrentPostTax = (cart, categories, taxInfo, country) => {
  const preTax = calcCurrentTotal(cart, categories);
  const tax = calcTax(preTax, taxInfo, country);
  return preTax + tax;
};

const markDiscountApplication = (cart, categories, plans, plansAreFree) =>
  cart.discounts.map(discount => {
    if (discount.type === DISCOUNT_TYPE.SUBSCRIPTION) {
      const { discountApplications } = applyDiscount(
        calcPlansTotal(cart, plans, plansAreFree) +
          calcSubscriptionsTotal(cart, plansAreFree),
        [discount],
      );
      return { ...discount, ...discountApplications[0] };
    }

    // Discount of type product
    let applied = false;
    const { discounted } = splitProducts(cart, [discount], categories);
    if (discounted.length > 0) {
      // if the discount doesn't apply to any products than no need to go any further
      const discountedTotal = discounted.reduce(
        (acc, { product, quantity }) => {
          const price = quantity * product.price;
          return acc + price;
        },
        0,
      );

      const { discountApplications } = applyDiscount(discountedTotal, [
        discount,
      ]);

      [{ applied }] = discountApplications;
    }
    return { ...discount, applied };
  });

export {
  getPriceForUsers,
  applyDiscount,
  splitDiscounts,
  splitProducts,
  calcTax,
  calcMonthlyTotal,
  calcMonthlyPostTax,
  calcCurrentTotal,
  calcCurrentPostTax,
  markDiscountApplication,
};
