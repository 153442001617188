import React from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import Avatar from './Avatar';
import translate from '../../../commonComponents/translate';

function SidePanel({ t, name, email, avatarURL, onAvatarChange, birthday }) {
  const birthdayDisplay = birthday ? moment.utc(birthday).format('DD/MM/YYYY') : '';

  return (
    <div className="m-portlet m-portlet--full-height">
      <div className="m-portlet__body">
        <div className="m-card-profile">
          <div className="m-card-profile__title m--hide">{t('profile_title')}</div>
          <div className="m-card-profile__pic">
            <div className="m-card-profile__pic-wrapper">
              <Avatar url={avatarURL} onChange={onAvatarChange} />
            </div>
          </div>
          <div className="m-card-profile__details">
            <span className="m-card-profile__name">{name}</span>
            <a href="" className="m-card-profile__email m-link">
              {email}
            </a>
            <span className="m-card-profile__birthday">{birthdayDisplay}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

SidePanel.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  avatarURL: PropTypes.string.isRequired,
  birthday: PropTypes.number,
  onAvatarChange: PropTypes.func,
};

SidePanel.defaultProps = {
  onAvatarChange: null,
  birthday: null,
};

export default translate(SidePanel);
