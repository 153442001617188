import PropTypes from 'prop-types';
import ProductProp from './ProductProp';

const propType = {
  ...ProductProp.propType,
  products: PropTypes.arrayOf(PropTypes.shape(ProductProp.propType)).isRequired,
};

const defaultProps = {
  ...ProductProp.defaultProp,
  products: [],
};

export default { propType, defaultProps };
