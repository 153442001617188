import React from 'react';
import CSRFContext from '../contextProviders/CSRFContext';

/**
 * CSRF Providing Higher Order component, uses the Context API to provide a valid csrf token
 * for the current request to the given component.
 * Instead of exporting your component directly simply export the use of the HoC over your component,
 * such as:
 * function MyComponent({ _csrf }) {
 *   return <input name="_csrf" type="hidden" value={_csrf} />;
 * }
 *
 * export default translate(MyComponent);
 */
export default function withCSRF(Component) {
  const C = props => {
    const { wrappedComponentRef, ...remainingProps } = props;

    return (
      <CSRFContext.Consumer>
        {_csrf => (
          <Component
            _csrf={_csrf}
            {...remainingProps}
            ref={wrappedComponentRef}
            wrappedComponentRef={wrappedComponentRef}
          />
        )}
      </CSRFContext.Consumer>
    );
  };

  C.displayName = `withCSRF(${Component.displayName || Component.name})`;
  C.WrappedComponent = Component;

  return C;
}
