import React from 'react';
import PropTypes from 'prop-types';
import CartProp from '../propTypes/CartProp';
import PlanProp from '../propTypes/PlanProp';
import CategoryProp from '../propTypes/CategoryProp';
import { displayMoney } from '../../../services/utilityFunctions';
import {
  getPriceForUsers,
  calcMonthlyTotal,
  calcCurrentTotal,
  splitDiscounts,
  calcMonthlyPostTax,
  calcCurrentPostTax,
  calcTax,
} from '../../../services/cartCalculations';
import translate from '../../../commonComponents/translate';
import PriceLine from './PriceLine';
import TaxInfoProp from '../propTypes/TaxInfoProp';

function Cart(props) {
  const {
    t,
    plansAreFree,
    plans,
    cart,
    categories,
    taxInfo,
    country,
    priceLabelClassName,
    priceValueClassName,
    totalContainerClassName,
    totalLineClassName,
    totalLabelClassName,
    totalValueClassName,
    onPackRemove,
    onProductRemove,
    onSubscriptionRemove,
    onDiscountRemove,
    interval,
  } = props;

  const {
    limitUsers,
    products: cartProds,
    packs: cartPacks,
    subscriptions: cartSubs,
    plans: cartPlans,
    discounts: cartDiscounts,
  } = cart;

  const renderPlanPrice = item => {
    let planInfo = plans.find(p => p._id === item._id);
    if (!planInfo) {
      // eslint-disable-next-line prefer-destructuring
      planInfo = plans[0];
    }
    const { _id, name, prices } = planInfo;
    const { price, isFlat } = getPriceForUsers(prices, limitUsers);

    let priceLabel = displayMoney(plansAreFree ? 0 : price);
    if (!plansAreFree && !isFlat) {
      priceLabel = `${limitUsers} x ${priceLabel}`;
    }

    return (
      <PriceLine
        key={_id}
        labelClassName={priceLabelClassName}
        valueClassName={priceValueClassName}
        label={name}
        value={priceLabel}
      />
    );
  };

  // eslint-disable-next-line react/prop-types
  const renderSubscriptionPrice = ({ product }) => {
    const { _id, name, price, isFreeTrial } = product;

    const isFree = plansAreFree && isFreeTrial;
    let priceLabel = displayMoney(isFree ? 0 : price);
    if (!isFree) {
      priceLabel = `${limitUsers} x ${priceLabel}`;
    }

    return (
      <PriceLine
        key={_id}
        labelClassName={priceLabelClassName}
        valueClassName={priceValueClassName}
        label={name}
        value={priceLabel}
        removeItem={() => onSubscriptionRemove(_id)}
      />
    );
  };

  // eslint-disable-next-line react/prop-types
  const renderPackPrice = ({ product, quantity }) => {
    const { _id, name, price } = product;

    let priceLabel = displayMoney(price);
    if (quantity > 1) {
      priceLabel = `${quantity} x ${priceLabel}`;
    }
    return (
      <PriceLine
        key={_id}
        labelClassName={priceLabelClassName}
        valueClassName={priceValueClassName}
        label={`${t('subscriptionCreate_nav_pack')}${name}`}
        value={priceLabel}
        removeItem={() => onPackRemove(_id)}
      />
    );
  };

  // eslint-disable-next-line react/prop-types
  const renderProductPrice = ({ product, quantity }) => {
    const { _id, name, price, mandatory } = product;

    let priceLabel = displayMoney(price);
    if (quantity > 1) {
      priceLabel = `${quantity} x ${priceLabel}`;
    }
    return (
      <PriceLine
        key={_id}
        labelClassName={priceLabelClassName}
        valueClassName={priceValueClassName}
        label={name}
        value={priceLabel}
        removeItem={mandatory ? null : () => onProductRemove(_id)}
      />
    );
  };

  // eslint-disable-next-line react/prop-types
  const renderSubDiscount = discount => {
    const { _id, name, percentOff, amountOff } = discount;

    const priceLabel = amountOff ? displayMoney(amountOff) : `${percentOff} %`;
    return (
      <PriceLine
        key={_id}
        labelClassName={priceLabelClassName}
        valueClassName={priceValueClassName}
        label={name}
        value={`- ${priceLabel}`}
        removeItem={() => onDiscountRemove(_id)}
      />
    );
  };

  const monthlyPreTax = calcMonthlyTotal(cart, plans, plansAreFree);
  const monthlyTotal = calcMonthlyPostTax(
    cart,
    plans,
    plansAreFree,
    taxInfo,
    country,
  );
  const currentPreTax = calcCurrentTotal(cart, categories);
  const currentTotal = calcCurrentPostTax(cart, categories, taxInfo, country);

  const { subs: subDiscs, prods: prodDiscs } = splitDiscounts(cartDiscounts);
  const hasPlans =
    cartPlans.length > 0 || cartSubs.length > 0 || subDiscs.length > 0;
  const hasProducts =
    cartPacks.length > 0 || cartProds.length > 0 || prodDiscs.length > 0;
  return (
    <React.Fragment>
      <div className="price-items-container">
        {hasPlans && <h5>{interval === 'year' ? t('subscription_interval_year') : t('subscription_interval_month')}</h5>}
        {cartPlans.map(renderPlanPrice)}
        {cartSubs.map(renderSubscriptionPrice)}
        {subDiscs.map(renderSubDiscount)}
        {hasPlans && (
          <PriceLine
            labelClassName={priceLabelClassName}
            valueClassName={priceValueClassName}
            label={t('subscriptionCreate_nav_vat')}
            value={displayMoney(calcTax(monthlyPreTax, taxInfo, country))}
          />
        )}
        {hasProducts && <h5>{t('subscriptionCreate_nav_now')}</h5>}
        {cartPacks.map(renderPackPrice)}
        {cartProds.map(renderProductPrice)}
        {prodDiscs.map(renderSubDiscount)}
        {hasProducts && (
          <PriceLine
            labelClassName={priceLabelClassName}
            valueClassName={priceValueClassName}
            label={t('subscriptionCreate_nav_vat')}
            value={displayMoney(calcTax(currentPreTax, taxInfo, country))}
          />
        )}
      </div>
      <div className={totalContainerClassName}>
        <h4 style={{ marginBottom: '0' }}>
          {t('subscriptionCreate_nav_totals')}
        </h4>
        <div className={totalLineClassName}>
          <div className={totalLabelClassName}>
            {interval === 'year' ? t('subscription_interval_year') : t('subscription_interval_month')}
          </div>
          <div className={totalValueClassName}>
            {displayMoney(monthlyTotal)}
          </div>
        </div>
        <div className={totalLineClassName}>
          <div className={totalLabelClassName}>
            {t('subscriptionCreate_nav_now')}
          </div>
          <div className={totalValueClassName}>
            {displayMoney(currentTotal)}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

Cart.propTypes = {
  t: PropTypes.func.isRequired,
  plansAreFree: PropTypes.bool,
  cart: PropTypes.shape(CartProp.propType).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape(CategoryProp.propType))
    .isRequired,
  plans: PropTypes.arrayOf(PropTypes.shape(PlanProp.propType)).isRequired,
  taxInfo: PropTypes.shape(TaxInfoProp.propType).isRequired,
  country: PropTypes.string.isRequired,
  priceLabelClassName: PropTypes.string,
  priceValueClassName: PropTypes.string,
  totalContainerClassName: PropTypes.string,
  totalLineClassName: PropTypes.string,
  totalLabelClassName: PropTypes.string,
  totalValueClassName: PropTypes.string,
  onPackRemove: PropTypes.func.isRequired,
  onProductRemove: PropTypes.func.isRequired,
  onSubscriptionRemove: PropTypes.func.isRequired,
  onDiscountRemove: PropTypes.func.isRequired,
  interval: PropTypes.string.isRequired,
};

Cart.defaultProps = {
  plansAreFree: false,
  priceLabelClassName: 'prices-item-label',
  priceValueClassName: 'prices-item-value',
  totalContainerClassName: 'prices-total',
  totalLineClassName: 'prices-total-line',
  totalLabelClassName: 'prices-total-label',
  totalValueClassName: 'prices-total-value',
};

export default React.memo(translate(Cart));
