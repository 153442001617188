import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import axios from 'axios';
import { compose } from '../../services/utilityFunctions';
import translate from '../../commonComponents/translate';
import withCSRF from '../../commonComponents/withCSRF';
import Form from '../../commonComponents/forms/Form';
import FormInput from '../../commonComponents/forms/FormInput';
import SignInContainer from './container/SignInContainer';
import Title from '../../commonComponents/Title';
import Notifications from '../../services/notifications';

function RecoverPasswordForm(props) {
  const { t, theme, _csrf, tenant, token, userId, err: error, reset } = props;

  let hasError = error === 'ERROR_TOKEN_NOT_FOUND' || error === 'ERROR_EXPIRED_TOKEN';
  const { primaryText, primaryColor, horizontalLogo } = tenant.branding;
  const [state, setState] = useState({ password: '', retypedPassword: '' });
  const [successUpdatingPassword, setSuccessUpdatingPassword] = useState(reset);
  const submitButtonClasses = cx(
    'btn',
    'btn-dynamic',
    'm-btn',
    'm-btn--pill',
    'm-btn--custom',
    'm-btn--air',
    'm-login__btn',
    'loader-button',
    hasError ? 'disabled-recover-pass' : '',
  );
  const handleChange = field => value => {
    setState(prevState => ({ ...prevState, [field]: value }));
  };
  const handleSubmit = () => {
    if (state.password === state.retypedPassword) {
      const params = {
        token,
        user: userId,
        password: state.password,
      };
      axios.post('/password/reset', params, {
        headers: {
          'Accept-Version': '1.0.0',
          'X-Gamification-API': 'Organization',
          'X-CSRF-Token': _csrf,
          _csrf,
        },
      })
        .then(() => {
          Notifications.showNotificationSuccess(t('success'), t('signIn.recover.password.success'));
          setSuccessUpdatingPassword(true);
          hasError = true;
        })
        .catch(err => {
          Notifications.showErrorFromRequest(err, t('signIn.recover.password.error'));
          console.log('Err', err);
        });
    }
  };
  return (
    <Title title={t('forgot_password')}>
      <div
        className="m-grid m-grid--hor m-grid--root m-page"
        style={{ height: '100vh' }}
      >
        <SignInContainer theme={theme} logo={horizontalLogo}>
          <div className="recover-password-form">
            <div className="">
              <div className="m-login__head">
                <h3 className="m-login__title">{t('forgot_password')}</h3>
                {!hasError && !successUpdatingPassword && (
                  <div className="m-login__desc">{t('signIn.recover.password.info')}</div>
                )}
                {!hasError && successUpdatingPassword && (
                  <div className="m-login__desc">{t('signIn.recover.password.info.success')}</div>
                )}
                {hasError && (
                  <div className="token-not-found">
                    <div className="alert alert-danger ">
                      <span>{t('ERROR_TOKEN_NOT_FOUND')}</span>
                    </div>
                  </div>
                )}
              </div>
              {!successUpdatingPassword && (
                <Form
                  validationRules={{
                    password: { required: true, minlength: 6 },
                    retypedPassword: {
                      required: true,
                      equalTo: '#password',
                    },
                  }}
                  className="m-login__form m-form"
                  id="recoverPasswordForm"
                  submitMethod={handleSubmit}
                >
                  {submitHandler => (
                    <>
                      <FormInput
                        type="password"
                        placeholder={t('password')}
                        name="password"
                        id="password"
                        value={state.password}
                        onChange={handleChange('password')}
                        inputProps={{ autoComplete: 'off', disabled: hasError }}
                        inputClassName={`login-input ${hasError ? 'disabled-recover-pass' : ''}`}
                      />
                      <FormInput
                        type="password"
                        placeholder={t('password')}
                        name="retypedPassword"
                        id="retypedPassword"
                        value={state.retypedPassword}
                        onChange={handleChange('retypedPassword')}
                        inputProps={{ autoComplete: 'off', disabled: hasError }}
                        inputClassName={`login-input ${hasError ? 'disabled-recover-pass' : ''}`}
                      />
                      <div className="m-login__form-action">
                        <button
                          type="submit"
                          id="m_login_forget_password_submit"
                          className={submitButtonClasses}
                          onClick={submitHandler}
                          style={{ backgroundColor: primaryColor, color: primaryText }}
                          disabled={hasError}
                        >
                          {t('validate')}
                        </button>
                      </div>
                    </>
                  )}
                </Form>
              )}
              {successUpdatingPassword && (
                <div className="goBack-toSignIn">
                  <button
                    type="submit"
                    id="gotoSignIn"
                    className={submitButtonClasses}
                    onClick={() => { window.location = '/signIn'; }}
                    style={{ backgroundColor: primaryColor, color: primaryText }}
                  >
                    {t('signIn.recover.password.btn.goToSignIn')}
                  </button>
                </div>
              )}
            </div>
          </div>
        </SignInContainer>
      </div>
    </Title>
  );
}

RecoverPasswordForm.propTypes = {
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  _csrf: PropTypes.string.isRequired,
  tenant: PropTypes.shape({
    branding: PropTypes.shape({
      primaryText: PropTypes.string,
      primaryColor: PropTypes.string,
      horizontalLogo: PropTypes.string,
    }),
  }).isRequired,
  token: PropTypes.string.isRequired,
  userId: PropTypes.string,
  err: PropTypes.bool,
  reset: PropTypes.bool,
};
RecoverPasswordForm.defaultProps = {
  userId: '',
  err: false,
  reset: false,
};

export default compose(
  translate,
  withCSRF,
)(RecoverPasswordForm);
