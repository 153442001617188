import PropTypes from 'prop-types';

const propType = {
  id: PropTypes.string.isRequired,
  isDefault: PropTypes.bool.isRequired,
  last4: PropTypes.string.isRequired,
  exp_month: PropTypes.number.isRequired,
  exp_year: PropTypes.number.isRequired,
  brand: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
};

export default { propType };
