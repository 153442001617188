import PropTypes from 'prop-types';
import VideoProp from './VideoProp';

const propType = {
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  prices: PropTypes.arrayOf(
    PropTypes.shape({
      flat_amount: PropTypes.number,
      unit_amount: PropTypes.number,
      from: PropTypes.number,
      up_to: PropTypes.number,
    }),
  ).isRequired,
  challenges: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    }),
  ).isRequired,
  video: PropTypes.shape(VideoProp.propType).isRequired,
  interval: PropTypes.string.isRequired,
};

export default { propType };
