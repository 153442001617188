class StorageService {
  constructor() {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.storage = localStorage;
    }
  }

  saveItem(key, value) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  getItem(key) {
    const item = this.storage.getItem(key);
    if (item) {
      return JSON.parse(item);
    }
    return null;
  }

  removeItem(key) {
    this.storage.removeItem(key);
  }
}

const instance = new StorageService();

export default instance;
