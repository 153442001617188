const USER_ROLE = {
  SUPER_ADMIN: 'super_admin',
  INSTANCE_ADMIN: 'instance_admin',
  ADMIN: 'admin',
  USER: 'user',
  MEMBER: 'member',
  GUEST: 'guest',
};

const USER_ROLES = Object.values(USER_ROLE);

const SUBSCRIPTION_TYPE = {
  FREE: 'free',
  TRIAL: 'trial',
  STANDARD: 'normal',
};
const SUBSCRIPTION_TYPES = Object.values(SUBSCRIPTION_TYPE);

const PRODUCT_TYPE = {
  SUBSCRIPTION: 'subscription',
  SINGLE_BUY: 'single_buy',
  MULTI_BUY: 'multi_buy',
};

const PRODUCT_TYPES = Object.values(PRODUCT_TYPE);

const PRODUCT_ITEM_TYPE = {
  AVATAR: 'avatar',
  AI: 'ai',
  CERTIFICATES: 'certificates',
  ANALYTICS: 'analytics',
  SURVEY: 'survey',
  TROPHY: 'trophy',
  LEVELS: 'levels',
  JIRA: 'jira',
  WALLET: 'wallet',
  TOPICS: 'topics',
  JOURNEY: 'journey',
  POSTER: 'poster',
  WOO_COMMERCE: 'wooCommerce',
  GAME_ITEM: 'game_item',
  SURVEY_ITEM: 'survey_item',
  JOURNEY_ITEM: 'journey_item',
  TOPIC_GROUP_ITEM: 'topic_group_item',
  COURSE_ITEM: 'course_item',
  INBOX: 'inbox',
  CONNECT: 'connect',
  PERFORM: 'perform',
  ACADEMY: 'academy',
  MARKET_PLACE: 'marketPlace',
  CHARITY: 'charity',
  SCORM: 'scorm',
};

const PRODUCT_ITEM_TYPES = Object.values(PRODUCT_ITEM_TYPE);

const PRODUCT_VIEW = {
  SOLUTIONS: 'solutions',
  OPTIONS: 'options',
  MODULES: 'modules',
};

const PRODUCT_VIEWS = Object.values(PRODUCT_VIEW);

const DISCOUNT_TYPE = {
  SUBSCRIPTION: 'subscription',
  PRODUCT: 'product',
};

const DISCOUNT_TYPES = Object.values(DISCOUNT_TYPE);

const DISCOUNT_DURATION = {
  ONCE: 'once',
  FOREVER: 'forever',
  REPEATING: 'repeating',
};

const DISCOUNT_DURATIONS = Object.values(DISCOUNT_DURATION);

const PROFILE_TAB = {
  SUBSCRIPTIONS: 'subscriptions',
  INVOICES: 'invoices',
  BILLING: 'billing',
  PAYMENT: 'payment',
};

const PROFILE_TABS = Object.values(PROFILE_TAB);

const isHigherAdmin = loggedUser =>
  loggedUser &&
  (loggedUser.role === USER_ROLE.SUPER_ADMIN ||
    loggedUser.role === USER_ROLE.INSTANCE_ADMIN);

const displayDate = (date, options) => {
  if (!date) {
    return '';
  }

  let lang = 'en';
  if (typeof window !== 'undefined' && window.document) {
    const data = window.document.querySelector('script[data-lang]');
    if (data) {
      lang = data.getAttribute('data-lang');
    }
  }

  const defaultOptions = {
    timeZone: 'UTC',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  return new Date(date).toLocaleDateString(lang, {
    ...defaultOptions,
    ...options,
  });
};

const numberWithSpaces = x =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

const displayNumber = number => {
  if (number === '') {
    return '';
  }

  try {
    let value = numberWithSpaces(Number(number).toFixed(2));

    const decimalPlaces = value.split('.');
    if (decimalPlaces[1]) {
      value = decimalPlaces[1].length < 2 ? `${value}0` : value;
    } else {
      value = `${value}.00`;
    }

    let lang = 'en';
    if (document) {
      const data = document.querySelector('script[data-lang]');
      if (data) {
        lang = data.getAttribute('data-lang');
      }
    }
    if (lang.startsWith('fr')) {
      value = value.replace('.', ',');
    }

    return value;
  } catch (err) {
    return '';
  }
};

const displayMoney = number => {
  try {
    const numberValue = Number(number) / 100;
    const value = displayNumber(numberValue);
    return value ? `${value} €` : value;
  } catch (err) {
    return '';
  }
};

const debounce = (func, delay) => {
  let inDebounce;
  return function debounced(...args) {
    const context = this;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
};

const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;
  return function throttled(...args) {
    const context = this;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(() => {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};

const compose = (fn, ...rest) =>
  rest.length === 0 ? fn : (...args) => fn(compose(...rest)(...args));

const displayCardNumber = last4 => `•••• ${last4}`;

const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const isInCart = (cart, ts) => {
  if (ts.type === PRODUCT_TYPE.SUBSCRIPTION) {
    return !!cart.subscriptions.find(({ product }) => product._id === ts._id);
  }
  return !!cart.products.find(({ product }) => product._id === ts._id);
};

const colors = {
  brand: '#7ed321',
  metal: '#c4c5d6',
  light: '#ffffff',
  accent: '#00c5dc',
  primary: '#5867dd',
  success: '#34bfa3',
  info: '#36a3f7',
  warning: '#ffb822',
  danger: '#f4516c',
  focus: '#9816f4',
};

export {
  USER_ROLE,
  USER_ROLES,
  SUBSCRIPTION_TYPE,
  SUBSCRIPTION_TYPES,
  PRODUCT_TYPE,
  PRODUCT_TYPES,
  PRODUCT_ITEM_TYPE,
  PRODUCT_ITEM_TYPES,
  PRODUCT_VIEW,
  PRODUCT_VIEWS,
  DISCOUNT_TYPE,
  DISCOUNT_TYPES,
  DISCOUNT_DURATION,
  DISCOUNT_DURATIONS,
  PROFILE_TAB,
  PROFILE_TABS,
  isHigherAdmin,
  displayDate,
  displayNumber,
  displayMoney,
  debounce,
  throttle,
  compose,
  colors,
  displayCardNumber,
  isInCart,
  capitalize,
};
