import PropTypes from 'prop-types';

const propType = {
  _id: PropTypes.string.isRequired,
  expirationDate: PropTypes.number,
  isActive: PropTypes.bool.isRequired,
  limitUsers: PropTypes.number,
  type: PropTypes.string.isRequired,
  plans: PropTypes.arrayOf(
    PropTypes.shape({ _id: PropTypes.string, limitUsers: PropTypes.number }),
  ),
  hasPendingChanges: PropTypes.bool,
};

const defaultProp = {
  _id: '',
  expirationDate: null,
  isActive: false,
  limitUsers: 0,
  type: '',
  plans: [],
  hasPendingChanges: false,
};

export default { propType, defaultProp };
