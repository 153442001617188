import React from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import translate from '../translate';
import ModalHeader from '../modal/ModalHeader';
import Modal from '../modal/Modal';

class ImageCropModal extends React.Component {
  static getCroppedImg(imageDataURL, pixelCrop) {
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.src = imageDataURL;

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height,
    );

    return new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg'));
  }

  constructor(props) {
    super(props);

    this.state = {
      cropState: { aspect: 1, x: 0, y: 0, width: 100 },
      pixelCrop: null,
    };

    this.handleCropChange = this.handleCropChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleCropChange(cropState, pixelCrop) {
    this.setState({ cropState, pixelCrop });
  }

  async handleSave() {
    const { originalFile, fileName, onFinished } = this.props;
    const { pixelCrop } = this.state;
    const croppedImage = await ImageCropModal.getCroppedImg(
      originalFile,
      pixelCrop,
      fileName,
    );
    onFinished(croppedImage);
  }

  render() {
    const { t, originalFile, onFinished, title } = this.props;
    const { cropState } = this.state;

    return (
      <Modal>
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div
            className="modal-content"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <ModalHeader title={title} onClose={() => onFinished(null)} />
            <div className="modal-body">
              <ReactCrop
                src={originalFile}
                crop={cropState}
                onChange={this.handleCropChange}
                keepSelection
              />
              <div className="m-portlet__foot" style={{ marginTop: 'auto' }}>
                <button
                  type="submit"
                  className="btn btn-accent m-btn m-btn--air m-btn--custom loader-button"
                  onClick={this.handleSave}
                >
                  {t('save')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

ImageCropModal.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  originalFile: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  onFinished: PropTypes.func.isRequired,
};

export default translate(ImageCropModal);
