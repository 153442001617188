import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../../services/loader';

/**
 * Basic mDataTable implementation,
 * see https://keenthemes.com/metronic/?page=docs&section=datatable#docs
 */
export default class List extends React.Component {
  constructor(props) {
    super(props);
    this.loader = null;
  }

  componentDidMount() {
    const { id, data, columns, onInit, onRowClick } = this.props;
    this.loader = new Loader(`#${id}`);

    const config = {
      data: {
        type: 'local',
        source: data,
        pageSize: 20,
      },

      layout: {
        theme: 'default',
        class: '',
        scroll: false,
        footer: false,
      },

      sortable: true,

      pagination: true,
      pagingType: 'full_numbers',
      columns,
      rows: onRowClick
        ? {
            beforeTemplate: (row, rowData) =>
              row
                .addClass('clickable-row')
                .on('click', () => onRowClick(rowData)),
          }
        : undefined,
    };

    const tableElement = $(`#${id}`);
    const table = tableElement.mDatatable(config);

    if (onInit) {
      table.on('m-datatable--on-init', onInit);
    }
  }

  componentDidUpdate({ data: oldData, loading: oldLoading }) {
    const { data, loading } = this.props;

    if (loading !== oldLoading) {
      if (loading) {
        this.loader.show();
      } else {
        this.loader.hide();
      }
    }

    data.forEach((c, idx) => {
      if (!oldData[idx] || oldData[idx] !== c) {
        const table = $(`#${this.props.id}`).mDatatable();
        table.originalDataSet = data;
        table.reload();
      }
    });
  }

  componentWillUnmount() {
    $(`#${this.props.id}`)
      .mDatatable()
      .destroy();
  }

  render() {
    return <div className="m_datatable" id={this.props.id} />;
  }
}

List.propTypes = {
  /** DataTable div container id */
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  /** Column definitions, passed directly to jQuery component, see it's docs */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      textAlign: PropTypes.string,
      width: PropTypes.number,
      sortable: PropTypes.bool,
      overflow: PropTypes.string,
      template: PropTypes.func,
    }),
  ).isRequired,
  /**
   * initialization event handler so we can do extra work, like add events to the
   * components rendered as a string
   */
  onInit: PropTypes.func,
  /**
   * Row click event handler, row data is passed as an argument
   */
  onRowClick: PropTypes.func,
  /** Whether we're currently loading new data */
  loading: PropTypes.bool.isRequired,
};

List.defaultProps = {
  onInit: null,
  onRowClick: null,
};
