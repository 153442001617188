import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../../../../commonComponents/translate';
import CreditCardItem from './CreditCardItem';
import SEPAItem from './SEPAItem';
import StripePaymentMethodsProp from '../../../../payments/propTypes/StripePaymentMethodsProp';
import CountryProp from '../../../../../commonComponents/forms/Address/CountryProp';

function CreditCardSelector(props) {
  const handleSelectPaymentMethod = paymentMethodId => {
    if (props.isModeSelection) {
      props.onSelectPaymentMethod(paymentMethodId);
    }
  };
  const handleRemovePaymentMethod = paymentMethodId => {
    if (props.onRemovePaymentMethod) {
      props.onRemovePaymentMethod(paymentMethodId);
    }
  };

  const handleClickAddCard = () => {
    if (props.onClickAddCard) {
      props.onClickAddCard();
    }
  };

  const handleClickAddSEPA = () => {
    if (props.onClickAddSEPA) {
      props.onClickAddSEPA();
    }
  };
  const {
    t,
    paymentMethods,
    isModeSelection,
    hasError,
    SEPACountries,
  } = props;

  return (
    <div className="m-form__section">
      <div className="m-form__heading">
        {hasError ? (
          <h6 className="m-form__heading-title m--font-danger">
            {`${t('choose_payment_method')}*`}
          </h6>
        ) : (
          <h6 className="m-form__heading-title">
            {t('choose_payment_method')}
          </h6>
        )}
      </div>
      <div className="form-group m-form__group">
        <div className="row">
          {paymentMethods.map(paymentMethod => {
            if (paymentMethod.type === 'card') {
              return (
                <div className="col-md-6 m--margin-bottom-30" key={paymentMethod.id}>
                  <CreditCardItem
                    id={paymentMethod.id}
                    last4={paymentMethod.card.last4}
                    exp_month={paymentMethod.card.exp_month}
                    exp_year={paymentMethod.card.exp_year}
                    brand={paymentMethod.card.brand}
                    logo={paymentMethod.card.logo}
                    isSelected={paymentMethod.isDefault}
                    displayRemove={!paymentMethod.isDefault}
                    onSelect={handleSelectPaymentMethod}
                    onRemove={handleRemovePaymentMethod}
                    readOnly={!isModeSelection}
                  />
                </div>
              );
            }
            if (paymentMethod.type === 'sepa_debit') {
              return (
                <div className="col-md-6 m--margin-bottom-30" key={paymentMethod.id}>
                  <SEPAItem
                    id={paymentMethod.id}
                    last4={paymentMethod.sepa.last4}
                    billingName={paymentMethod.billingName}
                    logo={paymentMethod.sepa.logo}
                    country={paymentMethod.sepa.country}
                    isSelected={paymentMethod.isDefault}
                    displayRemove={!paymentMethod.isDefault}
                    onSelect={handleSelectPaymentMethod}
                    onRemove={handleRemovePaymentMethod}
                    readOnly={!isModeSelection}
                    SEPACountries={SEPACountries}
                  />
                </div>
              );
            }
            return (<></>);
          })}
          {isModeSelection ? (
              <>
              <div className="col-md-6 m--margin-bottom-30">
                <div className="m-card_add" onClick={handleClickAddCard}>
                  <div className="m-card_add__body">
                    <div className="m-card_add__img">
                      <span>
                        <i className="la la-credit-card" />
                      </span>
                    </div>
                    <div className="m-card_add__text">
                      <span>{t('add_card_payment')}</span>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6 m--margin-bottom-30"> */}
              {/*  <div className="m-card_add" onClick={handleClickAddSEPA}> */}
              {/*    <div className="m-card_add__body"> */}
              {/*      <div className="m-card_add__img"> */}
              {/*      <span> */}
              {/*        <i className="la la-bank" /> */}
              {/*      </span> */}
              {/*      </div> */}
              {/*      <div className="m-card_add__text"> */}
              {/*        <span>{t('add_sepa_payment')}</span> */}
              {/*      </div> */}
              {/*    </div> */}
              {/*  </div> */}
              {/* </div> */}
              </>
          ) : (
            <div />
          )}
        </div>
        <div className="m-form__help" />
      </div>
    </div>
  );
}

CreditCardSelector.propTypes = {
  t: PropTypes.func.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.shape(StripePaymentMethodsProp.propType)),
  onSelectPaymentMethod: PropTypes.func,
  isModeSelection: PropTypes.bool,
  onClickAddCard: PropTypes.func,
  onClickAddSEPA: PropTypes.func,
  onRemovePaymentMethod: PropTypes.func,
  hasError: PropTypes.bool,
  SEPACountries: PropTypes.arrayOf(PropTypes.shape(CountryProp.propType)).isRequired,
};

CreditCardSelector.defaultProps = {
  paymentMethods: [],
  onSelectPaymentMethod: null,
  onClickAddCard: null,
  onClickAddSEPA: null,
  onRemovePaymentMethod: null,
  isModeSelection: false,
  hasError: false,
};

export default translate(CreditCardSelector);
