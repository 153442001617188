import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../../../../../commonComponents/translate';
import Modal from '../../../../../../commonComponents/modal/Modal';
import ModalHeader from './ProductItemModalHeader';
import ProductProp from '../../../../propTypes/ProductProp';
import Gallery from './ProductItemGallery';
import PackProp from '../../../../propTypes/PackProp';

class ProductItemModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { quantity: 1 };

    this.handleClick = this.handleClick.bind(this);
    this.handleProductAdd = this.handleProductAdd.bind(this);
    this.handleChangeQuantity = this.handleChangeQuantity.bind(this);
  }

  componentDidMount() {
    const { onClose } = this.props;
    // execute onClose when esc is pressed
    document.addEventListener(
      'keydown',
      event => {
        if (event.keyCode === 27) {
          onClose();
        }
      },
      false,
    );
  }

  handleChangeQuantity({ type, value }) {
    const { quantity } = this.state;
    if (type === 'decrement') {
      this.setState({ quantity: quantity > 0 ? quantity - 1 : 0 });
    } else if (type === 'increment') {
      this.setState({ quantity: quantity + 1 });
    } else if (type === 'set') {
      this.setState({ quantity: value > 0 ? value : 1 });
    }
  }

  handleClick() {
    const { product, onClickRemove } = this.props;
    const { isInCart } = product;
    const cb = isInCart ? onClickRemove : this.handleProductAdd;
    cb(product);
  }

  handleProductAdd(product) {
    const { onClickAdd } = this.props;
    const { quantity } = this.state;
    onClickAdd({ product, quantity });
  }

  render() {
    const { quantity } = this.state;
    const { t, onClose, product, interval } = this.props;
    const { description, images, video } = product;

    return (
      <Modal>
        <div
          className="modal-dialog modal-dialog-centered modal-lg product-item-modal"
          role="document"
        >
          <div className="modal-content">
            <ModalHeader
              t={t}
              onClose={onClose}
              product={product}
              onClick={this.handleClick}
              handleChangeQuantity={this.handleChangeQuantity}
              quantity={quantity}
              interval={interval}
            />
            <div className="modal-body product-item-modal-content">
              <div style={{ alignSelf: 'flex-start' }}>
                <div className="product-item-modal-resume">
                  {t('product-item-modal-resume')}
                </div>
                <div className="product-item-modal-description">
                  {description}
                </div>
              </div>
              <Gallery images={images} video={video} />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

ProductItemModal.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  product: PropTypes.oneOfType([
    PropTypes.shape(ProductProp.propType),
    PropTypes.shape(PackProp.propType),
  ]),
  onClickRemove: PropTypes.func,
  onClickAdd: PropTypes.func,
  interval: PropTypes.string.isRequired,
};

ProductItemModal.defaultProps = {
  onClose: null,
  product: ProductProp.defaultProp,
  onClickRemove: null,
  onClickAdd: null,
};

export default translate(ProductItemModal);
