import React from 'react';
import PropTypes from 'prop-types';
import UserProp from '../../propTypes/userProp';
import Request from '../../../../services/request';
import Notifications from '../../../../services/notifications';
import { compose } from '../../../../services/utilityFunctions';
import withCSRF from '../../../../commonComponents/withCSRF';
import translate from '../../../../commonComponents/translate';
import Form from '../../../../commonComponents/forms/Form';
import FormInput from '../../../../commonComponents/forms/FormInput';
import FormHeader from '../../../../commonComponents/forms/FormHeader';
import Separator from '../../../../commonComponents/forms/Separator';
import FormActions from './FormActions';
import ImageProp from '../../propTypes/imageProp';

/**
 * Form to update the current logged user
 */
class ProfileForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: props.member.username || '',
      firstName: props.member.firstName || '',
      lastName: props.member.lastName || '',
      email: props.member.email || '',
      genre: props.member.genre || '',
      password: '',
      newPassword: '',
      confirmPassword: '',
    };

    this.formId = 'profileForm';

    this.validationRules = {
      username: { required: true },
      firstName: { required: true },
      last_name: { required: true },
      email: { required: true, email: true },
      new_password: { required: false, minlength: 6 },
      confirm_password: { equalTo: '#newPassword' },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.createInput = this.createInput.bind(this);
  }

  handleSubmit() {
    const { t, _csrf, avatar, onMemberChange } = this.props;
    const image = { ...avatar };
    const changingPassword = !!this.state.newPassword;
    const params = {
      ...this.state,
      image,
      password: changingPassword ? this.state.password : undefined,
      newPassword: changingPassword ? this.state.newPassword : undefined,
      confirmPassword: undefined,
    };

    new Request(_csrf)
      .put('/profile/edit', params)
      .done(() => {
        Notifications.showNotificationSuccess(
          t('success'),
          t('success_update'),
        );

        $(`#${this.formId} .has-success`).removeClass('has-success');

        onMemberChange({
          name: `${params.firstName} ${params.lastName}`,
          username: params.username,
          firstName: params.firstName,
          lastName: params.lastName,
          email: params.email,
          genre: params.genre,
        });
      })
      .fail(err => {
        let error = t('error_profileUpdate');
        if (err && err.responseJSON) {
          error = err.responseJSON.message || t(err.responseJSON.error);
        }
        Notifications.showNotificationError(t('error'), error);
      });
  }

  handleChange(field) {
    return value => this.setState({ [field]: value });
  }

  createInput(field, typeParam) {
    const { t } = this.props;

    const type = typeParam || 'text';

    const inputProps =
      field === 'genre'
        ? {
            required: true,
            placeholder: t('profile_genre_placeholder'),
            items: [
              { id: 'male', text: t('gender_male') },
              { id: 'female', text: t('gender_female') },
            ],
          }
        : undefined;

    return (
      <FormInput
        id={field}
        name={field}
        value={this.state[field]}
        onChange={this.handleChange(field)}
        label={t(`profile_${field}`)}
        labelClassName="col-2"
        containerClassName="col-7"
        type={type}
        groupProps={{ className: 'row' }}
        inputProps={inputProps}
      />
    );
  }

  render() {
    const { t } = this.props;

    return (
      <Form
        className="m-form m-form--fit m-form--label-align-right"
        id={this.formId}
        submitMethod={this.handleSubmit}
        validationRules={this.validationRules}
      >
        {submitHandler => (
          <React.Fragment>
            <div className="m-portlet__body">
              <FormHeader title={t('profile_personalDetails')} />
              {this.createInput('username')}
              {this.createInput('firstName')}
              {this.createInput('lastName')}
              {this.createInput('email', 'email')}
              {this.createInput('genre', 'select')}
              <Separator />
              {this.createInput('password', 'password')}
              {this.createInput('newPassword', 'password')}
              {this.createInput('confirmPassword', 'password')}
            </div>
            <FormActions submitHandler={submitHandler} />
          </React.Fragment>
        )}
      </Form>
    );
  }
}

ProfileForm.propTypes = {
  /** translation function */
  t: PropTypes.func.isRequired,
  /** _csrf token for requests */
  _csrf: PropTypes.string.isRequired,
  /** member being edited */
  member: PropTypes.shape(UserProp.propType).isRequired,
  /** member change event handler */
  onMemberChange: PropTypes.func.isRequired,
  /** Avatar of the member, possibly changed, to send on the put request */
  avatar: PropTypes.shape(ImageProp.propType).isRequired,
};

export default compose(
  withCSRF,
  translate,
)(ProfileForm);
