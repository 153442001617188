import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../../../commonComponents/translate';
import Plan from './Plan';
import PlanProp from '../../propTypes/PlanProp';
import CartProp from '../../propTypes/CartProp';
import ProductCategory from '../options/category/ProductCategory';
import CategoryProp from '../../propTypes/CategoryProp';
import {
  PRODUCT_TYPE,
  PRODUCT_VIEW,
} from '../../../../services/utilityFunctions';
import ProductItemModal from '../options/category/modal/ProductItemModal';
import Modules from './modules/Modules';
import IntervalSwitch from './IntervalSwitch';

class Solutions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      selectedProduct: null,
    };

    this.handleItemAdd = this.handleItemAdd.bind(this);
    this.handleItemRemove = this.handleItemRemove.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.validateAndLeave = this.validateAndLeave.bind(this);
  }

  handleItemAdd(addInfo) {
    if (addInfo.product.type === PRODUCT_TYPE.SUBSCRIPTION) {
      this.props.onSubscriptionAdd({ ...addInfo, interval: addInfo.product.interval });
    } else {
      this.props.onProductAdd({ ...addInfo, interval: addInfo.product.interval });
    }
    this.setState({
      selectedProduct: { ...addInfo.product, isInCart: true },
    });
  }

  handleItemRemove(product) {
    if (product.type === PRODUCT_TYPE.SUBSCRIPTION) {
      this.props.onSubscriptionRemove(product._id);
    } else {
      this.props.onProductRemove(product._id);
    }
    this.setState({ selectedProduct: { ...product, isInCart: false } });
  }

  closeModal() {
    this.setState({
      showModal: false,
      selectedProduct: false,
    });
  }

  openModal(product) {
    this.setState({
      showModal: true,
      selectedProduct: product,
    });
  }

  validateAndLeave(nextStep) {
    const { plans } = this.props.cart;
    this.props.onNavigate({}, nextStep, plans.length > 0);
  }

  render() {
    const { t, cart, plans, categories, isEdit, onIntervalChange, interval, isDefault } = this.props;
    const { showModal, selectedProduct } = this.state;
    const plan = plans[0];

    return (
      <React.Fragment>
        {showModal && (
          <ProductItemModal
            onClose={this.closeModal}
            onClickAdd={this.handleItemAdd}
            onClickRemove={this.handleItemRemove}
            product={selectedProduct}
            interval={interval}
          />
        )}
        {!isEdit && (
        <div className="subscription-interval-container">
          <IntervalSwitch
            interval={interval}
            onIntervalChange={onIntervalChange}
          />
        </div>
        )}
        <div className="m-portlet__body">
          <div className="row">
            <div className="col-md-6">
              <Plan
                users={cart.limitUsers}
                prices={plan.prices}
                description={plan.description}
                title={plan.name}
                onUsersChange={this.props.onUsersChange}
                interval={interval}
              />
            </div>
            <div className="col-md-6">
              <Modules
                cart={cart}
                modules={categories
                  .filter(c => c.displayOnView === PRODUCT_VIEW.MODULES)
                  .reduce((acc, c) => acc.concat(c.products), [])}
                onAdd={this.handleItemAdd}
                onRemove={this.handleItemRemove}
                isDefault={isDefault}
              />
            </div>
          </div>
          <div className="subscription-trophies-container">
            {categories
              .filter(c => c.displayOnView === PRODUCT_VIEW.SOLUTIONS)
              .map(c => (
                <div className="m--margin-top-30" key={c._id}>
                  <ProductCategory
                    name={c.name}
                    cart={cart}
                    products={c.products}
                    onClickAddItem={this.handleItemAdd}
                    onClickRemoveItem={this.handleItemRemove}
                    isSubscriptionEdit={isEdit}
                    onOpenDescriptionModal={this.openModal}
                    interval={interval}
                  />
                </div>
              ))}
          </div>
        </div>
        <div className="wizard-content-footer">
          <button
            onClick={() => this.validateAndLeave(2)}
            type="submit"
            className={
              'btn m-btn--pill m-btn--air btn-primary' +
              ' m-btn m-btn--custom wizard-footer-btn'
            }
          >
            {`${t('next')} >`}
          </button>
        </div>
      </React.Fragment>
    );
  }
}

Solutions.propTypes = {
  /** Translation Function */
  t: PropTypes.func.isRequired,
  /** User change for a given plan event handler */
  onUsersChange: PropTypes.func.isRequired,
  /** Handler for navigation event post validation */
  onNavigate: PropTypes.func.isRequired,
  /** Current shopping cart, including number of users per plan */
  cart: PropTypes.shape(CartProp.propType).isRequired,
  plans: PropTypes.arrayOf(PropTypes.shape(PlanProp.propType)).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape(CategoryProp.propType)),
  isEdit: PropTypes.bool.isRequired,
  onProductAdd: PropTypes.func.isRequired,
  onProductRemove: PropTypes.func.isRequired,
  onSubscriptionAdd: PropTypes.func.isRequired,
  onSubscriptionRemove: PropTypes.func.isRequired,
  interval: PropTypes.string.isRequired,
  onIntervalChange: PropTypes.func.isRequired,
  isDefault: PropTypes.bool.isRequired,
};

Solutions.defaultProps = {
  categories: [],
};

export default translate(Solutions);
