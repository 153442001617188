export default class TawkTo {
  constructor(id, profile) {
    this.id = id;
    this.profile = profile;
    this.instance_ = null;
  }

  async getInstance() {
    if (this.instance_) {
      return this.instance_;
    }

    return this.setInstance();
  }

  async init() {
    const s1 = document.createElement('script');
    const s0 = document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = `https://embed.tawk.to/${this.id}/default`;
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);

    if (this.profile) {
      const api = await this.getInstance();
      api.setAttributes(
        {
          name: this.profile.username,
          email: this.profile.email,
          organization: this.profile.organization,
        },
        err => {
          if (err) console.error('TawkTo: ', err);
        },
      );
    }
  }

  setInstance() {
    return new Promise(resolve => {
      const check = setInterval(() => {
        if (
          window &&
          window.Tawk_API &&
          window.Tawk_API.getStatus() !== undefined
        ) {
          clearInterval(check);
          this.instance_ = window.Tawk_API;
          resolve(this.instance_);
        }
      }, 100);
    });
  }
}
