import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import SignInForm from './SignInForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import DismissibleAlert, {
  ALERT_TYPE,
} from '../../commonComponents/forms/DismissibleAlert';
import SignInContainer from './container/SignInContainer';
import translate from '../../commonComponents/translate';

/**
 * Main SignIn page component, wraps the SignInForm and ForgotPasswordForm as well as the
 * success/error alert functionality
 */
class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSignIn: true,
      showForgotPassword: false,
      showAlert: !!props.signInError,
      alertMessage: props.signInError ? props.t(props.signInError) : '',
      alertType: props.signInError ? ALERT_TYPE.DANGER : '',
    };

    this.displaySignInForm = this.displaySignInForm.bind(this);
    this.displayForgotPasswordForm = this.displayForgotPasswordForm.bind(this);
    this.displayAlert = this.displayAlert.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }

  componentDidMount() {
    $.fn.animateClass = function animateClass(cls, cbParam) {
      let cb = cbParam;
      if (typeof cbParam === 'string') {
        const endClass = cbParam;
        cb = function addJqueryClass() {
          $(this).addClass(endClass);
        };
      }

      return $(this)
        .one('webkitAnimationEnd', function animationEnd() {
          $(this).removeClass(cls);
          if (cb) cb.call(this);
        })
        .addClass(cls);
    };
  }

  displaySignInForm() {
    this.setState(
      { showSignIn: true, showForgotPassword: false, showAlert: false },
      () =>
        $('#m_login')
          .find('.m-login__signin')
          .animateClass('flipInX animated'),
    );
  }

  displayForgotPasswordForm() {
    this.setState(
      { showSignIn: false, showForgotPassword: true, showAlert: false },
      () =>
        $('#m_login')
          .find('.m-login__forget-password')
          .animateClass('flipInX animated'),
    );
  }

  displayAlert(type, msg) {
    this.setState({ showAlert: true, alertMessage: msg, alertType: type }, () =>
      $('#alertContainer').animateClass('fadeIn animated'),
    );
  }

  hideAlert() {
    this.setState({ showAlert: false, alertMessage: '', alertType: '' });
  }

  render() {
    const { t, theme, organizationData, email, password } = this.props;

    const {
      showSignIn,
      showForgotPassword,
      showAlert,
      alertMessage,
      alertType,
    } = this.state;

    return (
      <DocumentTitle title={t('login_head_title')}>
        <div className="m-grid m-grid--hor m-grid--root m-page">
          <SignInContainer theme={theme} forgotPassword={showForgotPassword}>
            {showAlert && (
              <DismissibleAlert
                message={alertMessage}
                type={alertType}
                onClick={this.hideAlert}
                autoDismiss={false}
              />
            )}
            {showSignIn && (
              <SignInForm
                displayForgotPasswordForm={this.displayForgotPasswordForm}
                organizationData={organizationData}
                email={email}
                password={password}
              />
            )}
            {showForgotPassword && (
              <ForgotPasswordForm
                displayAlert={this.displayAlert}
                displaySignInForm={this.displaySignInForm}
                alertTypes={ALERT_TYPE}
              />
            )}
          </SignInContainer>
        </div>
      </DocumentTitle>
    );
  }
}

SignIn.propTypes = {
  /** translation function, i18n.__ on the browser, response.__ on the server */
  t: PropTypes.func.isRequired,
  /** Initial error rendered from the backend, replaces the old hasError/error on the ejs */
  signInError: PropTypes.string,
  /** Previously entered email, in case of error */
  email: PropTypes.string,
  /** Previously entered password, in case of error */
  password: PropTypes.string,
  /** Organization data, in case the user belongs to multiple organizations and we need to pick one */
  organizationData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      identifierCode: PropTypes.string.isRequired,
    }).isRequired,
  ),
  /** Selected metronic theme, from the Config */
  theme: PropTypes.string.isRequired,
};

SignIn.defaultProps = {
  signInError: null,
  organizationData: null,
  email: null,
  password: null,
};

export default translate(SignIn);
