import React from 'react';
import PropTypes from 'prop-types';

/**
 * Modal header, with close button and title
 */
export default function ModalHeader({ onClose, title }) {
  return (
    <div className="modal-header">
      <h5 className="modal-title">{title}</h5>
      {onClose && (
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">×</span>
        </button>
      )}
    </div>
  );
}

ModalHeader.propTypes = {
  /** onClose event handler */
  onClose: PropTypes.func,
  /** title to display */
  title: PropTypes.string.isRequired,
};

ModalHeader.defaultProps = {
  onClose: null,
};
