import React from 'react';
import ReactDOM from 'react-dom/server';
import PropTypes from 'prop-types';
import withCSRF from '../../withCSRF';

/**
 * DropZone item template to render as static markup for the main DropZone component
 */
function Template() {
  return (
    <div id="dropzone-template-container">
      <div className="dz-preview dz-complete dz-image-preview">
        <div className="dz-image">
          <img alt="file thumbnail" data-dz-thumbnail="" />
        </div>
        <div className="dz-details">
          <div className="dz-size">
            <span data-dz-size="" />
          </div>
          <a className="dz-filename" target="_blank" download>
            <span data-dz-name="" />
          </a>
        </div>
        <div className="dz-progress">
          <span className="dz-upload" data-dz-uploadprogress="" />
        </div>
        <div className="dz-error-message">
          <span data-dz-errormessage="" />
        </div>
      </div>
    </div>
  );
}

/**
 * DropZone component to be used as a file picker/drag'n'drop in forms
 */
class DropZone extends React.Component {
  constructor(props) {
    super(props);

    this.state = { files: [] };

    this.fileAddedHandler = this.fileAddedHandler.bind(this);
    this.fileRemovedHandler = this.fileRemovedHandler.bind(this);
    this.successHandler = this.successHandler.bind(this);
    this.showSuccess = this.showSuccess.bind(this);
    this.showError = this.showError.bind(this);
    this.handleFilesChange = this.handleFilesChange.bind(this);
  }

  componentDidMount() {
    const { id, _csrf, maxFiles, value } = this.props;

    this.dropZone = $(`#${id}`).dropzone({
      url: '/uploadFile',
      paramName: 'file',
      maxFiles,
      createImageThumbnails: true,
      addRemoveLinks: true,
      headers: {
        'X-CSRF-Token': _csrf,
        _csrf,
      },
      previewTemplate: ReactDOM.renderToStaticMarkup(<Template />),
      accept: (file, done) => done(),
    });

    this.dropZone.on('addedfile', this.fileAddedHandler);
    this.dropZone.on('removedfile', this.fileRemovedHandler);
    this.dropZone.on('success', this.successHandler);
    this.dropZone.on('error', file =>
      $(file.previewElement)
        .find('.dz-error-message')
        .text('File upload failed'),
    );
    this.dropZone.on('thumbnail', (file, thumbParam) => {
      // eslint-disable-next-line no-param-reassign
      file.previewElement.querySelector('img').src = thumbParam || file.thumb;
      file.previewElement.querySelector('img').setAttribute('height', 120);
    });

    if (value) {
      const files = JSON.parse(value);
      files.forEach(file => {
        const fileNameParts = file.url.split('/');
        const fileName = fileNameParts[fileNameParts.length - 1];

        const mockFile = {
          name: fileName,
          size: 0,
          thumb: file.thumb,
          url: file.url,
          medium: file.medium,
        };

        this.dropZone.emit('addedfile', mockFile);
        if (mockFile.thumb) {
          this.dropZone.emit('thumbnail', mockFile);
        }
        this.dropZone.emit('complete', mockFile);
      });
    }
  }

  showSuccess() {
    $(`#${this.props.id}`)
      .closest('.form-group')
      .removeClass('has-danger')
      .addClass('has-success');
  }

  showError() {
    $(`#${this.props.id}`)
      .closest('.form-group')
      .removeClass('has-success')
      .addClass('has-danger');
  }

  handleFilesChange() {
    const { files } = this.state;
    const { maxFiles, required, onChange } = this.props;

    if (files.length === maxFiles) {
      $('.dz-hidden-input').prop('disabled', true);
    } else {
      $('.dz-hidden-input').prop('disabled', false);
    }

    if (required) {
      if (files.length === 0) {
        this.showError();
      } else {
        this.showSuccess();
      }
    }

    onChange(JSON.stringify(files));
  }

  fileAddedHandler(file) {
    this.dropZone.emit('thumbnail', file, file.thumb);
    $(file.previewElement)
      .find('.dz-filename')
      .attr({ href: file.url, download: file.name });
  }

  fileRemovedHandler(file) {
    let fileURL = file.url;
    if (file && file.xhr && file.xhr.response) {
      fileURL = JSON.parse(file.xhr.response).data.url;
    }
    this.setState(
      ({ files }) => ({ files: files.filter(f => f.url !== fileURL) }),
      this.handleFilesChange,
    );
  }

  successHandler(file, result) {
    this.setState(({ files }) => ({ files: files.concat(result.data) }), this.handleFilesChange);
  }

  render() {
    const { id, name, title, description } = this.props;
    const { files } = this.state;

    return (
      <div className="m-dropzone m-dropzone--secundary" id={id}>
        <div className="m-dropzone__msg dz-message needsclick">
          <h3 className="m-dropzone__msg-title">{title}</h3>
          <span className="m-dropzone__msg-desc">{description}</span>
        </div>
        <input
          name={name}
          type="hidden"
          value={files.length > 0 ? JSON.stringify(files) : ''}
          readOnly
        />
      </div>
    );
  }
}

DropZone.propTypes = {
  /** container div id for DropZone initialization */
  id: PropTypes.string.isRequired,
  /** _csrf token for request */
  _csrf: PropTypes.string.isRequired,
  /** <input /> name, required for validation */
  name: PropTypes.string,
  /** JSON.stringify() of an array of files (TODO: make sure this is used more often than non-JSON) */
  value: PropTypes.string.isRequired,
  /** onChange handler, receives a JSON string with the array of files */
  onChange: PropTypes.func.isRequired,
  /** Title to display in the DropZone element */
  title: PropTypes.string.isRequired,
  /** Description to display in the DropZone element */
  description: PropTypes.string.isRequired,
  /** Max number of files, defaults to 1 */
  maxFiles: PropTypes.number,
  /**
   * Whether the input is required in the form. Used to manipulate the error classes in the form on
   * file change
   */
  required: PropTypes.bool,
};

DropZone.defaultProps = {
  name: null,
  maxFiles: 1,
  required: false,
};

export default withCSRF(DropZone);
