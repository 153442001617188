import React from 'react';
import PropTypes from 'prop-types';
import BootstrapSwitch from '../../../../commonComponents/forms/inputs/BootstrapSwitch';
import translate from '../../../../commonComponents/translate';

function IntervalSwitch({ t, interval, onIntervalChange }) {
  // eslint-disable-next-line no-unused-vars
  const onSwitchChange = (isChecked, selectedStatus) => {
    onIntervalChange(isChecked ? 'month' : 'year');
  };

  return (
    <BootstrapSwitch
      id="SubscriptionInterval"
      name="SubscriptionInterval"
      label={t('subscription_interval')}
      className="subscription-interval-input"
      textSelected={interval === 'month' ? t('subscription_interval_month') : t('subscription_interval_year')}
      textOn={t('subscription_interval_month')}
      textOff={t('subscription_interval_year')}
      width="200"
      onSwitchChange={onSwitchChange}
    />
  );
}

IntervalSwitch.propTypes = {
  t: PropTypes.func.isRequired,
  interval: PropTypes.string.isRequired,
  onIntervalChange: PropTypes.func.isRequired,
};

IntervalSwitch.defaultProps = {};

export default translate(IntervalSwitch);
