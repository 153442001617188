import React from 'react';
import PropTypes from 'prop-types';
import TabHeader from './TabHeader';
import TabContent from './TabContent';

/**
 * Creates a Portlet with a tab header that picks the corresponding component to render,
 * the tab configuration is on the propType below
 */
export default class TabbedPortlet extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedIndex: props.selectedIndex };

    this.handleTabChange = this.handleTabChange.bind(this);
  }

  handleTabChange(selectedIndex) {
    return () => this.setState({ selectedIndex });
  }

  render() {
    const { tabs, style } = this.props;
    const { selectedIndex } = this.state;

    // eslint-disable-next-line react/prop-types
    const renderTabHeader = ({ id, title }, index) => (
      <TabHeader
        key={id}
        id={id}
        title={title}
        isSelected={index === selectedIndex}
        onTabChange={this.handleTabChange(index)}
      />
    );

    const maxIndex = tabs.length - 1;
    const tabToRender = tabs[selectedIndex > maxIndex ? 0 : selectedIndex];

    return (
      <div
        className="m-portlet m-portlet--full-height m-portlet--tabs"
        style={style}
      >
        <div className="m-portlet__head">
          <div className="m-portlet__head-tools">
            <ul
              className="nav nav-tabs m-tabs m-tabs-line m-tabs-line--left m-tabs-line--primary"
              role="tablist"
            >
              {tabs.map(renderTabHeader)}
            </ul>
          </div>
        </div>
        <div className="tab-content">
          <TabContent content={tabToRender.content} id={tabToRender.id} />
        </div>
      </div>
    );
  }
}

TabbedPortlet.propTypes = {
  /**
   * Array of tab configuration items, each tab has a corresponding
   * id, title to present in the header and react component to be rendered when selected
   */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.element.isRequired,
    }),
  ).isRequired,
  /** Selected tab index, defaults to the first tab */
  selectedIndex: PropTypes.number,
  style: PropTypes.shape({}),
};

TabbedPortlet.defaultProps = {
  selectedIndex: 0,
  style: null,
};
