import React from 'react';
import TranslationContext from '../contextProviders/TranslationContext';

/**
 * Translation Higher Order component, uses the Context API to provide a translation function prop
 * to the given component.
 * Instead of exporting your component directly simply export the use of the HoC over your component,
 * such as:
 * function MyComponent({ t }) {
 *   return <div>{t('test')}</div>;
 * }
 *
 * export default translate(MyComponent);
 */
export default function translate(Component) {
  const C = props => {
    const { wrappedComponentRef, ...remainingProps } = props;

    return (
      <TranslationContext.Consumer>
        {t => (
          <Component
            t={t}
            {...remainingProps}
            ref={wrappedComponentRef}
            wrappedComponentRef={wrappedComponentRef}
          />
        )}
      </TranslationContext.Consumer>
    );
  };

  C.displayName = `translated(${Component.displayName || Component.name})`;
  C.WrappedComponent = Component;

  return C;
}
