import React from 'react';
import StripeContext from '../contextProviders/StripeContext';

/**
 * Translation Higher Order component, uses the Context API to provide a translation function prop
 * to the given component.
 * Instead of exporting your component directly simply export the use of the HoC over your component,
 * such as:
 * function MyComponent({ stripe }) {
 *   return <div>{t('test')}</div>;
 * }
 *
 * export default translate(MyComponent);
 */
export default function injectStripe(Component) {
  const C = props => {
    const { wrappedComponentRef, ...remainingProps } = props;

    return (
      <StripeContext.Consumer>
        {stripe => (
          <Component
            stripe={stripe}
            {...remainingProps}
            ref={wrappedComponentRef}
            wrappedComponentRef={wrappedComponentRef}
          />
        )}
      </StripeContext.Consumer>
    );
  };

  C.displayName = `injectStripe(${Component.displayName || Component.name})`;
  C.WrappedComponent = Component;

  return C;
}
