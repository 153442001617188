/* globals toastr */
const template = (title, message) => `<h5>${title}</h5><span>${message}</span>`;

function showNotificationError(title, message) {
  toastr.error(template(title, message));
}

function showNotificationWarning(title, message) {
  toastr.warning(template(title, message));
}

function showNotificationSuccess(title, message) {
  toastr.success(template(title, message));
}

export default {
  showNotificationError,
  showNotificationWarning,
  showNotificationSuccess,
};
