import PropTypes from 'prop-types';
import {
  DISCOUNT_DURATIONS,
  DISCOUNT_TYPES,
} from '../../../services/utilityFunctions';

const propType = {
  _id: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.oneOf(DISCOUNT_TYPES).isRequired,
  duration: PropTypes.oneOf(DISCOUNT_DURATIONS),
  durationInMonths: PropTypes.number,
  currency: PropTypes.string,
  percentOff: PropTypes.number,
  amountOff: PropTypes.number,
  minPrice: PropTypes.number,
  maxPrice: PropTypes.number,
  expirationDate: PropTypes.number,
  products: PropTypes.arrayOf(PropTypes.string),
  productCategories: PropTypes.arrayOf(PropTypes.string),
  maxUsagePerOrganization: PropTypes.number,
};

export default { propType };
