import PropTypes from 'prop-types';

const propType = {
  rules: PropTypes.shape({
    LU: PropTypes.number.isRequired,
    EUROPE: PropTypes.number.isRequired,
    OTHERS: PropTypes.number.isRequired,
  }).isRequired,
  europe: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default { propType };
