import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import i18n from './services/i18n';
import Main from './Main';

/**
 * Script to fetch react props from the dom and mount the Main component,
 * should be the browser entry point
 */

const data = document.querySelector('script[data-react-props]');
let props = {};
if (data) {
  props = JSON.parse(data.getAttribute('data-react-props'));
}

ReactDOM.hydrate(
  <BrowserRouter>
    <Main t={i18n.__} {...props} />
  </BrowserRouter>,
  document.getElementById('react'),
);
