import PropTypes from 'prop-types';
import StripeCreditCardProp from './StripeCreditCardProp';
import StripeSEPAProp from './StripeSEPAProp';

const propType = {
  type: PropTypes.string.isRequired,
  isDefault: PropTypes.bool.isRequired,
  billingName: PropTypes.string,
  billingEmail: PropTypes.string,
  card: PropTypes.shape(StripeCreditCardProp.propType),
  sepa: PropTypes.shape(StripeSEPAProp.propType),
};

export default { propType };
