import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../../commonComponents/translate';
import FormInput from '../../../commonComponents/forms/FormInput';
import Notifications from '../../../services/notifications';

function errorMessageDiv(elementId) {
  return `<div id="${elementId}-error" class="form-control-feedback">Please enter a valid Email.</div>`;
}

function setElementInvalid(elementId) {
  const el = $(`#${elementId}`);
  el.closest('.form-group').addClass('has-danger');

  if (!$(`#${elementId}-error`).length) {
    el.after(errorMessageDiv(elementId));
  }
}

function setElementValid(elementId) {
  $(`#${elementId}`)
    .closest('.form-group')
    .removeClass('has-danger');
  $(`#${elementId}-error`).remove();
}

function validateEmail(email) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

class Invitations extends React.Component {
  constructor(props) {
    super(props);
    const fillerInvitations = Array(5 - props.invitations.length).fill('');
    this.state = {
      invitations: [...props.invitations.concat(fillerInvitations)],
    };

    this.formId = 'invitationsForm';

    this.handleChange = this.handleChange.bind(this);
    this.validateManually = this.validateManually.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleChange(index) {
    return value =>
      this.setState(({ invitations }) => ({
        invitations: invitations.map(
          (email, i) => (i === index ? value : email),
        ),
      }));
  }

  handleBlur(index) {
    return () => {
      const value = this.state.invitations[index];

      if (value === '' || validateEmail(value)) {
        setElementValid(`invitation_${index}`);
      } else {
        setElementInvalid(`invitation_${index}`);
      }
    };
  }

  validateManually() {
    const toValidate = this.state.invitations.slice();
    return toValidate.reduce((acc, field, index) => {
      if (field === '' || validateEmail(field)) {
        setElementValid(`invitation_${index}`);
        return acc;
      }
      setElementInvalid(`invitation_${index}`);
      return acc + 1;
    }, 0);
  }

  submitHandler(isForward) {
    const { t, onFinish, onBack } = this.props;
    return evt => {
      evt.preventDefault();

      const invalidCount = this.validateManually();
      if (invalidCount === 0) {
        const invitations = [...this.state.invitations].filter(i => i.length);
        if (isForward) {
          const uniqueInvitations = new Set(invitations);
          if (uniqueInvitations.size !== invitations.length) {
            Notifications.showNotificationError(
              t('error'),
              t('trial_invitations_repeatEmails'),
            );
          } else {
            onFinish({ invitations });
          }
        } else {
          onBack({ invitations });
        }
      } else if (isForward) {
        document.querySelector('.has-danger').scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
      } else {
        onBack({});
      }
    };
  }

  createInput(index) {
    const { t } = this.props;
    return (
      <FormInput
        key={index}
        id={`invitation_${index}`}
        name={`invitation_${index}`}
        value={this.state.invitations[index]}
        onChange={this.handleChange(index)}
        label={`${t('trial_invitations_email')} ${index + 1}`}
        labelClassName="col-3"
        containerClassName="col-9"
        type="email"
        groupProps={{ className: 'row' }}
        inputProps={{ onBlur: this.handleBlur(index) }}
      />
    );
  }

  render() {
    const { t } = this.props;
    const { invitations } = this.state;

    return (
      <div className="trial-info-container">
        <div className="trial-info-form">
          <div className="trial-info-form-wrapper">
            <div className="trial-info-form-content">
              <div>
                <h1>
                  <b>{t('trial_invitations_greeting_title1')}</b>
                  {t('trial_invitations_greeting_title2')}
                </h1>
              </div>
              <div className="trial-info-form-inputs">
                <form
                  className="m-form m-form--fit m-form--label-align-right"
                  id={this.formId}
                >
                  {invitations.map((email, index) => this.createInput(index))}
                  <div className="trial-info-next-container">
                    <button
                      className="trial-info-back-button"
                      type="button"
                      onClick={this.submitHandler(false)}
                    >
                      <i className="fa fa-arrow-left" />
                      {t('trial_info_back')}
                    </button>
                    <button
                      className="trial-info-next-button loader-button"
                      type="submit"
                      onClick={this.submitHandler(true)}
                    >
                      {t('trial_finish')}
                      <i className="fa fa-arrow-right" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="trial-demo-container">
          <img
            alt="platform demo"
            className="demo-image"
            src="/images/trial/trial_step_1.png"
          />
        </div>
      </div>
    );
  }
}

Invitations.propTypes = {
  /** Translation function */
  t: PropTypes.func.isRequired,
  /** Finish trial navigation event handler */
  onFinish: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  /** Already saved info, in case we're navigating back and forth */
  invitations: PropTypes.arrayOf(PropTypes.string),
};

Invitations.defaultProps = {
  invitations: ['', '', '', '', ''],
};

export default translate(Invitations);
