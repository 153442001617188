import * as moment from 'moment';
import Request from './request';
import StorageService from './storage';
import { SUBSCRIPTION_TYPE } from './utilityFunctions';

function fetchCurrentLicense(resolve, reject) {
  const subscription = StorageService.getItem('licenseInfo') || {};
  const { lastUpdate } = subscription;

  const aDayAgo = moment
    .utc()
    .subtract(1, 'days')
    .valueOf();

  if (!lastUpdate || lastUpdate < aDayAgo) {
    new Request()
      .get('/subscription/activeDetails', null, true)
      .done(({ data }) => {
        StorageService.saveItem('licenseInfo', {
          awaitingValidation: data.awaitingValidation,
          type: data.type,
          expirationDate: data.expirationDate,
          lastUpdate: moment.utc().valueOf(),
        });
        resolve(data);
      })
      .fail(reject);
  } else {
    resolve(subscription);
  }
}

function isExpiring() {
  return new Promise((resolve, reject) => {
    const aWeekFromNow = moment
      .utc()
      .add(1, 'weeks')
      .valueOf();

    fetchCurrentLicense(
      ({ expirationDate, type, awaitingValidation }) =>
        resolve(
          type === SUBSCRIPTION_TYPE.TRIAL &&
            expirationDate < aWeekFromNow &&
            !awaitingValidation,
        ),
      reject,
    );
  });
}

function isTrial() {
  return new Promise((resolve, reject) =>
    fetchCurrentLicense(
      ({ type }) => resolve(type === SUBSCRIPTION_TYPE.TRIAL),
      reject,
    ),
  );
}

function clearCache() {
  StorageService.removeItem('licenseInfo');
}

export default { isExpiring, isTrial, clearCache };
