import React from 'react';
import PropTypes from 'prop-types';

/**
 * Select component, uses either
 * Select2 (https://select2.org/) or
 * Bootstrap's SelectPicker (https://developer.snapappointments.com/bootstrap-select/options/)
 * depending on the "type" prop
 */
export default class Select extends React.Component {
  static clearErrors(selectElement, required, name) {
    if (required) {
      const form = selectElement.closest('.form-group');
      form.removeClass('has-danger').addClass('has-success');
      form.find(`#${name}-error`).remove();
    }
  }

  componentDidMount() {
    const {
      id,
      type,
      name,
      placeholder,
      searchable,
      width,
      required,
      onChange,
      onUnselect,
      disabled,
    } = this.props;
    const selectElement = $(`#${id}`);

    switch (type) {
      case 'selectpicker':
        selectElement.selectpicker();
        selectElement.on('change', evt => {
          Select.clearErrors(selectElement, required, name);
          onChange(evt.currentTarget.value);
        });
        break;
      case 'select2':
      default: {
        let minimumResultsForSearch;
        if (!searchable) {
          minimumResultsForSearch = -1;
        }
        selectElement.select2({
          placeholder,
          minimumResultsForSearch,
          width,
          disabled,
        });
        selectElement.on('select2:select', evt => {
          Select.clearErrors(selectElement, required, name);
          onChange(evt.params.data.id);
        });
        if (onUnselect) {
          selectElement.on('select2:unselect', evt => {
            Select.clearErrors(selectElement, required, name);
            onUnselect(evt.params.data.id);
          });
        }
      }
    }
  }

  componentDidUpdate({ value: prevValue }) {
    const { id, value } = this.props;
    if (value !== prevValue) {
      const selectElement = $(`#${id}`);
      selectElement.val(value);
      selectElement.trigger('change');
    }
  }

  componentWillUnmount() {
    const { id, type } = this.props;
    const element = $(`#${id}`);
    switch (type) {
      case 'selectpicker':
        element.selectpicker('destroy');
        break;
      case 'select2':
      default:
        element.select2('destroy');
    }
  }

  render() {
    const {
      id,
      name,
      className,
      items,
      value,
      placeholder,
      multiple,
      disabled,
    } = this.props;

    const renderItem = item => (
      <option key={item.id} value={item.id}>
        {item.text}
      </option>
    );

    return (
      <select
        id={id}
        name={name}
        defaultValue={value}
        className={`m-bootstrap-select m_selectpicker ${className}`}
        data-actions-box="true"
        multiple={multiple}
        disabled={disabled}
      >
        {placeholder && <option />}
        {items.map(renderItem)}
      </select>
    );
  }
}

Select.propTypes = {
  /** Selector id used by the jQuery libraries */
  id: PropTypes.string.isRequired,
  /** Selector html name, required for validation */
  name: PropTypes.string,
  /** Class names to be added to the default ones */
  className: PropTypes.string,
  /** Selector options, id will be the returned value on the onChange handler */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
  /** initial value */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  /** onChange handler */
  onChange: PropTypes.func.isRequired,
  /** onUnselect */
  onUnselect: PropTypes.func,
  /** Custom placeholder text */
  placeholder: PropTypes.string,
  /** Whether the selector is required in the form, used to display validation on change */
  required: PropTypes.bool,
  /** Type of selector, changes the underlying library */
  type: PropTypes.oneOf(['select2', 'selectpicker']),
  /** Whether the input has a search box */
  searchable: PropTypes.bool,
  /** Select2 width argument */
  width: PropTypes.string,
  /** Allow multiple choices */
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
};

Select.defaultProps = {
  name: null,
  className: '',
  placeholder: undefined,
  required: false,
  type: 'select2',
  searchable: true,
  width: undefined,
  disabled: false,
};
