import React from 'react';
import PropTypes from 'prop-types';
import ProfileForm from './form/ProfileForm';
import BillingForm from './form/BillingForm';
import UserProp from '../propTypes/userProp';
import translate from '../../../commonComponents/translate';
import TabbedPortlet from '../../../commonComponents/portlets/Tabbed/TabbedPortlet';
import PaymentForm from './form/PaymentForm';
import Subscriptions from '../../subscriptionList/Subscriptions';
import Invoices from '../../invoicesList/Invoices';
import { PROFILE_TAB, PROFILE_TABS } from '../../../services/utilityFunctions';
import CountryProp from '../../../commonComponents/forms/Address/CountryProp';
import BillingProp from '../propTypes/billingProp';
import ImageProp from '../propTypes/imageProp';
import StripePaymentMethodsProp from '../../payments/propTypes/StripePaymentMethodsProp';
import TaxIdTypeProp from '../../subscriptionDetails/propTypes/TaxIdTypeProp';

function MainPanel(props) {
  const {
    t,
    activeTab,
    member,
    onMemberChange,
    paymentMethods,
    countries,
    billing,
    onBillingChange,
    avatar,
    forceStripeCustomer,
    onCardsChange,
    subscriptionIsInactive,
    SEPACountries,
    taxIdTypes,
  } = props;

  let tabs = [
    {
      id: 'profileTab',
      title: t('profile_tab_info'),
      content: (
        <ProfileForm
          member={member}
          onMemberChange={onMemberChange}
          avatar={avatar}
        />
      ),
    },
  ];
  if (member.role !== 'super_admin') {
    tabs = tabs.concat([
      {
        id: 'subscriptionsTab',
        title: t('profile_tab_subscriptions'),
        content: <Subscriptions />,
      },
      {
        id: 'invoicesTab',
        title: t('profile_tab_invoices'),
        content: <Invoices />,
      },
    ]);
  }
  tabs = tabs.concat([
    {
      id: 'billingTab',
      title: t('profile_tab_billing'),
      content: (
        <BillingForm
          countries={countries}
          billing={billing}
          onBillingChange={onBillingChange}
          taxIdTypes={taxIdTypes}
        />
      ),
    },
    {
      id: 'paymentTab',
      title: t('profile_tab_payment'),
      content: (
        <PaymentForm
          paymentMethods={paymentMethods}
          onCardsChange={onCardsChange}
          forceStripeCustomer={forceStripeCustomer}
          subscriptionIsInactive={subscriptionIsInactive}
          shipping={billing.shipping}
          invoiceEmail={billing.invoiceEmail}
          SEPACountries={SEPACountries}
        />
      ),
    },
  ]);

  const selectedIndex = {
    [PROFILE_TAB.SUBSCRIPTIONS]: 1,
    [PROFILE_TAB.INVOICES]: 2,
    [PROFILE_TAB.BILLING]: 3,
    [PROFILE_TAB.PAYMENT]: 4,
  };
  const index = subscriptionIsInactive
    ? selectedIndex[PROFILE_TAB.PAYMENT]
    : selectedIndex[activeTab];

  return (
    <TabbedPortlet
      selectedIndex={index}
      tabs={tabs}
      style={{ minHeight: 'calc(100vh - 240px)' }}
    />
  );
}

MainPanel.propTypes = {
  t: PropTypes.func.isRequired,
  activeTab: PropTypes.oneOf(PROFILE_TABS),
  avatar: PropTypes.shape(ImageProp.propType).isRequired,
  member: PropTypes.shape(UserProp.propType).isRequired,
  onMemberChange: PropTypes.func.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.shape(StripePaymentMethodsProp.propType)).isRequired,
  onCardsChange: PropTypes.func.isRequired,
  billing: PropTypes.shape(BillingProp.propType).isRequired,
  onBillingChange: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape(CountryProp.propType))
    .isRequired,
  forceStripeCustomer: PropTypes.bool.isRequired,
  subscriptionIsInactive: PropTypes.bool,
  SEPACountries: PropTypes.arrayOf(PropTypes.shape(CountryProp.propType)).isRequired,
  taxIdTypes: PropTypes.arrayOf(PropTypes.shape(TaxIdTypeProp.propType)).isRequired,
};

MainPanel.defaultProps = {
  activeTab: null,
  subscriptionIsInactive: false,
};

export default translate(MainPanel);
