/* globals Stripe */

function onErrorHandler(err) {
  switch (err.type) {
    case 'card_error':
      switch (err.code) {
        case 'invalid_expiry_year':
          return 'ERROR_CREDIT_CARD_EXPIRED';
        case 'incorrect_cvc':
          return 'ERROR_CARD_CVC_INCORRECT';
        case 'expired_card':
          return 'ERROR_CREDIT_CARD_EXPIRED';
        case 'processing_error':
          return 'ERROR_CARD_PROCESSING';
        case 'missing':
          return 'ERROR_MISSING_PAYMENT_CARD';
        case 'card_declined':
          return 'ERROR_CREDIT_CARD_DECLINED';
        default:
          return 'ERROR_PAYMENT_DENIED';
      }
    default:
      switch (err.message) {
        case 'Invalid positive integer':
          return 'ERROR_INVALID_PRICE';
        default:
          if (err.message.indexOf('Invalid integer:') !== -1) {
            return 'ERROR_INVALID_PRICE';
          } if (err.message === 'Invalid positive integer') {
          return 'ERROR_INVALID_PRICE';
        } if (err.message.indexOf('Invalid currency:') !== -1) {
          return 'ERROR_INVALID_CURRENCY';
        }
          if (err.message.indexOf(' postal code') !== -1) {
            return 'ERROR_INVALID_POST_CODE_COUNTRY';
          }
          if (err.message === 'You cannot use a live bank account number when making transfers or debits in test mode') {
            return 'ERROR_INVALID_IBAN_TEST';
          }
          if (err.message === 'We couldn\'t find the bank for this account number') {
            return 'ERROR_INVALID_IBAN';
          }
          if (err.message === 'Date of birth must be in the past') {
            return 'ERROR_INVALID_BIRTHDAY';
          }

          return err.message;
      }
  }
}

export default {

  init(config) {
    Stripe.setPublishableKey(config.key);
  },

  handleErrors(err) {
    return onErrorHandler(err);
  },

  createCardToken(number, exp_month, exp_year, cvc) {
    return new Promise(async (resolve, reject) => {
      try {
        if (!Stripe.card.validateCardNumber(number)) {
          return reject('ERROR_CREDIT_CARD_DECLINED');
        }
        if (!Stripe.card.validateExpiry(exp_month, exp_year)) {
          return reject('ERROR_CREDIT_CARD_EXPIRED');
        }
        if (!Stripe.card.validateCVC(cvc)) {
          return reject('ERROR_CARD_CVC_INCORRECT');
        }
        return Stripe.card.createToken({ number, cvc, exp_month, exp_year }, (status, response) => {
          if (response.error) {
            reject(onErrorHandler(response.error));
          } else {
            resolve(response.id);
          }
        });
      } catch (e) {
        return reject(e);
      }
    });
  },
};
