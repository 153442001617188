import React from 'react';
import PropTypes from 'prop-types';

export default function ModalFooter(props) {
  const { onCancel, onSave, cancelText, saveText } = props;

  return (
    <div className="modal-footer">
      <button type="button" className="btn btn-secondary" onClick={onCancel}>
        {cancelText}
      </button>
      <button type="submit" className="btn btn-primary" onClick={onSave}>
        {saveText}
      </button>
    </div>
  );
}

ModalFooter.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  cancelText: PropTypes.string.isRequired,
  saveText: PropTypes.string.isRequired,
};
