import React from 'react';
import PropTypes from 'prop-types';

/**
 * Container used for theme styling purposes
 */
export default function ContentContainer({ children }) {
  return (
    <div className="m-grid__item m-grid__item--fluid m-grid m-grid--hor-desktop m-grid--desktop m-body">
      <div
        className={
          'm-grid__item m-grid__item--fluid m-grid m-grid--ver' +
          'm-container m-container--responsive m-container--xxl m-page__container body-background'
        }
      >
        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          {children}
        </div>
      </div>
    </div>
  );
}

ContentContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
