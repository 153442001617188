import React from 'react';

/**
 * Scroll to top arrow, used in Main and any component big enough to need it
 */
export default function ScrollTop() {
  return (
    <div id="m_scroll_top" className="m-scroll-top">
      <i className="la la-arrow-up" />
    </div>
  );
}
