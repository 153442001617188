import React from 'react';
import PropTypes from 'prop-types';
import Request from '../../../services/request';
import Notifications from '../../../services/notifications';
import translate from '../../translate';

/**
 *  Google location search functionality as a searchable dropdown,
 *  should return the address details on dropdown selection
 */
class GoogleSearch extends React.Component {
  componentDidMount() {
    const {
      t,
      id,
      onChange,
      placeholder,
      onRequestStart,
      onRequestEnd,
      disabled,
    } = this.props;
    const googleSelector = $(`#${id}`);

    googleSelector.select2({
      disabled,
      placeholder,
      ajax: {
        url: '/address/autocomplete',
        dataType: 'json',
        quietMillis: 200,
        processResults: res => ({
          results: res.data.map(address => ({
            id: address.id,
            text: address.name,
          })),
        }),
      },
    });

    googleSelector.on('select2:select', evt => {
      if (evt.params.data) {
        const addressId = evt.params.data.id;
        if (onRequestStart) {
          onRequestStart();
        }
        new Request()
          .get(`/address/${addressId}`)
          .done(result => {
            onChange(result.data);
            if (onRequestEnd) {
              onRequestEnd();
            }
          })
          .fail(err => {
            let error = t('error_getAddress');
            if (err && err.responseJSON) {
              error = err.responseJSON.message || t(err.responseJSON.error);
            }
            Notifications.showNotificationError(t('error'), error);
            if (onRequestEnd) {
              onRequestEnd();
            }
          });
      }
    });
  }

  render() {
    const { id, name, className } = this.props;
    return (
      <select
        className={`m-bootstrap-select m_selectpicker ${className}`}
        id={id}
        name={name}
      />
    );
  }
}

GoogleSearch.propTypes = {
  /** Translation function */
  t: PropTypes.func.isRequired,
  /** Input html id */
  id: PropTypes.string,
  /** Input html name */
  name: PropTypes.string,
  /* Additional class names */
  className: PropTypes.string,
  /** onChange handler, the details of the selected address are passed as an argument */
  onChange: PropTypes.func.isRequired,
  /** Input placeholder */
  placeholder: PropTypes.string.isRequired,
  onRequestStart: PropTypes.func,
  onRequestEnd: PropTypes.func,
  disabled: PropTypes.bool,
};

GoogleSearch.defaultProps = {
  id: 'googleSearch',
  name: 'googleSearch',
  className: '',
  onRequestStart: null,
  onRequestEnd: null,
  disabled: false,
};

export default translate(GoogleSearch);
