import React from 'react';
import PropTypes from 'prop-types';

export default function Switch(props) {
  const { onClick, checked, id, style, disabledModuleItem } = props;
  return (
    <div className="ios-switch" style={style}>
      <input
        className="ios-toggle"
        type="checkbox"
        onChange={onClick}
        checked={disabledModuleItem ? !disabledModuleItem : checked}
        name="switch"
        id={id}
        disabled={disabledModuleItem}
      />
      <label htmlFor={id} className="checkbox-label" />
    </div>
  );
}

Switch.propTypes = {
  style: PropTypes.shape({}),
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  disabledModuleItem: PropTypes.bool,
};

Switch.defaultProps = {
  style: {},
  disabledModuleItem: false,
};
