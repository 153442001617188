import React from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import translate from '../../translate';

/**
 * DateRange component wrapping the jQuery implementation,
 * see: http://www.daterangepicker.com/
 */
class DateRange extends React.Component {
  componentDidMount() {
    const { t, id, onChange, onClear } = this.props;
    const picker = $(`#${id}`);
    if (picker.length === 0) {
      return;
    }

    const start = moment();
    const end = moment();

    const today = t('dateRange_today');
    const yesterday = t('dateRange_yesterday');
    const sevenDays = t('dateRange_sevenDays');
    const thirtyDays = t('dateRange_thirtyDays');
    const thisMonth = t('dateRange_thisMonth');
    const lastMonth = t('dateRange_lastMonth');

    picker.daterangepicker({
      autoUpdateInput: false,
      locale: {
        applyLabel: t('dateRange_apply'),
        cancelLabel: t('dateRange_clear'),
      },
      startDate: start,
      endDate: end,
      opens: 'left',
      ranges: {
        [today]: [moment(), moment()],
        [yesterday]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        [sevenDays]: [moment().subtract(6, 'days'), moment()],
        [thirtyDays]: [moment().subtract(29, 'days'), moment()],
        [thisMonth]: [moment().startOf('month'), moment().endOf('month')],
        [lastMonth]: [
          moment()
            .subtract(1, 'month')
            .startOf('month'),
          moment()
            .subtract(1, 'month')
            .endOf('month'),
        ],
      },
    });

    picker.on('apply.daterangepicker', (ev, element) => {
      let title = '';
      let range = '';
      const { startDate, endDate, label } = element;

      if (endDate - startDate < 100) {
        title = `${today}:`;
        range = startDate.format('MMM D');
      } else if (label === yesterday) {
        title = `${yesterday}:`;
        range = startDate.format('MMM D');
      } else {
        range = `${startDate.format('MMM D')} - ${endDate.format('MMM D')}`;
      }

      picker.find('.m-subheader__daterange-date').html(range);
      picker.find('.m-subheader__daterange-title').html(title);

      onChange({ startDate: startDate.valueOf(), endDate: endDate.valueOf() });
    });

    picker.on('cancel.daterangepicker', () => {
      picker.find('.m-subheader__daterange-date').html('');
      picker.find('.m-subheader__daterange-title').html(t('all'));

      onClear();
    });
  }

  render() {
    const { t, id } = this.props;

    return (
      <span className="m-subheader__daterange" id={id}>
        <span className="m-subheader__daterange-label">
          <span className="m-subheader__daterange-title">{t('all')}</span>
          <span className="m-subheader__daterange-date m--font-brand" />
        </span>
        <a
          href="#"
          className="btn btn-sm btn-brand m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill"
          style={{ marginLeft: '10px' }}
        >
          <i className="la la-angle-down" />
        </a>
      </span>
    );
  }
}

DateRange.propTypes = {
  /** Translation function */
  t: PropTypes.func.isRequired,
  /** Id of the component */
  id: PropTypes.string,
  /** Handler for date change */
  onChange: PropTypes.func.isRequired,
  /** Handler for date clear */
  onClear: PropTypes.func.isRequired,
};

DateRange.defaultProps = {
  id: 'dateRangePicker',
};

export default translate(DateRange);
