import React from 'react';
import PropTypes from 'prop-types';
import Error from './Error';

/**
 * Router's catch all component, if rendered on the server adds a 404 status code to the context
 * which is not currently being used but is a general best practice
 */
export default function NotFound({ staticContext, ...other }) {
  if (staticContext) {
    // eslint-disable-next-line no-param-reassign
    staticContext.statusCode = 404;
  }
  return <Error {...other} />;
}

NotFound.propTypes = {
  staticContext: PropTypes.shape(),
};

NotFound.defaultProps = {
  staticContext: null,
};
