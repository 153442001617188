import PropTypes from 'prop-types';

const propType = {
  countryCode: PropTypes.string.isRequired,
  countryName: PropTypes.string.isRequired,
  taxCode: PropTypes.string.isRequired,
  taxType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  continent: PropTypes.string,
  flagEmoji: PropTypes.string,
};

export default { propType };
