import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../commonComponents/translate';

/**
 * Trial Created Page
 */
class TrialCreated extends React.Component {
  componentDidMount() {
    const { utmSource } = this.props;

    if (utmSource === 'capterra_lead-finished') {
      /* Code to run when the link comes from capterra web-site (needed for Marketing Analytics) */
      const capterra_vkey = '8c90f213bad9594d61125a8face78c47';
      const capterra_vid = '2130397';
      const capterra_prefix = ((document.location.protocol === 'https:')
        ? 'https://ct.capterra.com' : 'http://ct.capterra.com');
      const ct = document.createElement('script');
      ct.type = 'text/javascript';
      ct.async = true;
      ct.src = `${capterra_prefix}/capterra_tracker.js?vid=${capterra_vid}&vkey=${capterra_vkey}`;
      const s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(ct, s);
    }
  }

  render() {
    const { t } = this.props;
    const logo = '/images/trial/logo-dark.png';

    const facebookLink = 'https://www.facebook.com/gamificationsolution/';
    const facebookImg =
      'https://lh3.googleusercontent.com/NftFjishmedqEZnFKjDbTeLuBpoc8nYiuOtlrqL8sZFDf4WiXX9u05Do1N2hMKCxXblvBSkVauPK45c9X6Ng=s0';

    const instagramLink = 'https://www.instagram.com/bravon_games/';
    const instagramImg =
      'https://lh3.googleusercontent.com/PB2nOPaL9NzdhjXNwJtWjjjUwPQ0ZkIi12ap2ZU_MnUoI1qjjUagmfQKIHUuqKPta10_cPKARfPVEIQw9HU=s0';

    const linkedInLink = 'https://www.linkedin.com/company/bravon';
    const linkedInImg =
      'https://lh3.googleusercontent.com/SqUvYXNOTOhYcU0gY9SSTwh0TPHLqUMwpwVqu5fRQf5qIoXQYk66hgNlkz7c1jxHx-e3_fr88K_vVo0n5zc=s0';

    const appleLink = 'https://itunes.apple.com/us/app/bravon/id1362655378?mt=8';
    const appleImg =
      'https://s3.eu-central-1.amazonaws.com/avno-gamification-prod/portal/Appstore.png';

    const googleLink =
      'https://play.google.com/store/apps/details?id=pt.avnoconn.bravon';
    const googleImg =
      'https://s3.eu-central-1.amazonaws.com/avno-gamification-prod/portal/Playstore.png';

    const email = 'info@bravon.io';
    const phone = '+352 661840378';

    return (
      <div className="trial-created">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="message-container">
          <div className="welcome-message">
            <strong>{t('trial-created-welcome')}</strong>
          </div>
          <div className="description">{t('trial-created-message')}</div>
        </div>

        <div className="apps-container">
          <div className="app-entry">
            <a href={appleLink} target="_blank">
              <img src={appleImg} alt="appleStore-bravon" />
            </a>
          </div>

          <div className="app-entry">
            <a href={googleLink} target="_blank">
              <img src={googleImg} alt="googleStore-bravon" />
            </a>
          </div>
        </div>

        <div className="footer">
          <div className="social-media">
            <div className="social-media-entry">
              <a href={facebookLink} target="_blank">
                <img src={facebookImg} alt="Facebook" />
              </a>
            </div>
            <div className="social-media-entry">
              <a href={instagramLink} target="_blank">
                <img src={instagramImg} alt="Facebook" />
              </a>
            </div>
            <div className="social-media-entry">
              <a href={linkedInLink} target="_blank">
                <img src={linkedInImg} alt="Facebook" />
              </a>
            </div>
          </div>
          <div className="contact-info">
            <strong>{t('bravon')}</strong>
            <a href={`mailto:${email}`}>
{' '}
{email}
{' '}
            </a>
            <a href={`tel:${phone}`}>
{' '}
{phone}
{' '}
            </a>
          </div>
          <div className="copyright">{t('trial-created-copyright')}</div>
        </div>
      </div>
    );
  }
}

TrialCreated.propTypes = {
  /** Translation function */
  t: PropTypes.func.isRequired,
  utmSource: PropTypes.string,
};

TrialCreated.defaultProps = {
  utmSource: '',
};

export default translate(TrialCreated);
